import { Typography } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { postApis } from "../../Apis";
import {
  BUTTONS,
  NOTIFICATIONS,
  ROUTES,
  USER_TYPES,
  VALIDATIONS,
} from "../../Constants";
import useNotify from "../../Notify";
import {
  clinicAdminResetPasswordUrl,
  patientPesetPasswordUrl,
} from "../../Endpoints";
import { useSelector } from "react-redux";
import { providerPesetPasswordUrl } from "../../Endpoints";
import CustomForm from "../../CustomForm";
import { confirmPasswordInput, passwordInput } from "../../FormFields";
const { Title } = Typography;

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const navigate = useNavigate();
  const { notify } = useNotify();
  const userType = useSelector((state) => state?.app?.userType);

  const onFinish = async (values) => {
    setLoading(true);
    await postApis(
      userType === USER_TYPES.PATIENT
        ? patientPesetPasswordUrl
        : userType === USER_TYPES.CLINIC_ADMIN
        ? clinicAdminResetPasswordUrl
        : providerPesetPasswordUrl,
      {
        password: values?.password,
        uid: token,
      }
    )
      .then((response) => {
        setLoading(false);
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        navigate(
          userType === USER_TYPES.PATIENT
            ? ROUTES.PATIENT.LOGIN.PATHS.EMAIL
            : userType === USER_TYPES.CLINIC_ADMIN
            ? ROUTES.CLINIC_ADMIN.LOGIN.PATHS.EMAIL
            : ROUTES.PROVIDER.LOGIN.PATHS.EMAIL
        );
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };
  const onFinishFailed = () => {
    setLoading(false);
    notify(NOTIFICATIONS.ERROR.KEY, NOTIFICATIONS.ERROR.MESSAGE);
  };

  useEffect(() => {
    if (!token) {
      notify(NOTIFICATIONS.WARNING.KEY, NOTIFICATIONS.WARNING.INVALID_URL);
      navigate(
        userType === USER_TYPES.PATIENT
          ? ROUTES.PATIENT.FORGOT_PASSWORD.PATH
          : userType === USER_TYPES.CLINIC_ADMIN
          ? ROUTES.CLINIC_ADMIN.FORGOT_PASSWORD.PATH
          : ROUTES.PROVIDER.FORGOT_PASSWORD.PATH
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Title>Reset Password</Title>
      <div className="grey">Change your password below</div>

      <div className="grey mt05">
        Provide new password and confirm password here
      </div>
      <CustomForm
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        formFields={[passwordInput, confirmPasswordInput]}
        initialValues={{}}
        loading={loading}
        text={BUTTONS.RESET}
        style={{ width: "100%" }}
        validations={{
          required: VALIDATIONS.DEFAULT.BLANK,
        }}
      />
    </div>
  );
};
export default ResetPassword;
