import { Button, Col, Empty, Row, Select, Spin } from "antd";

const WithEpisode = ({
  children,
  selectedEpisode,
  onEpisodeChange,
  episodeDetails,
  showLoader,
  details,
  activeTab,
  showModal,
}) => {
  const addButton = activeTab && activeTab === "alerts";

  const renderEpisodeDropdown = () => {
    return (
      <Col xs={24} sm={12} md={12} lg={8} xl={8}>
        <Select
          name="episode"
          size="large"
          className="full-width"
          placeholder="Select an Episode Number"
          title="Change Episode"
          value={`Episode ${selectedEpisode}`}
          options={Array.from(
            { length: episodeDetails?.currentEpisode },
            (_, index) => index + 1
          )
            .reverse()
            .map((item) => {
              return { label: `Episode ${item}`, value: item };
            })}
          onChange={onEpisodeChange}
        />
      </Col>
    );
  };

  const renderResoleAllAlert = () => {
    return (
      <Col xs={24} sm={12} md={12} lg={6} xl={4} className="flex-center">
        <Button
          size="medium"
          type="primary"
          className="mr05"
          onClick={() => showModal()}
          block
          disabled={details?.length === 0}
        >
          Resolve All
        </Button>
      </Col>
    );
  };

  const renderNoData = () => {
    if (
      (episodeDetails && Object.keys(episodeDetails).length === 0) ||
      !details ||
      Object.keys(details)?.length === 0 ||
      details?.length === 0
    ) {
      return <Empty className="mt2" />;
    } else {
      return (
        <Row gutter={[12, 16]} className="mt1">
          {children}
        </Row>
      );
    }
  };

  return (
    <div className="mt1">
      {showLoader ? (
        <Row gutter={[16, 16]} justify="center" className="mt1">
          <Col span={24}>
            <Spin className="flex-center pd3" size="large" />
          </Col>
        </Row>
      ) : (
        <>
          <Row justify={addButton ? "space-between" : "end"}>
            {addButton && renderResoleAllAlert()}
            {episodeDetails &&
              Object.keys(episodeDetails).length > 0 &&
              renderEpisodeDropdown()}
          </Row>

          {renderNoData()}
        </>
      )}
    </div>
  );
};

export default WithEpisode;
