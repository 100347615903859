import { Col, Row } from "antd";

const AppleHealthDetails = ({ profileDetails }) => {
  return (
    <div style={{ minHeight: "26px" }}>
      <Row gutter={[16, 16]}>
        <Col xs={10} sm={10} md={8} lg={10} xl={8} className="bold">
          Apple Health:
        </Col>
        <Col xs={14} sm={14} md={16} lg={14} xl={16}>
          {profileDetails?.appleHealthData ? (
            <span className="green bold">Connected</span>
          ) : (
            <span className="orange bold">Not Connected</span>
          )}
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="mt05">
        <Col xs={10} sm={10} md={8} lg={10} xl={8} className="bold">
          Google Health:
        </Col>
        <Col xs={14} sm={14} md={16} lg={14} xl={16}>
          {profileDetails?.googleHealthData ? (
            <span className="green bold">Connected</span>
          ) : (
            <span className="orange bold">Not Connected</span>
          )}
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="mt05">
        <Col xs={10} sm={10} md={8} lg={10} xl={8} className="bold">
          GT+ :
        </Col>
        <Col xs={14} sm={14} md={16} lg={14} xl={16}>
          {profileDetails?.hydraDataCollected ? (
            <span className="green bold">Completed</span>
          ) : (
            <span className="orange bold">Not Completed</span>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AppleHealthDetails;
