import {
  Button,
  Col,
  Flex,
  Form,
  Input,
  Row,
  Typography,
  Result,
  Checkbox,
} from "antd";
import { MaskedInput } from "antd-mask-input";
import { useEffect, useState } from "react";
import { postApis } from "../../Common/Apis";
import {
  BUTTONS,
  FORM_FIELDS,
  NOTIFICATIONS,
  PLACEHOLDER,
  VALIDATIONS,
} from "../../Common/Constants";
import { clinicAdminRegisterUrl } from "../../Common/Endpoints";
import { prefixSelector } from "../../Common/FormFields";
import Loader from "../../Common/Loader";
import useNotify from "../../Common/Notify";
import {
  getRemoteConfigData,
  processedPhoneNumber,
  validatePhoneLength,
} from "../../Common/Utils";
import Autocomplete from "react-google-autocomplete";
import { Link } from "react-router-dom";
import TermsOfUseModal from "../../Common/Sessions/TermsOfUseModal";
import PrivacyPolicyModal from "../../Common/Sessions/PrivacyPolicyModal";

const { Title } = Typography;

const ClinicRegistration = () => {
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState();
  const [isRegistered, setIsRegistered] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const { notify } = useNotify();
  const [form] = Form.useForm();

  useEffect(() => {
    getRemoteConfigData("REGISTRATION").then((result) => {
      setContent(result?.clinic);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    const phone = await processedPhoneNumber(values);

    const updatedValues = {
      ...values,
      phoneNumber: phone,
    };
    await postApis(clinicAdminRegisterUrl, updatedValues)
      .then((response) => {
        setLoading(false);
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setIsRegistered(true);
      })
      .catch(() => setLoading(false));
  };
  const onFinishFailed = () => {
    setLoading(false);
  };

  const handleTermsModalCancel = () => {
    setIsTermsModalOpen(false);
  };

  const handlePrivacyModalCancel = () => {
    setIsPrivacyModalOpen(false);
  };

  return (
    <div className="signUpWidget" data-testid="registration">
      {isTermsModalOpen && (
        <TermsOfUseModal
          isTermsModalOpen={isTermsModalOpen}
          handleTermsModalCancel={handleTermsModalCancel}
        />
      )}

      {isPrivacyModalOpen && (
        <PrivacyPolicyModal
          isPrivacyModalOpen={isPrivacyModalOpen}
          handlePrivacyModalCancel={handlePrivacyModalCancel}
        />
      )}

      {isRegistered ? (
        <Result
          status="success"
          title={content?.title}
          subTitle={content?.subTitle}
        />
      ) : (
        <>
          <Flex vertical={true}>
            <Title>Clinic - Registration</Title>
          </Flex>
          <div
            className="text-left"
            dangerouslySetInnerHTML={{ __html: content?.instructions }}
          />
          <Form
            form={form}
            className="mt3"
            initialValues={{ code: "+1", programs: "Audiology" }}
            layout="vertical"
            size="large"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            validateMessages={{
              required: VALIDATIONS.DEFAULT.BLANK,
            }}
          >
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={FORM_FIELDS.CLINIC_NAME}
                  name="clinicName"
                  size="large"
                  rules={[
                    {
                      required: true,
                    },
                    {
                      pattern: /^[A-Za-z\s]+$/,
                      message: VALIDATIONS.PATTERN.STRING,
                    },
                  ]}
                >
                  <Input placeholder={FORM_FIELDS.CLINIC_NAME} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={FORM_FIELDS.CLINIC_ADDRESS}
                  name="clinicAddress"
                  size="large"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Autocomplete
                    apiKey={process.env.REACT_APP_GOOGLE_LOCATIONS_APY_KEY}
                    placeholder={"Address"}
                    className="ant-autocomplete"
                    onPlaceSelected={(place) => {
                      form.setFieldsValue({
                        clinicAddress: place.formatted_address,
                      });
                    }}
                    onBlur={() => {
                      form.setFieldsValue({
                        clinicAddress: "",
                      });
                    }}
                    onChange={(value) => {
                      if (!value) {
                        form.setFieldsValue({
                          clinicAddress: "",
                        });
                      }
                    }}
                    options={{
                      types: ["address"],
                      componentRestrictions: { country: "us" },
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={"Admin Name"}
                  name="userName"
                  size="large"
                  rules={[
                    {
                      required: true,
                    },
                    {
                      pattern: /^[A-Za-z\s]+$/,
                      message: VALIDATIONS.PATTERN.STRING,
                    },
                  ]}
                >
                  <Input placeholder={FORM_FIELDS.NAME} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={"Admin Email"}
                  name="userEmail"
                  size="large"
                  rules={[
                    {
                      required: true,
                    },
                    {
                      type: "email",
                    },
                  ]}
                >
                  <Input placeholder={PLACEHOLDER.EMAIL_ADDRESS} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={"Admin Phone Number"}
                  name="phoneNumber"
                  size="large"
                  rules={[
                    {
                      required: true,
                      validator: validatePhoneLength,
                    },
                  ]}
                >
                  <MaskedInput
                    addonBefore={prefixSelector}
                    mask={"(000)000-0000"}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={"Preferred Program"}
                  name="programs"
                  size="large"
                >
                  <Input value="Audiology" disabled readOnly />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item
                  name="registerAsProvider"
                  className="left"
                  valuePropName="checked"
                >
                  <Checkbox>
                    Would you like to register the same user as a provider under
                    this clinic? View our
                    <Link
                      onClick={() => setIsTermsModalOpen(true)}
                      className="ml025 mr025 underline"
                    >
                      Terms of Use
                    </Link>
                    and
                    <Link
                      onClick={() => setIsPrivacyModalOpen(true)}
                      className="ml025 underline"
                    >
                      Privacy Policy
                    </Link>
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row className="flex-center">
              <Col xs={24} sm={12} md={8} lg={8} xl={4}>
                <Form.Item className="mt2">
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    disabled={loading}
                  >
                    <Loader loading={loading} text={BUTTONS.SUBMIT} />
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </div>
  );
};

export default ClinicRegistration;
