import { Col, Empty, Row } from "antd";
import { displayDateTime, getPageHeader } from "../../Common/Utils";
import VISA from "../../../Assets/images/visa.svg";
import MASTER from "../../../Assets/images/master.svg";

const PaymentDetails = ({ payments, renderProfileDetails }) => {
  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={12} md={12} lg={12} xl={12}>
        <div>
          {renderProfileDetails(
            "Transaction Amount",
            payments?.lastTransaction
              ? `$${payments?.lastTransaction?.amount}`
              : "Not Available"
          )}
        </div>
        <div className="mt1">
          {renderProfileDetails(
            "Time",
            payments?.lastTransaction
              ? displayDateTime(payments?.lastTransaction?.created)
              : "Not Available"
          )}
        </div>
      </Col>

      <Col xs={24} sm={12} md={12} lg={12} xl={12}>
        {payments?.card ? (
          <Row gutter={[16, 16]} className="flex-center">
            <Col xs={24} sm={24} md={24} lg={24} xl={6}>
              <div className="bold">Saved Card:</div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={18}>
              <div className="card box-shadow">
                <Row gutter={[16, 16]}>
                  <Col span={6} className="flex-center">
                    <img
                      src={
                        payments?.card?.display_brand === "mastercard"
                          ? MASTER
                          : VISA
                      }
                      width="100%"
                      alt={payments?.card?.display_brand}
                    />
                  </Col>
                  <Col span={18} className="flex-start-center">
                    {getPageHeader(payments?.card?.display_brand)} ending
                    in {payments?.card?.last4}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        ) : (
          <Empty
            imageStyle={{
              height: 35,
            }}
            description="No cards added yet"
            style={{ fontSize: "12px" }}
          />
        )}
      </Col>
    </Row>
  );
};

export default PaymentDetails;
