import { Button, Col, Form, Input, Row, Select } from "antd";
import { MaskedInput } from "antd-mask-input";
import moment from "moment";
import { setProfileDetails } from "../../../Reducers/profileSlice";
import { putApis } from "../../Common/Apis";
import {
  BUTTONS,
  FORM_FIELDS,
  NOTIFICATIONS,
  VALIDATIONS,
} from "../../Common/Constants";
import { patientUpdateProfileUrl } from "../../Common/Endpoints";
import Loader from "../../Common/Loader";
import {
  displayOnlyDate,
  setUserDetails,
  validateDate,
} from "../../Common/Utils";

const BasicDetails = ({
  loading,
  userDetails,
  setLoading,
  form,
  profileDetails,
  staticDropdownData,
  notify,
  dispatch,
  setShowBasicForm,
  showBasicForm,
  renderProfileDetails,
  initialValues,
}) => {
  const inputStyle =
    profileDetails?.theme === "dark"
      ? {
          backgroundColor: "#141414",
          color: "#fff",
          borderColor: "#424242",
        }
      : {
          backgroundColor: "#fff",
          color: "#000",
        };

  const onFinish = async (values) => {
    setLoading(true);
    const updatedValues = {
      ...values,
      dateOfBirth: moment(new Date(values?.dateOfBirth)).format("MM-DD-YYYY"),
    };

    await putApis(patientUpdateProfileUrl, updatedValues)
      .then((response) => {
        setUserDetails({ ...userDetails, ...response?.data });
        dispatch(setProfileDetails({ ...userDetails, ...response?.data }));
        setLoading(false);
        setShowBasicForm(false);
        notify(NOTIFICATIONS.SUCCESS.KEY, "Profile details has been updated");
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };
  const onFinishFailed = () => {
    setLoading(false);
  };

  return (
    <div>
      {showBasicForm ? (
        <Form
          initialValues={{
            ...initialValues,
            dateOfBirth: moment(profileDetails?.dateOfBirth, [
              "MM-DD-YYYY",
              "YYYY-MM-DD",
            ]).format("MM/DD/YYYY"),
          }}
          form={form}
          layout="vertical"
          size="large"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          validateMessages={{
            required: VALIDATIONS.DEFAULT.BLANK,
          }}
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={FORM_FIELDS.FIRST_NAME}
                name="firstName"
                size="large"
                rules={[
                  {
                    required: true,
                  },
                  {
                    pattern: /^[A-Za-z\s]+$/,
                    message: VALIDATIONS.PATTERN.STRING,
                  },
                ]}
              >
                <Input placeholder={FORM_FIELDS.FIRST_NAME} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={FORM_FIELDS.LAST_NAME}
                name="lastName"
                size="large"
                rules={[
                  {
                    required: true,
                  },
                  {
                    pattern: /^[A-Za-z\s]+$/,
                    message: VALIDATIONS.PATTERN.STRING,
                  },
                ]}
              >
                <Input placeholder={FORM_FIELDS.LAST_NAME} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={FORM_FIELDS.EMAIL_ADDRESS}
                name="email"
                size="large"
                rules={[
                  {
                    required: true,
                  },
                  {
                    type: "email",
                  },
                ]}
              >
                <Input placeholder={FORM_FIELDS.EMAIL_ADDRESS} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={
                  userDetails?.mrn
                    ? FORM_FIELDS.MRN
                    : `${FORM_FIELDS.MRN} (Optional)`
                }
                name="mrn"
                size="large"
                rules={[
                  {
                    required: userDetails?.mrn,
                  },
                  {
                    pattern: /^[0-9]+$/,
                    message: VALIDATIONS.PATTERN.NUMERIC,
                  },
                ]}
              >
                <Input
                  placeholder={FORM_FIELDS.MRN}
                  disabled={userDetails?.mrn ? true : false}
                  readOnly={userDetails?.mrn ? true : false}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={FORM_FIELDS.GENDER}
                name="gender"
                size="large"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="Please Select"
                  options={staticDropdownData?.common?.genders}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={FORM_FIELDS.DOB}
                name="dateOfBirth"
                size="large"
                rules={[
                  {
                    required: true,
                  },
                  {
                    validator: validateDate,
                  },
                ]}
              >
                <MaskedInput
                  mask={"00/00/0000"}
                  placeholder="MM/DD/YYYY"
                  style={inputStyle}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
              <Form.Item
                label="Height in Feet"
                name="feet"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  type="number"
                  placeholder="Feet"
                  min={1}
                  step={1}
                  max={12}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
              <Form.Item
                label="Height in Inches"
                name="inches"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  type="number"
                  placeholder="Inches"
                  min={0}
                  step={1}
                  max={11}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                label={FORM_FIELDS.LBS_WEIGHT}
                name="lbsWeight"
                size="large"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input type="number" placeholder="150" min={1} step={1} />
              </Form.Item>
            </Col>
          </Row>
          <Row className="flex-center">
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <Form.Item className="mt2">
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  disabled={loading}
                >
                  <Loader loading={loading} text={BUTTONS.UPDATE} />
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : (
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            {renderProfileDetails(
              FORM_FIELDS.NAME,
              `${profileDetails?.firstName} ${profileDetails?.lastName}`
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            {renderProfileDetails(
              FORM_FIELDS.EMAIL_ADDRESS,
              profileDetails?.email || "Not Available"
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            {renderProfileDetails(
              FORM_FIELDS.GENDER,
              profileDetails?.gender || "Not Available"
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            {renderProfileDetails(
              FORM_FIELDS.DOB,
              displayOnlyDate(profileDetails?.dateOfBirth) || "Not Available"
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            {renderProfileDetails(
              FORM_FIELDS.MRN,
              profileDetails?.mrn || "Not Available"
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            {renderProfileDetails(
              FORM_FIELDS.PHONE,
              profileDetails?.phoneNumber || "Not Available"
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            {renderProfileDetails("Age", profileDetails?.age)}
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            {renderProfileDetails(
              FORM_FIELDS.FEET_HEIGHT,
              profileDetails?.feet && profileDetails?.inches
                ? `${profileDetails?.feet}.${profileDetails?.inches}`
                : "Not Available"
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            {renderProfileDetails(
              FORM_FIELDS.LBS_WEIGHT,
              profileDetails?.lbsWeight || "Not Available"
            )}
          </Col>
        </Row>
      )}
    </div>
  );
};

export default BasicDetails;
