import { Button, Col, Form, Input, Row, Select } from "antd";
import { MaskedInput } from "antd-mask-input";
import { BUTTONS, FORM_FIELDS, VALIDATIONS } from "../../../Common/Constants";
import Loader from "../../../Common/Loader";
import Popup from "../../../Common/Popup";
import { validatePhoneLength } from "../../../Common/Utils";
const { Option } = Select;
const { TextArea } = Input;

const Refer = ({
  form,
  isModalOpen,
  handleModalCancel,
  loading,
  onFinish,
  onFinishFailed,
}) => {
  const validateWordLimit = (_, value) => {
    if (!value || value.trim().split(/\s+/).length <= 25) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("The input exceeds the 25-word limit."));
  };

  const validateCharLimit = (_, value) => {
    if (!value || value.length <= 1000) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error("The input exceeds the 1000-character limit.")
    );
  };

  const prefixSelector = (name) => {
    return (
      <Form.Item name={name} noStyle>
        <Select style={{ width: 70 }} name="code">
          <Option value="+1">+1</Option>
          <Option value="+91">+91</Option>
        </Select>
      </Form.Item>
    );
  };

  return (
    <Popup
      title="Share"
      open={isModalOpen}
      cancel={handleModalCancel}
      footer={null}
      width={700}
      style={{ top: "25px" }}
    >
      <Form
        initialValues={{ code: "+1", faxCode: "+1" }}
        form={form}
        layout="vertical"
        size="large"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        validateMessages={{
          required: VALIDATIONS.DEFAULT.BLANK,
          types: {
            email: VALIDATIONS.DEFAULT.INVALID_EMAIL,
          },
        }}
      >
        <Form.Item
          label="Provider Name"
          name="providerName"
          size="large"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={FORM_FIELDS.NAME} />
        </Form.Item>

        <Form.Item
          label="Department"
          name="department"
          size="large"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Department" />
        </Form.Item>

        <Form.Item
          label="Contact Number"
          name="phoneNumber"
          size="large"
          rules={[
            {
              required: true,
            },
            { validator: validatePhoneLength },
          ]}
        >
          <MaskedInput
            addonBefore={prefixSelector("code")}
            mask={"(000)000-0000"}
          />
        </Form.Item>

        <Form.Item
          label="FAX Number"
          name="faxNumber"
          size="large"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <MaskedInput
            addonBefore={prefixSelector("faxCode")}
            mask={"(000)000-0000"}
          />
        </Form.Item>

        <Form.Item
          label="Heading"
          name="heading"
          size="large"
          rules={[
            {
              required: true,
            },
            { validator: validateWordLimit },
          ]}
        >
          <Input placeholder="Heading" />
        </Form.Item>

        <Form.Item
          label="Reason"
          name="reason"
          size="large"
          rules={[
            {
              required: true,
            },
            { validator: validateCharLimit },
          ]}
        >
          <TextArea rows={3} placeholder="Reason" />
        </Form.Item>

        <Row className="flex-center mt1">
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Button
              type="primary"
              className="mt05"
              htmlType="submit"
              block
              disabled={loading}
            >
              <Loader loading={loading} text={BUTTONS.SUBMIT} />
            </Button>
          </Col>
        </Row>
      </Form>
    </Popup>
  );
};

export default Refer;
