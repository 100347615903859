import { Button, Card, Col, Empty, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApiLoader } from "../../Common/ApiLoaderContext";
import { getApis } from "../../Common/Apis";
import { ROUTES, SERVICES } from "../../Common/Constants";
import {
  patientRespiratoryEpisodeDetailsUrl,
  patientRespiratoryVitalsUrl,
} from "../../Common/Endpoints";
import PatientVitals from "../../Common/PatientVitals";

const Slit = () => {
  const { showLoader, setShowLoader } = useApiLoader();
  const [graphDetails, setGraphDetails] = useState();
  const [episodeDetails, setEpisodeDetails] = useState();
  const [selectedEpisode, setSelectedEpisode] = useState();
  let { clinic } = useParams();
  const navigate = useNavigate();

  const getEpisodeDetailsData = async () => {
    setShowLoader(true);
    await getApis(patientRespiratoryEpisodeDetailsUrl, {
      clinic: clinic,
      service: SERVICES.SLIT,
    })
      .then((response) => {
        setEpisodeDetails(response?.data);
        if (response?.data?.currentEpisodeNumber) {
          getGraphData(response?.data?.currentEpisodeNumber);
        } else {
          setShowLoader(false);
        }
      })
      .catch(() => setShowLoader(false));
  };

  const getGraphData = async (episodeNumber) => {
    setShowLoader(true);
    await getApis(patientRespiratoryVitalsUrl, {
      episodeNumber: episodeNumber,
      clinic: clinic,
      service: SERVICES.SLIT,
    })
      .then((response) => {
        setShowLoader(false);
        setGraphDetails(response?.data);
      })
      .catch(() => setShowLoader(false));
  };

  useEffect(() => {
    getEpisodeDetailsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEpisodeChange = (value) => {
    setSelectedEpisode(value);
    getGraphData(value);
  };

  const renderOrderButton = () => {
    return (
      <Button
        type="primary"
        block
        onClick={() => navigate(ROUTES.PATIENT.DEVICES.PATH)}
      >
        Order Vials
      </Button>
    );
  };

  return (
    !showLoader && (
      <>
        {episodeDetails?.currentEpisodeNumber && graphDetails ? (
          <div data-testid="general-data">
            <Row gutter={[16, 16]} justify={"space-between"}>
              <Col xs={24} sm={8} md={8} lg={6} xl={4}>
                {renderOrderButton()}
              </Col>

              <Col xs={24} sm={16} md={16} lg={12} xl={10}>
                <Row gutter={[16, 16]}>
                  <Col span={10} className="bold flex-center">
                    Episode Number:
                  </Col>
                  <Col span={14}>
                    <Select
                      name="episode"
                      size="large"
                      className="full-width"
                      placeholder="Please Select"
                      value={
                        selectedEpisode || episodeDetails?.currentEpisodeNumber
                      }
                      options={Array.from(
                        { length: episodeDetails?.currentEpisodeNumber },
                        (_, index) => index + 1
                      ).map((item) => {
                        return { label: item, value: item };
                      })}
                      onChange={onEpisodeChange}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <div data-testid="slit-data">
              <Row gutter={[16, 16]} className="mt1" justify="center">
                {graphDetails?.map((item, index) => {
                  return (
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} key={index}>
                      <Card className="box-shadow">
                        <PatientVitals graphData={item} />
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        ) : (
          <Card>
            <Empty description={"Order Your PFM Device"}>
              <Row gutter={[16, 16]} justify={"center"}>
                <Col xs={24} sm={8} md={8} lg={6} xl={4}>
                  {renderOrderButton()}
                </Col>
              </Row>
            </Empty>
          </Card>
        )}
      </>
    )
  );
};

export default Slit;
