import { Button, Col, Form, Row, Select } from "antd";
import { setProfileDetails } from "../../../Reducers/profileSlice";
import { putApis } from "../../Common/Apis";
import {
  BUTTONS,
  FORM_FIELDS,
  NOTIFICATIONS,
  VALIDATIONS,
} from "../../Common/Constants";
import { patientUpdateProfileUrl } from "../../Common/Endpoints";
import Loader from "../../Common/Loader";
import { setUserDetails } from "../../Common/Utils";

const InsuranceDetails = ({
  loading,
  userDetails,
  setLoading,
  profileDetails,
  notify,
  form,
  dispatch,
  insurances,
  setShowInsuranceForm,
  showInsuranceForm,
  renderProfileDetails,
  initialValues,
}) => {
  const onFinish = async (values) => {
    setLoading(true);

    await putApis(patientUpdateProfileUrl, values)
      .then((response) => {
        setUserDetails({ ...userDetails, ...response?.data });
        dispatch(setProfileDetails({ ...userDetails, ...response?.data }));
        setLoading(false);
        setShowInsuranceForm(false);
        notify(NOTIFICATIONS.SUCCESS.KEY, "Insurance details has been updated");
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };
  const onFinishFailed = () => {
    setLoading(false);
  };

  return (
    <div>
      {showInsuranceForm ? (
        <Form
          initialValues={{ ...initialValues }}
          form={form}
          layout="vertical"
          size="large"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          validateMessages={{
            required: VALIDATIONS.DEFAULT.BLANK,
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label={FORM_FIELDS.PRIMARY_INSURANCE}
                name="insurance"
                size="large"
              >
                <Select
                  showSearch
                  name="insurance"
                  size="large"
                  placeholder="Please Select"
                  options={insurances}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="flex-center">
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  disabled={loading}
                >
                  <Loader loading={loading} text={BUTTONS.UPDATE} />
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : (
        <Row gutter={[16, 16]} style={{ minHeight: "78px" }}>
          <Col span={24}>
            {renderProfileDetails(
              FORM_FIELDS.INSURANCE,
              profileDetails?.insurance || "Not Available"
            )}
          </Col>
        </Row>
      )}
    </div>
  );
};

export default InsuranceDetails;
