import { Form, Input, Select } from "antd";
import { MaskedInput } from "antd-mask-input";
import { Link } from "react-router-dom";
import { FORM_FIELDS, PLACEHOLDER, ROUTES, VALIDATIONS } from "./Constants";
import { validatePhoneLength } from "./Utils";
const { Option } = Select;

export const labelledEmailInput = {
  label: FORM_FIELDS.EMAIL_ADDRESS,
  name: "email",
  rules: [
    {
      required: true,
    },
    {
      type: "email",
    },
  ],
  field: <Input placeholder={PLACEHOLDER.EMAIL_ADDRESS} />,
};

export const emailInput = {
  label: false,
  name: "email",
  rules: [
    {
      required: true,
    },
    {
      type: "email",
    },
  ],
  field: <Input placeholder={FORM_FIELDS.EMAIL_ADDRESS} />,
};

export const nameInput = {
  label: false,
  name: "name",
  rules: [
    {
      required: true,
    },
  ],
  field: <Input placeholder={FORM_FIELDS.NAME} />,
};

export const zipcodeInput = {
  label: false,
  name: "zipcode",
  rules: [
    {
      required: true,
    },
    {
      pattern: /^[0-9]+$/,
      message: "Only numbers are allowed",
    },
  ],
  field: <Input placeholder={FORM_FIELDS.ZIPCODE} />,
};

export const otpInput = {
  label: false,
  name: "otp",
  rules: [
    {
      required: true,
    },
    {
      pattern: /^[0-9]+$/,
      message: "Only numbers are allowed",
    },
  ],
  field: <Input placeholder={FORM_FIELDS.OTP} maxLength={6} />,
};

export const labelledPasswordInput = {
  label: FORM_FIELDS.PASSWORD,
  name: "password",
  rules: [
    {
      required: true,
    },
    {
      min: 8,
      message: VALIDATIONS.DEFAULT.PASSWORD_MIN_LENGTH,
    },
  ],
  field: <Input.Password placeholder={PLACEHOLDER.PASSWORD} />,
};

export const patientForgotPasswordLink = {
  field: (
    <Link to={ROUTES.PATIENT.FORGOT_PASSWORD.PATH} className="right">
      Forgot Password?
    </Link>
  ),
};

export const forgotPasswordLink = (url) => {
  return {
    field: (
      <Link to={url} className="right">
        Forgot Password?
      </Link>
    ),
  };
};

export const passwordInput = {
  label: false,
  name: "password",
  rules: [
    {
      required: true,
    },
    {
      min: 8,
      message: VALIDATIONS.DEFAULT.PASSWORD_MIN_LENGTH,
    },
  ],
  field: <Input.Password placeholder={FORM_FIELDS.PASSWORD} />,
};

export const labelledConfirmPasswordInput = {
  label: FORM_FIELDS.CONFIRM_PASSWORD,
  name: "confirmPassword",
  rules: [
    {
      required: true,
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue("password") === value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(VALIDATIONS.DEFAULT.PASSWORD_MISMATCH));
      },
    }),
  ],
  field: <Input.Password placeholder={PLACEHOLDER.CONFIRM_PASSWORD} />,
};

export const confirmPasswordInput = {
  label: false,
  name: "confirmPassword",
  rules: [
    {
      required: true,
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue("password") === value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(VALIDATIONS.DEFAULT.PASSWORD_MISMATCH));
      },
    }),
  ],
  field: <Input.Password placeholder={FORM_FIELDS.CONFIRM_PASSWORD} />,
};

export const prefixSelector = (
  <Form.Item name="code" noStyle>
    <Select style={{ width: 70 }} name="code">
      <Option value="+1">+1</Option>
      <Option value="+91">+91</Option>
    </Select>
  </Form.Item>
);

export const labelledPhoneInput = {
  label: FORM_FIELDS.PHONE,
  name: "phoneNumber",
  rules: [
    {
      required: true,
      validator: validatePhoneLength,
    },
  ],
  field: <MaskedInput addonBefore={prefixSelector} mask={"(000)000-0000"} />,
};

export const phoneInput = {
  label: false,
  name: "phoneNumber",
  rules: [
    {
      required: true,
      validator: validatePhoneLength,
    },
  ],
  field: <MaskedInput addonBefore={prefixSelector} mask={"(000)000-0000"} />,
};

export const captchaInput = {
  label: false,
  name: "",
  rules: [],
  field: <div id="recaptcha" className="flex-center mt1"></div>,
};
