import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Flex,
  Form,
  Popover,
  Row,
  Table,
} from "antd";
import { useSelector } from "react-redux";
import { FORM_FIELDS, NOTIFICATIONS } from "../../../Common/Constants";
import { providerViewPatient } from "../../../Common/Images";
import Refer from "./Refer";
import { useState } from "react";
import {
  processedFaxNumber,
  processedPhoneNumber,
} from "../../../Common/Utils";
import { postApis } from "../../../Common/Apis";
import useNotify from "../../../Common/Notify";
import { providerViewPatientReferralUrl } from "../../../Common/Endpoints";
import {
  InfoCircleFilled,
  InfoCircleOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import BarGraph from "./BarGraph";

const Audiology = ({ profileDetails, audiologyDetails, content }) => {
  const isMobile = useSelector((state) => state?.app?.isMobile);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { notify } = useNotify();

  const renderProfileDetails = (label, value) => {
    if (value) {
      return (
        <Flex
          className="mb05"
          wrap="wrap"
          gap="small"
          vertical={isMobile ? true : false}
        >
          <div className="bold">{label}: </div>
          <div>{value}</div>
        </Flex>
      );
    }
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
    form.resetFields();
  };

  const renderProfileCard = () => {
    return (
      <Card
        title={"Profile Details"}
        className="box-shadow"
        extra={[
          <Button
            key={"download"}
            icon={<ShareAltOutlined />}
            className="green-btn"
            onClick={showModal}
          >
            Share
          </Button>,
        ]}
      >
        <Row gutter={[16, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            {renderProfileDetails(FORM_FIELDS.GENDER, profileDetails?.gender)}
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            {renderProfileDetails(FORM_FIELDS.AGE, profileDetails?.age)}
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            {renderProfileDetails(FORM_FIELDS.EMAIL, profileDetails?.email)}
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            {renderProfileDetails(
              FORM_FIELDS.PHONE,
              profileDetails?.phoneNumber
            )}
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            {renderProfileDetails(FORM_FIELDS.MRN, profileDetails?.mrn)}
          </Col>
        </Row>
      </Card>
    );
  };

  const riskColumns = [
    {
      title: "Risk Cluster",
      dataIndex: "riskCluster",
      width: 140,
      render: (_, record) => (
        <Popover
          overlayStyle={{
            width: 800,
            maxHeight: 300,
          }}
          content={record?.description}
          trigger="hover"
        >
          <span className="cursor-pointer left">
            {record?.riskCluster} <InfoCircleFilled className="ml025" />
          </span>
        </Popover>
      ),
    },
    {
      title: "Primary Causes of Hearing Loss",
      dataIndex: "primaryCauses",
      render: (text) => <span className="text-left left">{text}</span>,
    },
    {
      title: "Tinnitus Risk",
      dataIndex: "tinnitusRisk",
    },
    {
      title: "Psychological Responses",
      dataIndex: "responses",
      render: (text) => <span className="left text-left">{text}</span>,
    },
  ];

  const insightsColumns = [
    {
      title: "Condition",
      dataIndex: "name",
      width: 220,
      render: (text) => (
        <Popover
          overlayStyle={{
            width: 800,
            maxHeight: 300,
          }}
          content={
            content?.audiology?.insights?.list?.find(
              (item) => text === item?.title
            )?.description
          }
          trigger="hover"
        >
          <span className="cursor-pointer left">
            {text} <InfoCircleFilled className="ml025" />
          </span>
        </Popover>
      ),
    },
    {
      title: "Risk",
      dataIndex: "risk",
    },
    {
      title: "Chance of Progression in 5 years",
      dataIndex: "progression",
      render: (_, record) => `${record?.progression}%`,
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      render: (text) => <span className="left text-left">{text}</span>,
    },
  ];

  const onFinish = async (values) => {
    setLoading(true);
    const phone = await processedPhoneNumber(values);
    const fax = await processedFaxNumber(values?.faxCode, values?.faxNumber);
    const params = {
      ...values,
      referralPhoneNumber: phone,
      referralFaxNumber: fax,
      token: profileDetails?.token,
    };
    delete params["code"];
    delete params["phoneNumber"];
    delete params["faxCode"];
    delete params["faxNumber"];
    await postApis(providerViewPatientReferralUrl, params)
      .then((response) => {
        setLoading(false);
        setIsModalOpen(false);
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  const renderLegends = (item, index) => {
    return (
      <Col key={index} xs={24} sm={24} md={24} lg={12} xl={12}>
        <Flex className="flex-start-center">
          <Popover
            overlayStyle={{
              width: 600,
              maxHeight: 300,
            }}
            content={
              <div
                dangerouslySetInnerHTML={{
                  __html: item?.description,
                }}
              />
            }
            trigger="hover"
          >
            <div
              style={{
                backgroundColor: item?.color,
                height: "15px",
                width: "15px",
                borderRadius: "1rem",
                border: "solid 1px #777",
              }}
              className="cursor-pointer"
            ></div>
          </Popover>
          <Popover
            overlayStyle={{
              width: 600,
              maxHeight: 300,
            }}
            content={
              <div
                dangerouslySetInnerHTML={{
                  __html: item?.description,
                }}
              />
            }
            trigger="hover"
          >
            <div className="ml1 cursor-pointer">
              {item?.title}{" "}
              <InfoCircleOutlined
                style={{ fontSize: "12px" }}
                className="ml025"
              />
            </div>
          </Popover>
        </Flex>
      </Col>
    );
  };

  return (
    <div>
      <Row gutter={[12, 16]} justify="space-between">
        <Col span={24}>{renderProfileCard()}</Col>
      </Row>
      {audiologyDetails ? (
        <div>
          <div className="bold fs16 mt2">
            {content?.audiology?.instructions?.title}
          </div>
          <Divider style={{ margin: "0.25rem 0 1rem 0" }} />
          <div
            dangerouslySetInnerHTML={{
              __html: content?.audiology?.instructions?.description,
            }}
          />

          <div className="bold fs16 mt2">
            {content?.audiology?.aboutUs?.title}
          </div>
          <Divider style={{ margin: "0.25rem 0 1rem 0" }} />
          <div
            dangerouslySetInnerHTML={{
              __html: content?.audiology?.aboutUs?.description,
            }}
          />

          <div className="bold fs16 mt2">The Risk Clusters</div>
          <Divider style={{ margin: "0.25rem 0 1rem 0" }} />

          <Table
            bordered
            className="mt1"
            columns={riskColumns}
            dataSource={content?.audiology?.instructions?.list}
            rowKey={(record) => record.riskCluster}
            pagination={false}
            scroll={{ x: "100%" }}
          />

          <Row gutter={[12, 16]} justify="center" className="mt2">
            <Col span={24}>
              <Card
                title={
                  <Row gutter={[16, 8]} className="flex-start-center">
                    <Col
                      xs={5}
                      sm={2}
                      md={2}
                      lg={2}
                      xl={2}
                      style={{ lineHeight: 0 }}
                    >
                      <img
                        src={providerViewPatient.RISK}
                        alt={"Risk"}
                        width="100%"
                        className="pdtb05"
                      />
                    </Col>
                    <Col
                      xs={19}
                      sm={22}
                      md={22}
                      lg={22}
                      xl={22}
                      className="blue fs18"
                    >
                      Baseline Risk Clustering
                    </Col>
                  </Row>
                }
              >
                <Row gutter={[32, 16]} justify="space-between">
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <img
                      src={audiologyDetails?.graph}
                      alt={"chart"}
                      width="100%"
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <div className="bold mt05">Legend</div>
                    <Divider style={{ margin: "0.25rem 0 1rem 0" }} />
                    <Row
                      gutter={[16, 8]}
                      justify="space-between"
                      className="mt1"
                    >
                      {content?.audiology?.risk?.legends?.map((item, index) =>
                        renderLegends(item, index)
                      )}
                    </Row>
                    <div className="bold mt2">Baseline Risk Score</div>
                    <Divider style={{ margin: "0.25rem 0 1rem 0" }} />

                    <BarGraph audiologyDetails={audiologyDetails} />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Card
            className="mt1"
            title={
              <Row gutter={[16, 8]} className="flex-start-center">
                <Col
                  xs={5}
                  sm={2}
                  md={2}
                  lg={2}
                  xl={2}
                  style={{ lineHeight: 0 }}
                >
                  <img
                    src={providerViewPatient.INFERENCE}
                    alt={"insights"}
                    width="100%"
                    className="pdtb05"
                  />
                </Col>
                <Col
                  xs={19}
                  sm={22}
                  md={22}
                  lg={22}
                  xl={22}
                  className="blue fs18"
                >
                  Insights
                </Col>
              </Row>
            }
            extra={[
              <Button
                key={"download"}
                className="blue-btn"
                onClick={() => window.open(audiologyDetails?.report, "_blank")}
              >
                View Report
              </Button>,
            ]}
          >
            <div>
              <span className="mr025">
                * The below insights have been generated with a confidence score
                of
              </span>
              {audiologyDetails?.confidenceScore}%
            </div>

            <div className="bold fs16 mt1">Primary Cause of Hearing Loss</div>
            <Divider style={{ margin: "0.25rem 0 1rem 0" }} />

            <Table
              bordered
              className="mt1"
              columns={[
                {
                  title: "Cause",
                  dataIndex: "cause",
                  render: (text) => (
                    <span className="left text-left">{text}</span>
                  ),
                },
                {
                  title: "Remarks",
                  dataIndex: "remarks",
                  render: (text) => (
                    <span className="left text-left">{text}</span>
                  ),
                },
              ]}
              dataSource={[
                {
                  cause: audiologyDetails?.primaryCause?.cause,
                  remarks: audiologyDetails?.primaryCause?.remarks,
                },
              ]}
              rowKey={(record) => record.name}
              pagination={false}
              scroll={{ x: "100%" }}
            />

            <div className="bold fs16 mt2">
              Audiological / Mental Wellness Summary
            </div>
            <Divider style={{ margin: "0.25rem 0 1rem 0" }} />

            <Table
              bordered
              className="mt1"
              columns={insightsColumns}
              dataSource={audiologyDetails?.insights}
              rowKey={(record) => record.name}
              pagination={false}
              scroll={{ x: "100%" }}
            />
          </Card>
        </div>
      ) : (
        <Row justify={"center"}>
          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            <Empty
              imageStyle={{
                height: 70,
              }}
              className="mt3 bold"
              description="Please confirm whether the patient has undergone the GT Plus procedure. If the procedure has been completed, kindly wait for the reports to be generated."
            />
          </Col>
        </Row>
      )}

      {isModalOpen && (
        <Refer
          form={form}
          isModalOpen={isModalOpen}
          handleModalCancel={handleModalCancel}
          loading={loading}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        />
      )}
    </div>
  );
};

export default Audiology;
