import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const CustomSpin = ({ color }) => {
  const antIcon = (
    <LoadingOutlined
      style={{
        color,
      }}
      spin
    />
  );

  return <Spin fullscreen size="large" indicator={antIcon} />;
};

export default CustomSpin;
