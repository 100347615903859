import {
  ClockCircleOutlined,
  CloseCircleFilled,
  PlusCircleFilled,
} from "@ant-design/icons";
import {
  Button,
  Carousel,
  Col,
  Divider,
  Form,
  Input,
  Radio,
  Result,
  Row,
  Select,
  Space,
} from "antd";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { BUTTONS, SERVICES, VALIDATIONS } from "../../Common/Constants";
import Loader from "../../Common/Loader";
import Popup from "../../Common/Popup";
import { displayDateTime, isAndroidOrIosApp } from "../../Common/Utils";
const { TextArea } = Input;

const QuestionsForm = ({
  staticQuestionnaires,
  questionnaires,
  onFinish,
  onFinishFailed,
  loading,
  isQuestionnaireModalOpen,
  handleQuestionnaireModalCancel,
  service,
  loadingRowId,
  clinic,
}) => {
  const carouselRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [form] = Form.useForm();
  const isMobile = useSelector((state) => state?.app?.isMobile);
  const isMobileApp = isAndroidOrIosApp();

  const handleNext = async () => {
    try {
      await form.validateFields([`Q${currentSlide + 1}`]);
      if (carouselRef.current) {
        carouselRef.current.next();
      }
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const handlePrev = () => {
    if (currentSlide > 0) {
      carouselRef.current.prev();
      setCurrentSlide((prev) => prev - 1);
    }
  };

  const renderQuestions = (questions) => {
    return questions?.map((item, index) => {
      return (
        <div key={index}>
          {item?.title !== "Vials Details" ? (
            <>
              <div className="mb1" id={`Q${index + 1}`}>
                <span className="form-item-required">*</span>{" "}
                <span className="mr025">{index + 1}.</span> {item?.title}
              </div>
              <Form.Item
                size="large"
                name={`Q${index + 1}`}
                className="ml1"
                rules={[
                  {
                    required: true,
                    message: "Please select an option",
                  },
                ]}
              >
                <Radio.Group>
                  <Space direction="vertical">
                    {item?.options?.map((opt, index) => {
                      return (
                        <Radio key={index} value={index + 1}>
                          {opt?.label}
                        </Radio>
                      );
                    })}
                  </Space>
                </Radio.Group>
              </Form.Item>
            </>
          ) : (
            (((isMobile || isMobileApp) && currentSlide === index) ||
              !isMobile || (isMobileApp && currentSlide === index)) && (
              <>
                <div className="bold fs16">{item?.title}</div>
                <Form.List name="Q6">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name }) => (
                        <div
                          key={key}
                          className="grey-fill pd1 mt1"
                          style={{ position: "relative" }}
                        >
                          {key !== 0 && (
                            <CloseCircleFilled
                              style={{
                                position: "absolute",
                                top: "0",
                                right: "0",
                              }}
                              onClick={() => remove(name)}
                              className="fs24 orange right"
                              title="Delete this row"
                            />
                          )}
                          <Row
                            key={key}
                            gutter={[16, 16]}
                            className={key !== 0 ? "mt105" : "mt1"}
                          >
                            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                              <Form.Item
                                name={[name, "vial"]}
                                rules={[
                                  {
                                    required: true,
                                  },
                                ]}
                                className="mb0"
                              >
                                <Select
                                  name={[name, "vial"]}
                                  placeholder="Vials Given"
                                  size="large"
                                  options={
                                    staticQuestionnaires?.[service]?.vials
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                              <Form.Item
                                name={[name, "drops"]}
                                rules={[
                                  {
                                    required: true,
                                  },
                                ]}
                                className="mb0"
                              >
                                <Select
                                  name={[name, "drops"]}
                                  placeholder="No. of Drops Taken"
                                  size="large"
                                  options={
                                    staticQuestionnaires?.[service]?.drops
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                              <Form.Item
                                name={[name, "reaction"]}
                                rules={[
                                  {
                                    required: true,
                                  },
                                ]}
                                className="mb0"
                              >
                                <TextArea rows={1} placeholder="Reaction" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      ))}
                      <Form.Item className="mt2">
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusCircleFilled className="fs24 green" />}
                        >
                          More Vial Updates
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </>
            )
          )}
        </div>
      );
    });
  };

  return (
    <Popup
      title="Questionnaires"
      open={isQuestionnaireModalOpen}
      cancel={handleQuestionnaireModalCancel}
      width={900}
      footer={null}
      style={{ top: "25px" }}
    >
      {questionnaires?.map((item, index) => {
        const questions =
          staticQuestionnaires?.[service]?.["questionnaires"]?.[item?.subType]
            ?.data || [];

        return (
          <div key={index}>
            {item?.submitted ? (
              <Result
                icon={false}
                title={`${service} - ${
                  staticQuestionnaires?.[service]?.["questionnaires"]?.[
                    item?.subType
                  ]?.label
                } has already been submitted`}
                subTitle={
                  <div>
                    <span className="bold mr05">
                      <ClockCircleOutlined /> Last Submitted On:
                    </span>
                    <span>{displayDateTime(item?.lastUpdated)}</span>
                  </div>
                }
              />
            ) : (
              <>
                <Row justify={"end"} gutter={[16, 16]}>
                  <Col span={24} className="bold fs16">
                    <div className="blue">
                      {service +
                        " - " +
                        staticQuestionnaires?.[service]?.["questionnaires"]?.[
                          item?.subType
                        ]?.label}
                    </div>
                  </Col>
                </Row>
                <Divider style={{ margin: "0.25rem 0 1.5rem 0" }} />
                <Form
                  form={form}
                  name={item?.subType}
                  initialValues={{
                    clinic: clinic,
                    service: service,
                    type: "questionnaires",
                    subType: item?.subType,
                    Q6: [{}],
                  }}
                  size="large"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  validateMessages={{
                    required: VALIDATIONS.DEFAULT.BLANK,
                  }}
                >
                  <Form.Item name="clinic" hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item name="service" hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item name="type" hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item name="subType" hidden>
                    <Input />
                  </Form.Item>
                  {isMobile || isMobileApp ? (
                    <Carousel
                      ref={carouselRef}
                      dots={false}
                      afterChange={(current) => setCurrentSlide(current)}
                    >
                      {renderQuestions(questions)}
                    </Carousel>
                  ) : (
                    <>{renderQuestions(questions)}</>
                  )}

                  <Row justify={"center"} className="mt2">
                    {isMobile || isMobileApp ? (
                      <Col xs={24} sm={24} md={10} lg={6} xl={6}>
                        <Button
                          onClick={handlePrev}
                          disabled={currentSlide === 0}
                          size="middle"
                        >
                          Previous
                        </Button>
                        {currentSlide < questions.length - 1 ? (
                          <Button
                            type="primary"
                            onClick={handleNext}
                            disabled={
                              currentSlide ===
                              (service === SERVICES.SLIT
                                ? questions.length
                                : questions.length - 1)
                            }
                            size="middle"
                            className="right"
                          >
                            Next
                          </Button>
                        ) : (
                          <Button
                            type="primary"
                            size="middle"
                            htmlType="submit"
                            disabled={loading && loadingRowId === item?.subType}
                            className="right"
                          >
                            <Loader
                              loading={
                                loading && loadingRowId === item?.subType
                              }
                              text={BUTTONS.SUBMIT}
                            />
                          </Button>
                        )}
                      </Col>
                    ) : (
                      <Col xs={24} sm={24} md={10} lg={6} xl={6}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          block
                          disabled={loading && loadingRowId === item?.subType}
                          className="mt1"
                        >
                          <Loader
                            loading={loading && loadingRowId === item?.subType}
                            text={BUTTONS.SUBMIT}
                          />
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Form>
              </>
            )}
          </div>
        );
      })}
    </Popup>
  );
};

export default QuestionsForm;
