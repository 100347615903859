import { Button, Col, Collapse, Flex, Form, Row } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useNotify from "../../Common/Notify";
import { getRemoteConfigData, isAndroidOrIosApp } from "../../Common/Utils";
import CollapseSection from "./CollapseSection";
import HealthDetails from "./HealthDetails";
import InsuranceDetails from "./InsuranceDetails";
import LocationDetails from "./LocationDetails";
import AppleHealthDetails from "./AppleHealthDetails";
import BasicDetails from "./BasicDetails";
import ClinicDetails from "./ClinicDetails";
import PaymentDetails from "./PaymentDetails";
import { ROUTES } from "../../Common/Constants";
import { useNavigate } from "react-router-dom";
import {
  CaretRightOutlined,
  EyeFilled,
  LogoutOutlined,
} from "@ant-design/icons";
import { getApis } from "../../Common/Apis";
import { patientProfilePaymentsUrl } from "../../Common/Endpoints";

const PatientSettings = () => {
  const [form] = Form.useForm();
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [staticDropdownData, setStaticDropdownData] = useState();
  const [insurances, setInsurances] = useState();
  const profileDetails = useSelector((state) => state?.profile?.profileDetails);
  const [loading, setLoading] = useState(false);
  const [showBasicForm, setShowBasicForm] = useState(false);
  const [showHealthForm, setShowHealthForm] = useState(false);
  const [showLocationForm, setShowLocationForm] = useState(false);
  const [showInsuranceForm, setShowInsuranceForm] = useState(false);
  const [payments, setPayments] = useState();
  const isMobile = useSelector((state) => state?.app?.isMobile);
  const isMobileApp = isAndroidOrIosApp();
  const dispatch = useDispatch();
  const { notify } = useNotify();
  const navigate = useNavigate();

  const initialValues = {
    ...profileDetails,
    targetSleepStartTime: profileDetails?.targetSleepStartTime
      ? moment(profileDetails.targetSleepStartTime, "hh:mm A")
      : null,
    targetSleepEndTime: profileDetails?.targetSleepEndTime
      ? moment(profileDetails.targetSleepEndTime, "hh:mm A")
      : null,
  };

  const renderProfileDetails = (label, value) => {
    if (value) {
      return (
        <div className="flex-start-center">
          <Flex wrap="wrap" gap="small" vertical={isMobile ? true : false}>
            <div className="bold">{label}: </div>
            <div>{value}</div>
          </Flex>
        </div>
      );
    }
  };

  const getPayments = async () => {
    await getApis(patientProfilePaymentsUrl)
      .then((response) => setPayments(response?.data))
      .catch(() => {});
  };

  useEffect(() => {
    getPayments();

    getRemoteConfigData("DROPDOWNS").then((result) =>
      setStaticDropdownData(result)
    );
    getRemoteConfigData("INSURANCES").then((result) => {
      setInsurances(result);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileDetails]);

  return (
    <div>
      <CollapseSection sectionKey="1" title="Clinic Details">
        <ClinicDetails
          profileDetails={profileDetails}
          staticDropdownData={staticDropdownData}
        />
      </CollapseSection>
      <CollapseSection
        sectionKey="2"
        title="Basic Details"
        showForm={showBasicForm}
        setShowForm={setShowBasicForm}
        form={form}
        initialValues={initialValues}
      >
        <BasicDetails
          form={form}
          showBasicForm={showBasicForm}
          profileDetails={profileDetails}
          loading={loading}
          staticDropdownData={staticDropdownData}
          setLoading={setLoading}
          userDetails={userDetails}
          setShowBasicForm={setShowBasicForm}
          dispatch={dispatch}
          notify={notify}
          initialValues={initialValues}
          renderProfileDetails={renderProfileDetails}
        />
      </CollapseSection>

      <CollapseSection
        sectionKey="3"
        title="Health Stats"
        showForm={showHealthForm}
        setShowForm={setShowHealthForm}
        form={form}
        initialValues={initialValues}
      >
        <HealthDetails
          form={form}
          showHealthForm={showHealthForm}
          profileDetails={profileDetails}
          loading={loading}
          setLoading={setLoading}
          userDetails={userDetails}
          setShowHealthForm={setShowHealthForm}
          dispatch={dispatch}
          notify={notify}
          renderProfileDetails={renderProfileDetails}
          initialValues={initialValues}
        />
      </CollapseSection>

      <CollapseSection
        sectionKey="4"
        title="Location Details"
        showForm={showLocationForm}
        setShowForm={setShowLocationForm}
        form={form}
        initialValues={initialValues}
      >
        <LocationDetails
          form={form}
          initialValues={initialValues}
          showLocationForm={showLocationForm}
          profileDetails={profileDetails}
          loading={loading}
          setLoading={setLoading}
          userDetails={userDetails}
          setShowLocationForm={setShowLocationForm}
          dispatch={dispatch}
          notify={notify}
          renderProfileDetails={renderProfileDetails}
        />
      </CollapseSection>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <CollapseSection
            sectionKey="5"
            title="Insurance Details"
            showForm={showInsuranceForm}
            setShowForm={setShowInsuranceForm}
            form={form}
            initialValues={initialValues}
          >
            <InsuranceDetails
              form={form}
              initialValues={initialValues}
              showInsuranceForm={showInsuranceForm}
              profileDetails={profileDetails}
              loading={loading}
              setLoading={setLoading}
              userDetails={userDetails}
              setShowInsuranceForm={setShowInsuranceForm}
              dispatch={dispatch}
              notify={notify}
              insurances={insurances}
              renderProfileDetails={renderProfileDetails}
            />
          </CollapseSection>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <CollapseSection
            sectionKey="7"
            title="Settings"
            showForm={false}
            setShowForm={() => {}}
          >
            <AppleHealthDetails profileDetails={profileDetails} />
          </CollapseSection>
        </Col>
      </Row>

      <Collapse
        className="mt1"
        collapsible
        defaultActiveKey={["8"]}
        items={[
          {
            key: "8",
            label: (
              <div>
                <span className="left">Payment Details</span>{" "}
                <Button
                  className="right"
                  size="small"
                  onClick={() => navigate(ROUTES.PATIENT.PAYMENTS.PATH)}
                  icon={<EyeFilled />}
                >
                  View More
                </Button>
              </div>
            ),
            children: (
              <PaymentDetails
                payments={payments}
                renderProfileDetails={renderProfileDetails}
              />
            ),
          },
        ]}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      />

      {(isMobile || isMobileApp) && (
        <Button
          className="mt1 blue-btn"
          block
          icon={<LogoutOutlined />}
          onClick={() => navigate(ROUTES.LOGOUT.PATH)}
        >
          Logout
        </Button>
      )}
    </div>
  );
};

export default PatientSettings;
