import { Card, Col } from "antd";
import LOGO from "../../Assets/images/Tinnitus/logo.png";
import ARROW from "../../Assets/images/arrow.svg";

const WwdCards = ({
  isMobile,
  isMobileApp,
  index,
  minHeight,
  imgSrc,
  title,
  description,
  borderColor,
}) => {
  return (
    <>
      <Col xs={24} sm={24} md={12} lg={6} xl={6} className="text-center">
        <Card
          styles={{
            body: {
              minHeight: minHeight,
              padding: "1rem",
              border: `solid 2px ${borderColor}`,
              borderRadius: "8px",
            },
          }}
        >
          <img alt="example" src={imgSrc} width="100%" className="mb05" />
          <div className="blue flex-center fs24 bold mt05">
            <img alt="logo" src={LOGO} className="mr05" />
            {title}
            <img alt="logo" src={LOGO} className="ml05" />
          </div>
          <div className="left-aligned-pgp mt1">{description}</div>
        </Card>
      </Col>
      {index <= 1 && !isMobileApp && !isMobile && (
        <Col span={2} className="flex-center">
          <img alt="arrow" src={ARROW} width="100%" />
        </Col>
      )}
    </>
  );
};

export default WwdCards;
