import { CalendarOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { Card, Col, Flex, Row, Splitter } from "antd";
import { Link } from "react-router-dom";
import { ROUTES } from "../../Common/Constants";
import { patientDashboardImages } from "../../Common/Images";

const Weather = ({ details }) => {
  return (
    details && (
      <Card
        id="Weather"
        title={
          <Row gutter={[16, 16]} justify={"space-between"} className="mt05">
            <Col xs={4} sm={3} md={3} lg={3} xl={3}>
              <img
                src={patientDashboardImages.WEATHER}
                alt="weather"
                width="100%"
              />
            </Col>
            <Col
              xs={17}
              sm={19}
              md={19}
              lg={19}
              xl={19}
              className="bold fs18 flex-start-center"
            >
              Weather
            </Col>
            <Col xs={3} sm={2} md={2} lg={2} xl={2} className="flex-center">
              <Link to={ROUTES.PATIENT.WEATHER.PATH}>
                <img
                  src={patientDashboardImages.GREEN_VIEWMORE}
                  alt="weather"
                  width="100%"
                />
              </Link>
            </Col>
          </Row>
        }
        className="bdr8 box-more-shadow"
        styles={{ body: { padding: "0" }, color: "#141414" }}
      >
        <Splitter>
          <Splitter.Panel collapsible={false} resizable={false}>
            <div className="pd-tlr1-b0">
              <div className="grey fs12">{details?.c1?.label}</div>
              <Flex wrap="wrap" gap="small" className="flex-baseline">
                <div className="fs22 bold">{details?.c1?.value}</div>
                <div className="grey fs12">{details?.c1?.unit}</div>
              </Flex>
            </div>

            <div className="text-center">
              <img
                src={patientDashboardImages.WEATHER_BANNER}
                alt="sleep"
                width="65%"
              />
            </div>

            <Row gutter={[16, 8]} justify={"end"} className="pd05">
              <Col
                xs={24}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="grey fs12 text-right"
              >
                <CalendarOutlined className="mr05" />
                {details?.date}
              </Col>
              <Col
                xs={24}
                sm={10}
                md={10}
                lg={10}
                xl={10}
                className="grey fs12 text-right"
              >
                <ClockCircleOutlined className="mr05" />
                {details?.time}
              </Col>
            </Row>
          </Splitter.Panel>
          <Splitter.Panel>
            <Splitter layout="vertical">
              <Splitter.Panel
                collapsible={false}
                resizable={false}
                className="flex-center"
              >
                <div className="pd1">
                  <Row gutter={[16, 16]} className="flex-center">
                    <Col xs={8} sm={6} md={6} lg={6} xl={6}>
                      <img
                        src={patientDashboardImages.POLLEN}
                        alt="bp"
                        width="100%"
                      />
                    </Col>
                    <Col xs={16} sm={18} md={18} lg={18} xl={18}>
                      <div className="grey fs12">{details?.c2?.label}</div>
                      <Flex wrap="wrap" gap="small" className="flex-baseline">
                        <div className="fs24 bold">{details?.c2?.value}</div>
                        <div className="grey fs12">{details?.c2?.unit}</div>
                      </Flex>
                    </Col>
                  </Row>
                </div>
              </Splitter.Panel>
              <Splitter.Panel
                collapsible={false}
                resizable={false}
                className="flex-center"
              >
                <div className="pd1">
                  <Row gutter={[16, 16]} className="flex-center">
                    <Col xs={8} sm={6} md={6} lg={6} xl={6}>
                      <img
                        src={patientDashboardImages.AQI}
                        alt="bp"
                        width="100%"
                      />
                    </Col>
                    <Col xs={16} sm={18} md={18} lg={18} xl={18}>
                      <div className="grey fs12">{details?.c3?.label}</div>
                      <Flex wrap="wrap" gap="small" className="flex-baseline">
                        <div className="fs24 bold">{details?.c3?.value}</div>
                        <div className="grey fs12">{details?.c3?.unit}</div>
                      </Flex>
                    </Col>
                  </Row>
                </div>
              </Splitter.Panel>
            </Splitter>
          </Splitter.Panel>
        </Splitter>
      </Card>
    )
  );
};

export default Weather;
