import { Col, Row, Segmented } from "antd";
import { patientHealthImages } from "../../Common/Images";
import { getFormattedDateRange } from "../../Common/Utils";
import AreaChart from "./Vitals/AreaChart";
import BarChart from "./Vitals/BarChart";

const RespiratoryRate = ({
  isMobile,
  isTablet,
  frequency,
  healthDetails,
  onFrequencyChange,
}) => {
  const dateRange = getFormattedDateRange(healthDetails?.list, frequency);

  return (
    <>
      <Row gutter={[16, 16]} justify={"space-between"}>
        <Col xs={4} sm={3} md={3} lg={3} xl={4}>
          <img
            src={patientHealthImages.RESPIRATORY}
            alt="active"
            width="100%"
          />
        </Col>

        <Col xs={20} sm={11} md={11} lg={12} xl={11} className="vertical-align">
          <div className="bold blue">Respiratory Rate</div>
          <div className="flex-baseline">
            <div className="fs28 bold">{healthDetails?.value}</div>
            <div className="grey fs12 ml025">breaths/min</div>
          </div>
        </Col>
        <Col xs={24} sm={10} md={10} lg={9} xl={9} className="text-right">
          <Segmented
            options={[
              { label: "Day", value: "day" },
              { label: "Week", value: "week" },
              { label: "Month", value: "month" },
            ]}
            size={isMobile || isTablet ? "large" : "middle"}
            value={frequency}
            onChange={(value) => {
              onFrequencyChange(value, "RESPIRATORY_RATE");
            }}
          />
          <div className="right fs13 bold grey mt05">{dateRange}</div>
        </Col>
      </Row>
      {frequency === "day" ? (
        <AreaChart
          chartDivId={"RespiratoryRateBarChart"}
          healthDetails={healthDetails}
          frequency={frequency}
          color={"#F26511"}
        />
      ) : (
        <BarChart
          chartDivId={"RespiratoryRateBarChart"}
          healthDetails={healthDetails}
          frequency={frequency}
          color={"#F26511"}
        />
      )}
    </>
  );
};

export default RespiratoryRate;
