import { Card, Col, Empty, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApiLoader } from "../../Common/ApiLoaderContext";
import { getApis } from "../../Common/Apis";
import { SERVICES } from "../../Common/Constants";
import {
  patientSurgeryEpisodeDetailsUrl,
  patientSurgeryVitalsUrl,
} from "../../Common/Endpoints";
import PatientVitals from "../../Common/PatientVitals";

const PrePostProcedure = () => {
  const { showLoader, setShowLoader } = useApiLoader();
  const [graphDetails, setGraphDetails] = useState();
  const [episodeDetails, setEpisodeDetails] = useState();
  const [selectedEpisode, setSelectedEpisode] = useState();
  let { clinic } = useParams();

  const getEpisodeDetailsData = async () => {
    setShowLoader(true);
    await getApis(patientSurgeryEpisodeDetailsUrl, {
      clinic: clinic,
      service: SERVICES.PRE_POST_PROCEDURE,
    })
      .then((response) => {
        setEpisodeDetails(response?.data);
        if (response?.data?.currentEpisodeNumber) {
          getGraphData(response?.data?.currentEpisodeNumber);
        } else {
          setShowLoader(false);
        }
      })
      .catch(() => setShowLoader(false));
  };

  const getGraphData = async (episodeNumber) => {
    setShowLoader(true);
    await getApis(patientSurgeryVitalsUrl, {
      episodeNumber: episodeNumber,
      clinic: clinic,
      service: SERVICES.PRE_POST_PROCEDURE,
    })
      .then((response) => {
        setShowLoader(false);
        setGraphDetails(response?.data);
      })
      .catch(() => setShowLoader(false));
  };

  useEffect(() => {
    getEpisodeDetailsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEpisodeChange = (value) => {
    setSelectedEpisode(value);
    getGraphData(value);
  };

  return (
    !showLoader && (
      <>
        {episodeDetails?.currentEpisodeNumber && graphDetails ? (
          <div data-testid="general-data">
            <Row gutter={[16, 16]} className="flex-start-center">
              <Col xs={24} sm={12} md={12} lg={6} xl={4} className="bold">
                Episode Number:
              </Col>

              <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                <Select
                  name="episode"
                  size="large"
                  className="full-width"
                  placeholder="Select an Episode"
                  value={
                    selectedEpisode || episodeDetails?.currentEpisodeNumber
                  }
                  options={Array.from(
                    { length: episodeDetails?.currentEpisodeNumber },
                    (_, index) => index + 1
                  ).map((item) => {
                    return { label: item, value: item };
                  })}
                  onChange={onEpisodeChange}
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]} className="mt1" justify="center">
              {graphDetails?.map((item, index) => {
                return (
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} key={index}>
                    <Card className="box-shadow">
                      <PatientVitals graphData={item} />
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </div>
        ) : (
          <Card>
            <Empty
              description={"Order your devices and start taking recordings"}
            />
          </Card>
        )}
      </>
    )
  );
};

export default PrePostProcedure;
