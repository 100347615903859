import { Button, Col, Flex, Modal, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { BUTTONS, FORM_FIELDS, ROUTES } from "../../../Common/Constants";
import Loader from "../../../Common/Loader";

const Address = ({
  userDetails,
  handleOk,
  isModalOpen,
  handleCancel,
  loading,
}) => {
  const navigate = useNavigate();
  const addressFilled =
    !!userDetails?.addressLine1 || !!userDetails?.addressLine2;

  const renderAddress = () => {
    return addressFilled ? (
      <>
        <Row gutter={[16, 16]} className="mt1">
          <Col span={24}>
            {userDetails?.addressLine1 && (
              <Flex wrap="wrap" gap="small">
                <div>{FORM_FIELDS.ADDRESS_LINE_1}: </div>
                <div className="grey">{userDetails?.addressLine1}</div>
              </Flex>
            )}
          </Col>
          {userDetails?.addressLine2 && (
            <Col span={24}>
              <Flex wrap="wrap" gap="small">
                <div>{FORM_FIELDS.ADDRESS_LINE_2}: </div>
                <div className="grey">{userDetails?.addressLine2}</div>
              </Flex>
            </Col>
          )}
          <Col span={24}>
            {userDetails?.city && (
              <Flex wrap="wrap" gap="small">
                <div>{FORM_FIELDS.CITY}: </div>
                <div className="grey">{userDetails?.city}</div>
              </Flex>
            )}
          </Col>
          <Col span={24}>
            {userDetails?.state && (
              <Flex wrap="wrap" gap="small">
                <div>{FORM_FIELDS.STATE}: </div>
                <div className="grey">{userDetails?.state}</div>
              </Flex>
            )}
          </Col>
          <Col span={24}>
            {userDetails?.zipcode && (
              <Flex wrap="wrap" gap="small">
                <div>{FORM_FIELDS.ZIPCODE}: </div>
                <div className="grey">{userDetails?.zipcode}</div>
              </Flex>
            )}
          </Col>
        </Row>
      </>
    ) : (
      "You have not fill the address. Please fill and place an order again."
    );
  };

  return (
    <Modal
      title="Confirm Your Address"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      width={600}
      footer={[
        addressFilled && (
          <Button
            key="submit"
            type="primary"
            disabled={loading}
            onClick={handleOk}
          >
            <Loader loading={loading} text={BUTTONS.OK} />
          </Button>
        ),
        <Button
          key="link"
          onClick={() => navigate(ROUTES.PATIENT.PROFILE.PATH)}
        >
          {addressFilled ? "Change" : "Add Address"}
        </Button>,
      ]}
    >
      {renderAddress()}
    </Modal>
  );
};

export default Address;
