import React, { useEffect, useState } from "react";
import { Col, Flex, Input, Row, Select, Table, Tag } from "antd";
import { getApis, postApis } from "../../Common/Apis";
import { defaultPageSize, providerClaimsUrl } from "../../Common/Endpoints";
import { useDispatch, useSelector } from "react-redux";
import {
  displayOnlyDate,
  getRemoteConfigData,
  tagColors,
} from "../../Common/Utils";
import { setClaimFilters } from "../../../Reducers/filterSlice";
import useNotify from "../../Common/Notify";
import { NOTIFICATIONS } from "../../Common/Constants";
import { useApiLoader } from "../../Common/ApiLoaderContext";
const { Search } = Input;

const Claims = () => {
  const [claimList, setClaimList] = useState({});
  const [claims, setClaims] = useState([]);
  const [searchQuery, setSearchQuery] = useState();
  const [paginationDetails, setPaginationDetails] = useState({
    current: 1,
    pageSize: defaultPageSize,
  });
  const [fromPage, setFromPage] = useState(1);
  const [forward, setForward] = useState(true);
  const { showLoader, setShowLoader } = useApiLoader();
  const filters = useSelector((state) => state?.filter?.claims);
  const dispatch = useDispatch();
  const [staticDropdownData, setStaticDropdownData] = useState();
  const defaultDoctor = useSelector((state) => state?.filter?.defaultDoctor);
  const { notify } = useNotify();

  const columns = [
    {
      title: "Full Name",
      dataIndex: "fullName",
      sortDirections: ["descend"],
      render: (_, record) => `${record.firstName} ${record.lastName}`,
    },
    {
      title: "MRN",
      dataIndex: "mrn",
    },
    {
      title: "Episode",
      dataIndex: "episodeNumber",
    },
    {
      title: "Episode Start Date",
      dataIndex: "episodeStartDate",
      render: (_, record) => displayOnlyDate(record?.episodeStartDate),
    },
    {
      title: "Raised At",
      dataIndex: "created",
      render: (_, record) => displayOnlyDate(record?.created),
    },
    {
      title: "CPT Codes",
      dataIndex: "cptCodes",
      render: (_, { cptCodes }) => (
        <>
          {cptCodes.map((item, index) => {
            return (
              <Tag color={tagColors[index % tagColors.length]} key={index}>
                {item}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "billingStatus",
      width: 180,
      render: (text, record) =>
        record.billingStatus === "Claimed" ? (
          record.billingStatus
        ) : (
          <Select
            name="billingStatus"
            size="small"
            value={record?.billingStatus}
            placeholder="Select Status"
            options={staticDropdownData?.common?.claimStatus.filter(
              (option) =>
                ![record.billingStatus, "Pending"].includes(option.value)
            )}
            className="full-width"
            onChange={(selectedValue) =>
              onStatusActionChange(selectedValue, record)
            }
          />
        ),
    },
  ];

  const onStatusActionChange = async (value, record) => {
    await postApis(providerClaimsUrl, {
      status: value,
      firstName: record.firstName,
      lastName: record.lastName,
      episodeNumber: record.episodeNumber,
    })
      .then((response) => {
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      })
      .catch((error) => {
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const handleSearch = (query) => {
    setClaimList({});
    setSearchQuery(query);
    setFromPage(1);
    setForward(true);
    setPaginationDetails({
      ...paginationDetails,
      forward: true,
      current: 1,
      searchBy: query,
      paginationSize: paginationDetails.pageSize,
    });

    setClaims(
      claimList?.claims?.filter((record) =>
        Object.values(record).some((value) =>
          value.toString().toLowerCase().includes(query.toLowerCase())
        )
      )
    );
  };

  const getClaims = async () => {
    setShowLoader(true);
    const params = {
      forward: forward,
      doctor: defaultDoctor,
      status: filters?.status,
      searchBy: searchQuery,
      paginationSize: paginationDetails.pageSize,
      firstDate: claimList?.firstDate,
      lastDate: claimList?.lastDate,
    };
    if (searchQuery === "") {
      delete params["searchBy"];
    }
    await getApis(providerClaimsUrl, params)
      .then((response) => {
        setShowLoader(false);
        setClaimList(response?.data);
        setClaims(response?.data?.claims);
      })
      .catch(() => setShowLoader(false));
  };

  useEffect(() => {
    getRemoteConfigData("DROPDOWNS").then((result) =>
      setStaticDropdownData(result)
    );
    setClaimList({});
    getClaims();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationDetails, defaultDoctor]);

  const handlePageChange = (pagination) => {
    setFromPage(pagination.current);
    setForward(pagination.current > fromPage);
    setPaginationDetails({
      ...paginationDetails,
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  const handleStatusChange = (value) => {
    setClaimList({});
    setFromPage(1);
    setForward(true);
    dispatch(setClaimFilters({ status: value }));
    setPaginationDetails({
      ...paginationDetails,
      current: 1,
      forward: true,
      status: value,
      paginationSize: paginationDetails.pageSize,
    });
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    !showLoader && (
      <div data-testid="claims">
        <Row justify={"space-between"} gutter={[16, 8]}>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={6}
            className="flex-start-center"
          >
            {defaultDoctor && (
              <Flex wrap="wrap" justify={"start"}>
                <div className="bold mr05">Provider: </div>
                <div>{defaultDoctor}</div>
              </Flex>
            )}
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Select
              name="claimStatus"
              size="large"
              value={filters?.status}
              placeholder="Select Status"
              options={staticDropdownData?.common?.claimStatus}
              className="full-width"
              onChange={handleStatusChange}
            />
          </Col>
          <Col xs={24} sm={24} md={16} lg={12} xl={12}>
            <Search
              allowClear
              value={searchQuery}
              placeholder="Search by First Name or MRN"
              onSearch={handleSearch}
              onChange={handleSearchInputChange}
              size="large"
              className="full-width search"
            />
          </Col>
        </Row>
        <Row className="mt1">
          <Col span={24}>
            <Table
              rowKey={(record) =>
                `${record.mrn}-${new Date(record?.created).getTime()}`
              }
              columns={columns}
              dataSource={claims}
              scroll={{ x: "100%" }}
              onChange={handlePageChange}
              pagination={{
                current: paginationDetails.current,
                pageSize: paginationDetails.pageSize,
                total: claimList?.totalClaims,
                showSizeChanger: false,
                showQuickJumper: false,
                hideOnSinglePage: true,
                showTotal: (total, range) => (
                  <div className="mt025">
                    Total <b>{total}</b> Items
                  </div>
                ),
              }}
            />
          </Col>
        </Row>
      </div>
    )
  );
};
export default Claims;
