import { Button, Card, Col, Divider, Row } from "antd";
import VIALS from "../../../../Assets/images/Devices/vials.jpg";
import { BUTTONS } from "../../../Common/Constants";
import Loader from "../../../Common/Loader";

const Vials = ({ getVialsOrder, loading }) => {
  return (
    <Card title={"Vials Allergy(Drops)"} styles={{ body: { padding: "0" } }}>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={16}
          lg={16}
          xl={16}
          className="pd1"
          style={{ borderRight: "solid 1px #eee" }}
        >
          <ul>
            <li>
              You can place a new order only 3 months after your previous one.
            </li>
            <li className="mt1">
              You can place an order only if you've had a recent visit with your
              provider.
            </li>
            <li className="mt1">
              Payment for the vials will also be processed in next steps of the
              order here
            </li>
          </ul>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Row justify={"center"}>
            <Col xs={12} sm={8} md={20} lg={22} xl={22}>
              <div className="pd1">
                <img src={VIALS} alt="vials" width="100%" />
              </div>
            </Col>
          </Row>
          <Divider style={{ margin: "0 0" }} />
          <div className="pd1">
            <Button
              type="primary"
              block
              disabled={loading}
              onClick={() => getVialsOrder()}
            >
              <Loader loading={loading} text={BUTTONS.ORDER} />
            </Button>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default Vials;
