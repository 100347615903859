import { Button, Card, Col, Empty, Row, Segmented } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useApiLoader } from "../../Common/ApiLoaderContext";
import { getApis } from "../../Common/Apis";
import {
  patientWellnessUrl,
  patientWellnessWeeklyUrl,
} from "../../Common/Endpoints";
import { isAndroidOrIosApp, postMessageToMobileApp } from "../../Common/Utils";
import CreateQR from "./CreateQR";
import HydraData from "./HydraData";

const Wellness = () => {
  let { clinic } = useParams();
  const profileDetails = useSelector((state) => state.profile?.profileDetails);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [wellnessDetails, setWellnessDetails] = useState();
  const { showLoader, setShowLoader } = useApiLoader();
  const [frequency, setFrequency] = useState("week");
  const [weeklyWellnessDetails, setWeeklyWellnessDetails] = useState();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const isMobileApp = isAndroidOrIosApp();

  const getWellnessData = async () => {
    setShowLoader(true);
    await getApis(patientWellnessUrl, { clinic: clinic })
      .then((response) => {
        setShowLoader(false);
        setWellnessDetails(response?.data);
      })
      .catch(() => setShowLoader(false));
  };

  const getWeeklyWellnessData = async () => {
    await getApis(patientWellnessWeeklyUrl, {
      type: frequency,
      timeZone: timezone,
    })
      .then((response) => {
        setWeeklyWellnessDetails(response?.data);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getWellnessData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getWeeklyWellnessData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frequency]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      {!showLoader && (!wellnessDetails || !weeklyWellnessDetails) && (
        <Card>
          <Empty />
        </Card>
      )}

      {!profileDetails?.hydraDataCollected && (
        <Row gutter={[16, 16]} className="mb1" justify={"space-between"}>
          <Col xs={24} sm={24} md={12} lg={4} xl={4}>
            <Button type="primary" block onClick={() => setIsModalOpen(true)}>
              Get Your GT +
            </Button>
          </Col>
          {isMobileApp && (
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Button
                type="primary"
                block
                onClick={() =>
                  postMessageToMobileApp({ record: true }, "voiceRecord")
                }
              >
                Record Your Speech
              </Button>
            </Col>
          )}
        </Row>
      )}

      {wellnessDetails && <HydraData wellnessDetails={wellnessDetails} />}

      {weeklyWellnessDetails && (
        <Row gutter={[32, 24]} justify="space-between" className="mt2">
          <Col span={24}>
            <Card>
              <Segmented
                options={[
                  { label: "Week", value: "week" },
                  { label: "Month", value: "month" },
                ]}
                value={frequency}
                onChange={(value) => {
                  setFrequency(value);
                }}
                size="large"
              />

              <Row gutter={[16, 16]} justify="space-between" className="mt2">
                {Object.keys(weeklyWellnessDetails).map((key, index) => {
                  return (
                    <Col key={index} xs={24} sm={24} md={12} lg={6} xl={6}>
                      <Card className="orange-card text-center white-text-shadow">
                        <div className="bold fs32">
                          {weeklyWellnessDetails?.[key]}
                        </div>
                        <div className="bold">{key}</div>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </Card>
          </Col>
        </Row>
      )}

      {isModalOpen && (
        <CreateQR
          profileDetails={profileDetails}
          isModalOpen={isModalOpen}
          handleCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default Wellness;
