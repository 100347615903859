import { Collapse, Space } from "antd";

const Troubleshoot = ({ staticContent }) => {
  return (
    <Space direction="vertical" className="full-width">
      {staticContent?.map((item, index) => {
        return (
          <Collapse
            key={index}
            size="large"
            expandIconPosition="end"
            items={[
              {
                key: item?.key,
                label: item?.label,
                children: (
                  <div dangerouslySetInnerHTML={{ __html: item?.children }} />
                ),
              },
            ]}
          />
        );
      })}
    </Space>
  );
};

export default Troubleshoot;
