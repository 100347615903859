import { Button, Col, Form, Row } from "antd";
import Loader from "./Loader";

const CustomForm = ({
  onFinish,
  onFinishFailed,
  formFields,
  initialValues,
  style,
  loading,
  text,
  validations,
}) => {
  const [form] = Form.useForm();

  return (
    <Row>
      <Col span={24} className="flex-center">
        <Form
          className="mt3"
          initialValues={initialValues ? initialValues : {}}
          form={form}
          layout="vertical"
          size="large"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          style={style ? style : {}}
          validateMessages={validations}
        >
          {formFields?.map((item, index) => {
            return (
              <Form.Item
                key={index}
                label={item?.label}
                name={item?.name}
                size="large"
                rules={item?.rules}
              >
                {item?.field}
              </Form.Item>
            );
          })}
          <Form.Item className="mt105">
            <Button
              data-testid="submit"
              type="primary"
              htmlType="submit"
              block
              disabled={loading}
            >
              <Loader loading={loading} text={text} />
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default CustomForm;
