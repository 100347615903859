import { Button, Checkbox, Col, Form, Modal, Row } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setProfileDetails } from "../../../Reducers/profileSlice";
import { putApis } from "../../Common/Apis";
import { BUTTONS, NOTIFICATIONS, VALIDATIONS } from "../../Common/Constants";
import { patientUpdateProfileUrl } from "../../Common/Endpoints";
import Loader from "../../Common/Loader";
import useNotify from "../../Common/Notify";
import { setUserDetails } from "../../Common/Utils";

const TermsAndConditions = ({
  userDetails,
  isTermsModalOpen,
  setIsTermsDocOpen,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { notify } = useNotify();

  const onFinish = async (values) => {
    setLoading(true);
    await putApis(patientUpdateProfileUrl, values)
      .then((response) => {
        setUserDetails({ ...userDetails, ...response?.data });
        dispatch(setProfileDetails({ ...userDetails, ...response?.data }));
        notify(
          NOTIFICATIONS.SUCCESS.KEY,
          "Terms and Conditions status updated"
        );
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  return (
    <Modal
      title={"Terms Of Use"}
      open={isTermsModalOpen}
      closeIcon={false}
      maskClosable={false}
      keyboard={false}
      footer={null}
      width={600}
    >
      <Form
        initialValues={{}}
        layout="vertical"
        size="large"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        validateMessages={{
          required: VALIDATIONS.DEFAULT.BLANK,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item
              name="termsAndConditions"
              valuePropName="checked"
              className="left"
              rules={[
                {
                  validator: (_, value) => {
                    if (!value) {
                      return Promise.reject("Please accept it");
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Checkbox>
                Please accept the{" "}
                <Link onClick={() => setIsTermsDocOpen(true)}>
                  terms and conditions
                </Link>{" "}
                to begin using the app.
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row className="flex-center">
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item>
              <Button type="primary" htmlType="submit" block disabled={loading}>
                <Loader loading={loading} text={BUTTONS.SUBMIT} />
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default TermsAndConditions;
