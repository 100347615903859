import { useEffect } from "react";
import * as echarts from "echarts";
import {
  displayOnlyTime,
  durationToHrsAndMins,
  mapGraphTimeWithValue,
  titleizeWord,
} from "../../../Common/Utils";

const SleepBarChart = ({ color, chartDivId, frequency, healthDetails }) => {
  const filteredData = () => {
    const list = Array.isArray(healthDetails)
      ? healthDetails
      : healthDetails?.list || [];

    if (list.length === 0) {
      return [["No Data", 0]];
    }

    if (frequency === "day") {
      return [
        ["Time", "Type", "Duration"],
        ...list.map((entry) => {
          const { duration, type, startTime } = entry;

          if (!startTime || isNaN(new Date(startTime))) {
            return ["Invalid Time Range", type, 0];
          }

          return [displayOnlyTime(startTime), type, duration];
        }),
      ];
    } else {
      return [
        ["Date", ""],
        ...list.map((entry) => {
          const date = Object.keys(entry)[0];
          let value = entry[date];

          if (value === null) {
            value = 0;
          }
          return mapGraphTimeWithValue(date, value);
        }),
      ];
    }
  };

  const sleepTypeColorMapping = {
    awake: { name: "Awake", color: "#0AAA4A" },
    rem: { name: "REM Sleep", color: "#F26511" },
    light: { name: "Light Sleep", color: "#1FA7B2" },
    deep: { name: "Deep Sleep", color: "#21575B" },
    inBed: { name: "In Bed", color: "#FFC100" },
  };

  useEffect(() => {
    const chartDom = document.getElementById(chartDivId);
    if (!chartDom) return;

    let existingChart = echarts.getInstanceByDom(chartDom);
    if (existingChart) {
      existingChart.dispose();
    }

    const myChart = echarts.init(chartDom);

    const option = {
      tooltip: {
        trigger: "axis",
        formatter: (params) => {
          const { data } = params[0];
          if (frequency === "day") {
            return `${data[0]}<br/><b>${data[1] && titleizeWord(
              data[1]
            )}:</b> ${durationToHrsAndMins(data[2])}`;
          } else {
            return `${data[0]}<br/> ${durationToHrsAndMins(data[1])}`;
          }
        },
      },
      xAxis: {
        type: "category",
        axisLabel: {
          show: true,
          fontSize: 11,
          rotate: 45,
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          show: true,
          fontSize: 11,
        },
      },
      dataset: {
        source: filteredData(),
      },
      grid: {
        bottom: 50,
        left: 45,
        right: 15,
        top: 20,
      },
      series: [
        {
          type: "bar",
          encode: {
            x: frequency === "day" ? "Time" : "Date",
            y: "Duration",
          },
          itemStyle: {
            color: (params) => {
              return sleepTypeColorMapping[params.data[1]]?.color || color;
            },
          },
        },
      ],
    };

    myChart.setOption(option);

    return () => {
      if (myChart) {
        myChart.dispose();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartDivId, frequency, healthDetails]);

  return (
    <div
      className="mt1"
      id={chartDivId}
      style={{ width: "100%", height: "220px" }}
    ></div>
  );
};

export default SleepBarChart;
