import { useEffect, useState } from "react";
import { useApiLoader } from "../../Common/ApiLoaderContext";
import { getApis } from "../../Common/Apis";
import {
  patientDeviceDetailsUrl,
  patientDeviceOrderUrl,
} from "../../Common/Endpoints";
import { getRemoteConfigData } from "../../Common/Utils";
import ConnectedDevices from "./Connected";
import Orders from "./Orders";

const Devices = () => {
  const [deviceDetails, setDeviceDetails] = useState();
  const { showLoader, setShowLoader } = useApiLoader();
  const [deviceImages, setDeviceImages] = useState();
  const [devices, setDevices] = useState();
  const [questionnaires, setQuestionnaires] = useState();
  const [orderDetails, setOrderDetails] = useState();

  const getDeviceDetails = async () => {
    setShowLoader(true);
    await getApis(patientDeviceDetailsUrl)
      .then((response) => {
        setShowLoader(false);
        setDeviceDetails(response?.data);
      })
      .catch(() => setShowLoader(false));
  };

  const getDeviceOrders = async () => {
    setShowLoader(true);
    await getApis(patientDeviceOrderUrl)
      .then((response) => {
        setShowLoader(false);
        setOrderDetails(response?.data);
      })
      .catch(() => setShowLoader(false));
  };

  useEffect(() => {
    getDeviceDetails();
    getDeviceOrders();
    getRemoteConfigData("DEVICES").then((result) => {
      setDeviceImages(result?.images);
      setDevices(result?.devices);
    });
    getRemoteConfigData("ORDERS").then((result) => {
      setQuestionnaires(result);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <ConnectedDevices
        deviceDetails={deviceDetails}
        showLoader={showLoader}
        deviceImages={deviceImages}
        devices={devices}
        orderDetails={orderDetails}
      />
      <Orders
        orderDetails={orderDetails}
        questionnaires={questionnaires}
        deviceDetails={deviceDetails}
        showLoader={showLoader}
        devices={devices}
        deviceImages={deviceImages}
      />
    </div>
  );
};

export default Devices;
