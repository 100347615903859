import { Button, Col, Form, Input, Row, TimePicker } from "antd";
import { setProfileDetails } from "../../../Reducers/profileSlice";
import { putApis } from "../../Common/Apis";
import {
  BUTTONS,
  FORM_FIELDS,
  NOTIFICATIONS,
  VALIDATIONS,
} from "../../Common/Constants";
import { patientUpdateProfileUrl } from "../../Common/Endpoints";
import Loader from "../../Common/Loader";
import { setUserDetails } from "../../Common/Utils";

const HealthDetails = ({
  loading,
  form,
  userDetails,
  setLoading,
  profileDetails,
  notify,
  dispatch,
  setShowHealthForm,
  showHealthForm,
  renderProfileDetails,
  initialValues,
}) => {
  const onFinish = async (values) => {
    const formattedValues = {
      ...values,
      targetSleepStartTime: values?.targetSleepStartTime.format("hh:mm A"),
      targetSleepEndTime: values?.targetSleepEndTime.format("hh:mm A"),
    };
    setLoading(true);
    await putApis(patientUpdateProfileUrl, formattedValues)
      .then((response) => {
        setUserDetails({ ...userDetails, ...response?.data });
        dispatch(setProfileDetails({ ...userDetails, ...response?.data }));
        setLoading(false);
        setShowHealthForm(false);
        notify(NOTIFICATIONS.SUCCESS.KEY, "Health details has been updated");
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };
  const onFinishFailed = () => {
    setLoading(false);
  };

  return (
    <div>
      {showHealthForm ? (
        <Form
          initialValues={{
            ...initialValues,
          }}
          form={form}
          layout="vertical"
          size="large"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          validateMessages={{
            required: VALIDATIONS.DEFAULT.BLANK,
          }}
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                label={FORM_FIELDS.STEPS}
                name="targetSteps"
                rules={[
                  {
                    required: true,
                  },
                  {
                    pattern: /^[0-9]+$/,
                    message: VALIDATIONS.PATTERN.NUMERIC,
                  },
                ]}
              >
                <Input placeholder="Steps In Count" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                label={FORM_FIELDS.CALORIES}
                name="targetCalories"
                rules={[
                  {
                    required: true,
                  },
                  {
                    pattern: /^[0-9]+$/,
                    message: VALIDATIONS.PATTERN.NUMERIC,
                  },
                ]}
              >
                <Input placeholder="Calories In Count" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                label={FORM_FIELDS.SLEEP_START_TIME}
                name="targetSleepStartTime"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TimePicker format="hh:mm A" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                label={FORM_FIELDS.SLEEP_END_TIME}
                name="targetSleepEndTime"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TimePicker format="hh:mm A" />
              </Form.Item>
            </Col>
          </Row>
          <Row className="flex-center">
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <Form.Item className="mt2">
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  disabled={loading}
                >
                  <Loader loading={loading} text={BUTTONS.UPDATE} />
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : (
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            {renderProfileDetails(
              FORM_FIELDS.STEPS,
              profileDetails?.targetSteps || "Not Available"
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            {renderProfileDetails(
              FORM_FIELDS.CALORIES,
              profileDetails?.targetCalories || "Not Available"
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            {renderProfileDetails(
              FORM_FIELDS.SLEEP_START_TIME,
              profileDetails?.targetSleepStartTime || "Not Available"
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            {renderProfileDetails(
              FORM_FIELDS.SLEEP_END_TIME,
              profileDetails?.targetSleepEndTime || "Not Available"
            )}
          </Col>
        </Row>
      )}
    </div>
  );
};

export default HealthDetails;
