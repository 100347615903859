import { Button, Card, Col, Empty, Row } from "antd";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../Common/Constants";
import { patientDashboardImages } from "../../Common/Images";

const Bookings = ({ details }) => {
  const navigate = useNavigate();
  const isMobile = useSelector((state) => state?.app?.isMobile);

  const gridStyle = {
    width: isMobile ? "50%" : "20%",
    textAlign: "center",
  };

  return (
    <div id="Bookings">
      <div className="orange-card-header">
        <Row gutter={[16, 16]} justify={"space-between"}>
          <Col xs={4} sm={3} md={3} lg={2} xl={1}>
            <img
              src={patientDashboardImages.BOOKINGS}
              alt="slit"
              width="100%"
            />
          </Col>
          <Col
            xs={17}
            sm={19}
            md={19}
            lg={20}
            xl={22}
            className="bold fs18 flex-start-center"
          >
            Sleep Therapy Bookings
          </Col>
          <Col xs={3} sm={2} md={2} lg={2} xl={1} className="flex-center">
            <Link to={ROUTES.PATIENT.BOOKINGS.PATH}>
              <img
                src={patientDashboardImages.VIEWMORE}
                alt="view"
                width="100%"
              />
            </Link>
          </Col>
        </Row>
      </div>
      {details ? (
        <Card className="box-more-shadow">
          <Card.Grid style={gridStyle} hoverable={false}>
            <div className="bold fs12">Appointment Date</div>
            <div className="bold blue mt05">{details?.bookingDate}</div>
          </Card.Grid>
          <Card.Grid style={gridStyle} hoverable={false}>
            <div className="bold fs12">Booking ID</div>
            <div className="bold blue mt05">{details?.bookingId}</div>
          </Card.Grid>
          <Card.Grid style={gridStyle} hoverable={false}>
            <div className="bold fs12">Type</div>
            <div className="bold blue mt05">{details?.bookingType}</div>
          </Card.Grid>
          <Card.Grid style={gridStyle} hoverable={false}>
            <div className="bold fs12">Location</div>
            <div className="bold blue mt05">{details?.location}</div>
          </Card.Grid>
          <Card.Grid style={gridStyle} hoverable={false}>
            <div className="bold fs12">Time</div>
            <div className="bold blue mt05">{details?.time}</div>
          </Card.Grid>
        </Card>
      ) : (
        <Card className="text-center">
          <Empty
            imageStyle={{
              height: 70,
            }}
            className="fs13"
            description={"You don't have any bookings available at this time."}
          >
            <Button
              type="primary"
              size="small"
              onClick={() => navigate(ROUTES.PATIENT.BOOKINGS.PATH)}
            >
              Explore Now
            </Button>
          </Empty>
        </Card>
      )}
    </div>
  );
};

export default Bookings;
