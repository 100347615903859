import { Button, Card, Col, Row, Segmented } from "antd";
import { useEffect, useState } from "react";
import { getApis } from "../../Common/Apis";
import {
  patientTinnitusUrl,
  patientTinnitusWeeklyWellnessUrl,
} from "../../Common/Endpoints";
import ONE from "../../../Assets/images/Dashboard/totalWellness.svg";
import TWO from "../../../Assets/images/Dashboard/physical.svg";
import THREE from "../../../Assets/images/Dashboard/cognitive.svg";
import UP from "../../../Assets/images/Dashboard/upArrow.svg";
import DOWN from "../../../Assets/images/Dashboard/downArrow.svg";
import CreateQR from "../Wellness/CreateQR";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useApiLoader } from "../../Common/ApiLoaderContext";
import WellnessCard from "../../Common/WellnessCard";
import { isAndroidOrIosApp, postMessageToMobileApp } from "../../Common/Utils";

const defaultDailyData = {
  1: {
    title: "Total",
    strokeColor: "#FF7D01",
    value: 0,
    delta: 0,
    imgSrc: ONE,
  },
  2: {
    title: "Physical",
    strokeColor: "#1CB4BF",
    value: 0,
    delta: 0,
    imgSrc: TWO,
  },
  3: {
    title: "Cognitive",
    strokeColor: "#FFCD00",
    value: 0,
    delta: 0,
    imgSrc: THREE,
  },
};

const TinnitusStats = () => {
  let { clinic } = useParams();
  const profileDetails = useSelector((state) => state.profile?.profileDetails);
  const { showLoader, setShowLoader } = useApiLoader();
  const [tinnitusDetails, setTinnitusDetails] = useState();
  const [tinnitusweeklyDetails, setTinnitusWeeklyDetails] = useState();
  const [frequency, setFrequency] = useState("week");
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMobileApp = isAndroidOrIosApp();

  const getTinnitusData = async () => {
    setShowLoader(true);
    await getApis(patientTinnitusUrl, {
      clinic: clinic,
      service: "Tinnitus",
    })
      .then((response) => {
        setShowLoader(false);
        setTinnitusDetails(response?.data);
      })
      .catch(() => setShowLoader(false));
  };

  const getTinnitusWeeklyData = async () => {
    await getApis(patientTinnitusWeeklyWellnessUrl, {
      type: frequency,
      timeZone: timezone,
    })
      .then((response) => {
        setTinnitusWeeklyDetails(response?.data);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getTinnitusData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    getTinnitusWeeklyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frequency]);

  return (
    !showLoader && (
      <>
        <Row gutter={[16, 16]} className="mb1" justify={"space-between"}>
          <Col xs={24} sm={24} md={12} lg={4} xl={4}>
            <Button type="primary" block onClick={() => setIsModalOpen(true)}>
              Get Your GT +
            </Button>
          </Col>
          {isMobileApp && (
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Button
                type="primary"
                block
                onClick={() =>
                  postMessageToMobileApp({ record: true }, "voiceRecord")
                }
              >
                Record Your Speech
              </Button>
            </Col>
          )}
        </Row>

        <div className="blue-card">
          <div className="pd2">
            <div className="fs24 bold"> Monitor Your Tinnitus</div>
            <p className="mt1">
              Track and manage your tinnitus and wellness programs all in one
              place.
            </p>
          </div>
        </div>

        {tinnitusDetails && (
          <Row gutter={[32, 24]} justify="space-between" className="mt2">
            {tinnitusDetails?.wellness?.map((item, index) => {
              return (
                <WellnessCard
                  key={index}
                  apiData={item}
                  barImage={defaultDailyData[item?.position]?.imgSrc}
                  arrowImage={item?.delta > 0 ? UP : DOWN}
                />
              );
            })}
            <Col xs={24} sm={12} md={10} lg={6} xl={6}>
              <Card className="text-center box-more-shadow">
                <div className="bold fs42 orange">
                  {tinnitusDetails?.FIScore ? tinnitusDetails?.FIScore : 0}
                </div>
                <div className="bold">Functional Index Score</div>
              </Card>
            </Col>
          </Row>
        )}

        {tinnitusweeklyDetails && (
          <Row gutter={[32, 24]} justify="space-between" className="mt2">
            <Col span={24}>
              <Card>
                <Segmented
                  options={[
                    { label: "Week", value: "week" },
                    { label: "Month", value: "month" },
                  ]}
                  value={frequency}
                  onChange={(value) => {
                    setFrequency(value);
                  }}
                  size="large"
                />

                <Row gutter={[16, 16]} justify="space-between" className="mt2">
                  {Object.keys(tinnitusweeklyDetails).map((key, index) => {
                    return (
                      <Col key={index} xs={24} sm={12} md={10} lg={6} xl={6}>
                        <Card className="blue-card text-center white-text-shadow">
                          <div className="bold fs32">
                            {tinnitusweeklyDetails?.[key]}
                          </div>
                          <div className="bold">{key}</div>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </Card>
            </Col>
          </Row>
        )}

        {isModalOpen && (
          <CreateQR
            profileDetails={profileDetails}
            isModalOpen={isModalOpen}
            handleCancel={handleCancel}
          />
        )}
      </>
    )
  );
};

export default TinnitusStats;
