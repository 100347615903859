import { getPageHeader } from "../../Common/Utils";
import { SERVICES } from "../../Common/Constants";
import { useParams } from "react-router-dom";
import General from "./General";
import Slit from "./Slit";
import Biologics from "./Biologics";
import PrePostProcedure from "./PrePostProcedure";
import Wellness from "./Wellness";
import Tinnitus from "./Tinnitus";
import Audiology from "./Audiology";

const ClinicAdminPatients = () => {
  let { service } = useParams();
  service = service === SERVICES.SLIT ? service : getPageHeader(service);

  const getServicePages = (service) => {
    switch (service) {
      case SERVICES.BIOLOGICS:
        return <Biologics />;
      case SERVICES.SLIT:
        return <Slit />;
      case SERVICES.PRE_POST_PROCEDURE:
        return <PrePostProcedure />;
      case SERVICES.GENERAL:
        return <General />;
      case SERVICES.WELLNESS:
        return <Wellness />;
      case SERVICES.TINNITUS:
        return <Tinnitus />;
      case SERVICES.AUDIOLOGY:
        return <Audiology />;
      default:
        return "";
    }
  };

  return (
    <div data-testid="clinicAdmin-activePatients">
      {getServicePages(service)}
    </div>
  );
};
export default ClinicAdminPatients;
