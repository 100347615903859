import { Button, Card, Table } from "antd";
import { displayDateTime, displayOnlyDate } from "../../Common/Utils";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { DownloadOutlined } from "@ant-design/icons";

const SlitAnswers = ({
  questionnaireDetails,
  profileDetails,
  episodeDetails,
  questionnaires,
}) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const questionKeys = new Set();

  Object.keys(questionnaireDetails?.questionnaires)?.map((item) => {
    return Object.keys(questionnaireDetails?.questionnaires?.[item][0]).forEach(
      (key) => {
        if (!["created", "subType", "type", "Q6"].includes(key)) {
          questionKeys.add(key);
        }
      }
    );
  });

  const questionnairesDownload = (key) => {
    let formattedData;
    formattedData = questionnaireDetails?.questionnaires?.[key]?.map(
      (item) => ({
        "Filled On": displayDateTime(item.created),
        Q1: item.Q1 === 1 ? "Yes" : item.Q1 === 2 ? "No" : "-",
        Q2: item.Q2 === 1 ? "Yes" : item.Q2 === 2 ? "No" : "-",
        Q3: item.Q3 === 1 ? "Yes" : item.Q3 === 2 ? "No" : "-",
        Q4: item.Q4 === 1 ? "Yes" : item.Q4 === 2 ? "No" : "-",
        Q5: item.Q5 === 1 ? "Yes" : item.Q5 === 2 ? "No" : "-",
        "Q6 Vials": Array.isArray(item.Q6)
          ? item.Q6.map((q) => q.vial).join(", ")
          : "-",
        "Q6 Drops Taken": Array.isArray(item.Q6)
          ? item.Q6.map((q) => q.drops).join(", ")
          : "-",
        "Q6 Reaction": Array.isArray(item.Q6)
          ? item.Q6.map((q) => q.reaction).join(", ")
          : "-",
      })
    );

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    const fileName = `${profileDetails?.firstName}_${profileDetails?.lastName}_${episodeDetails?.currentEpisode}_SLIT_questionnaires_${key}`;
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const symptomsDownload = (key) => {
    let formattedData;
    formattedData = questionnaireDetails?.symptoms?.[key]?.map((item) => ({
      "Filled On": displayOnlyDate(item.created),
      Description: item.description,
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    const fileName = `${profileDetails?.firstName}_${profileDetails?.lastName}_${episodeDetails?.currentEpisode}_SLIT_symptoms_${key}`;
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const questionnaireColumns = [
    {
      title: "Filled On",
      dataIndex: "created",
      key: "created",
      render: (_, record) => displayOnlyDate(record?.created),
    },
    ...Array.from(questionKeys).map((key) => ({
      title: key,
      dataIndex: [key],
      key: `${key}`,
      render: (text) => {
        return text ? (text === 1 ? "Yes" : "No") : "-";
      },
    })),
    {
      title: "Q6 Vials",
      dataIndex: ["Q6"],
      key: "Q6.vial",
      render: (text) =>
        text && text.length > 0
          ? text?.map((item) => item?.vial).join(", ")
          : "-",
    },
    {
      title: "Q6 Drops Taken",
      dataIndex: ["Q6"],
      key: "Q6.drops",
      render: (text) =>
        text && text.length > 0
          ? text.map((item) => item.drops).join(", ")
          : "-",
    },
    {
      title: "Q6 Reaction",
      dataIndex: ["Q6"],
      key: "Q6.reaction",
      render: (text) =>
        text && text.length > 0
          ? text.map((item) => item.reaction).join(", ")
          : "-",
    },
  ];

  const symptomsColumns = [
    {
      title: "Filled On",
      dataIndex: "created",
      key: "created",
      render: (_, record) => displayOnlyDate(record?.created),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  const renderQuestionnaires = () => {
    return Object.keys(questionnaireDetails?.questionnaires)?.map(
      (key, index) => {
        return (
          <Card
            title={`${questionnaires?.["SLIT"]?.questionnaires?.[key]?.label} - Questionnaires`}
            key={index}
            extra={
              <Button
                type="primary"
                size="small"
                onClick={() => questionnairesDownload(key)}
              >
                <DownloadOutlined style={{ color: "#fff", fontSize: "16px" }} />
                Download Answers
              </Button>
            }
          >
            {questionnaires?.["SLIT"]?.questionnaires?.[key]?.data?.map(
              (question, questionIndex) => (
                <div
                  key={questionIndex}
                  className={questionIndex > 0 ? "mt2" : ""}
                >
                  {question?.title !== "Vials Details" && (
                    <div className="mb1">
                      <span className="mr025">{questionIndex + 1}.</span>{" "}
                      {question?.title}
                    </div>
                  )}
                  <div className="ml1">
                    {question?.options?.map((option, optionIndex) => (
                      <span key={optionIndex} className="mr1">
                        {optionIndex + 1}. {option.label}
                      </span>
                    ))}
                  </div>
                </div>
              )
            )}

            <Table
              rowKey={(record) => `${new Date(record?.created).getTime()}`}
              dataSource={questionnaireDetails?.questionnaires?.[key]}
              columns={questionnaireColumns}
              bordered
              scroll={{ x: "100%" }}
              pagination={false}
              className="mt2"
            />
          </Card>
        );
      }
    );
  };

  const renderSymptoms = () => {
    return Object.keys(questionnaireDetails?.symptoms)?.map((key, index) => {
      return (
        <Card
          title={`${questionnaires?.["SLIT"]?.symptoms?.[key]?.label} - Symptoms`}
          key={index}
          className="mt1"
          extra={
            <Button
              type="primary"
              size="small"
              onClick={() => symptomsDownload(key)}
            >
              <DownloadOutlined style={{ color: "#fff", fontSize: "16px" }} />
              Download
            </Button>
          }
        >
          <Table
            rowKey={(record) => `${new Date(record?.created).getTime()}`}
            dataSource={questionnaireDetails?.symptoms?.[key]}
            columns={symptomsColumns}
            bordered
            scroll={{ x: "100%" }}
            pagination={false}
          />
        </Card>
      );
    });
  };

  return Object.keys(questionnaireDetails)?.map((key, index) => {
    return key === "questionnaires" ? renderQuestionnaires() : renderSymptoms();
  });
};

export default SlitAnswers;
