import { App } from "antd";
import { useCallback } from "react";

const useNotify = () => {
  const { message } = App.useApp();
  const notify = useCallback(
    (type, description) => {
      message[type](description);
    },
    [message]
  );

  return {
    notify,
  };
};

export default useNotify;
