import { Button, Card, Checkbox, Col, Divider, Form, Row } from "antd";
import { BUTTONS, NOTIFICATIONS } from "../../../Common/Constants";

const DeviceForm = ({
  deviceData,
  devices,
  mandatory,
  onSubmit,
  formId,
  deviceImages,
  remoteConfigDevices,
  notify,
  userDetails,
}) => {
  const [form] = Form.useForm();
  const initialValues = devices?.reduce((acc, device) => {
    acc[device] = mandatory;
    return acc;
  }, {});

  const handleSubmit = (values) => {
    const selectedDevices = Object.keys(values).filter((key) => values[key]);
    if (selectedDevices?.length > 0) {
      const transformedValues = {
        devices: selectedDevices,
        manufacturer: deviceData?.manufacturer,
      };
      onSubmit(transformedValues, formId);
    } else {
      notify(NOTIFICATIONS.ERROR.KEY, "Please select atleast one device");
    }
  };

  const getDeviceData = (device) => {
    const deviceList = remoteConfigDevices?.filter((i) => i?.value === device);
    return deviceList?.[0];
  };

  return (
    <Card
      title={
        deviceData?.program === "SLIT"
          ? "Allergy(Drops)/Biologics"
          : deviceData?.program
      }
      styles={{ body: { padding: "0" } }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinish={handleSubmit}
      >
        <Row>
          <Col
            xs={24}
            sm={24}
            md={16}
            lg={16}
            xl={16}
            className="pd1"
            style={{ borderRight: "solid 1px #eee" }}
          >
            {devices?.map((device, index) => (
              <div key={index} className="mt1">
                <Form.Item
                  name={device}
                  valuePropName="checked"
                  style={{ margin: 0 }}
                >
                  <Checkbox style={{ margin: 0 }} disabled={mandatory}>
                    <Row gutter={[16, 16]}>
                      <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <img
                          src={deviceImages?.[device]}
                          alt={device}
                          width="100%"
                          style={{
                            border: "solid 1px #eee",
                            borderRadius: "4px",
                          }}
                        />
                      </Col>
                      <Col
                        xs={18}
                        sm={18}
                        md={18}
                        lg={18}
                        xl={18}
                        className="flex-start-center"
                      >
                        <div className="display-inline">
                          <div className="bold">{device}</div>
                          <div className="fs12">
                            {getDeviceData(device)?.caption}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Checkbox>
                </Form.Item>
              </div>
            ))}
          </Col>

          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <div className="pd1">
              <div className="fs12 grey text-center bold">
                Scan to get from Clinic
              </div>
              <Row justify={"center"}>
                <Col xs={12} sm={8} md={20} lg={22} xl={22}>
                  <div className="flex-center mt1 box-shadow">
                    <img
                      src={`data:image/png;base64,${userDetails?.qr}`}
                      alt="Base64"
                      width="100%"
                    />
                  </div>
                </Col>
              </Row>
            </div>

            <Divider style={{ margin: "0" }} />
            <Form.Item style={{ margin: "1rem 1rem 0 1rem" }}>
              <Button block type="primary" htmlType="submit">
                {BUTTONS.PLACE_ORDER}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default DeviceForm;
