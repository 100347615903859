import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Steps } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../../../Common/Constants";
import Confirm from "./Confirm";
import Location from "./Location";
import Pay from "./Pay";
import Review from "./Review";
import TimeSlots from "./TimeSlots";

const SleepStudy = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const step = searchParams.get("step");
  const clinic = searchParams.get("clinic");
  const sessionId = searchParams.get("session_id");
  const app = useSelector((state) => state?.app);
  const isMobile = app?.isMobile;
  const isTablet = app?.isTablet;
  const [currentStep, setCurrentStep] = useState();
  const activeDate = moment().add(1, "days");

  useEffect(() => {
    setCurrentStep(step ? parseInt(step) : 0);
  }, [step]);

  const stepItems = () => {
    return [
      {
        title: isMobile || isTablet ? "" : "Location",
      },
      {
        title: isMobile || isTablet ? "" : "Slots",
      },
      {
        title: isMobile || isTablet ? "" : "Sign",
      },
      {
        title: isMobile || isTablet ? "" : "Pay",
      },
      {
        title: isMobile || isTablet ? "" : "Done",
      },
    ];
  };

  const renderStepComponent = () => {
    switch (currentStep) {
      case 0:
        return (
          <Location
            currentStep={currentStep}
            activeDate={activeDate}
            setCurrentStep={setCurrentStep}
          />
        );
      case 1:
        return (
          <TimeSlots
            currentStep={currentStep}
            activeDate={activeDate}
            setCurrentStep={setCurrentStep}
          />
        );
      case 2:
        return (
          <Review currentStep={currentStep} setCurrentStep={setCurrentStep} />
        );
      case 3:
        return (
          <Pay
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            clinic={clinic}
            userDetails={userDetails}
          />
        );
      case 4:
        return (
          <Confirm
            sessionId={sessionId}
            clinic={clinic}
            userDetails={userDetails}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Row>
      <Col span={24}>
        <Button href={ROUTES.PATIENT.BOOKINGS.PATH} size="small">
          <ArrowLeftOutlined className="mr025" /> Back to Bookings
        </Button>
        <Card className="mt1">
          <Steps
            current={currentStep}
            direction="horizontal"
            labelPlacement={isMobile || isTablet ? "horizontal" : "vertical"}
            style={{
              flexDirection: isMobile || isTablet ? "row" : "",
              textAlign: isMobile || isTablet ? "center" : "",
            }}
            items={stepItems()}
          />
          <div data-testid="new-bookings">{renderStepComponent()}</div>
        </Card>
      </Col>
    </Row>
  );
};

export default SleepStudy;
