import { EditOutlined } from "@ant-design/icons";
import { Checkbox, Col, Divider, Form, Radio, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setBookingDetails,
  setTimeSlots,
} from "../../../../Reducers/bookingsSlice";
import { setProfileDetails } from "../../../../Reducers/profileSlice";
import { getApis, putApis } from "../../../Common/Apis";
import {
  BUTTONS,
  FORM_FIELDS,
  ROUTES,
  VALIDATIONS,
} from "../../../Common/Constants";
import {
  bookingsLocationsUrl,
  patientBookingsUrl,
  patientUpdateProfileUrl,
} from "../../../Common/Endpoints";
import {
  centalisedSubmitBtn,
  getOnlyWeekDays,
  getRemoteConfigData,
  setUserDetails,
} from "../../../Common/Utils";

const Location = ({ currentStep, activeDate, setCurrentStep }) => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [locations, setLocations] = useState();
  const [insurances, setInsurances] = useState();
  const [loading, setLoading] = useState(false);
  const bookings = useSelector((state) => state?.bookings);
  const isMobile = useSelector((state) => state?.app?.isMobile);
  const [selectedWeeks, setSelectedWeeks] = useState(false);
  const [selfPay, setSelfPay] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getRemoteConfigData("INSURANCES").then((result) => {
      setInsurances(result);
      const selectedInsurance = result?.find(
        (option) => option.value === userDetails?.insurance
      );
      setSelectedWeeks(selectedInsurance?.weeks);
    });
    getLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLocations = async () => {
    await getApis(bookingsLocationsUrl, { type: "sleep" })
      .then((response) => {
        setLocations(response?.data);
      })
      .catch(() => {});
  };

  const handleInsuranceChange = (value) => {
    const selectedOption = insurances.find((option) => option.value === value);
    if (selectedOption) {
      setSelectedWeeks(selectedOption.weeks);
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    const resultDate = getOnlyWeekDays(activeDate, values?.days);
    let updatedValues = {
      ...values,
      insurance: values?.insurance || userDetails?.insurance,
      weeks: selectedWeeks,
      paymentStatus: values?.paymentStatus ? "Self Pay" : "Insurance",
      bookingDate: activeDate.format("YYYY-MM-DD"),
      returnDate: resultDate.format("YYYY-MM-DD"),
    };
    if (values?.paymentStatus) {
      delete updatedValues["insurance"];
    } else {
      updatedValues = {
        ...updatedValues,
        insurance: values?.insurance || userDetails?.insurance,
      };
    }
    dispatch(setBookingDetails(updatedValues));
    if (!selfPay && values?.insurance) {
      updateProfile({
        insurance: values?.insurance,
        dateOfBirth: userDetails?.dateOfBirth,
      });
    }
    if (selectedWeeks) {
      setCurrentStep(currentStep + 1);
    } else {
      await getApis(patientBookingsUrl, updatedValues)
        .then((response) => {
          setLoading(false);
          dispatch(setTimeSlots(response?.data));
          setCurrentStep(currentStep + 1);
        })
        .catch(() => setLoading(false));
    }
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  const updateProfile = async (data) => {
    if (userDetails?.insurance !== data?.insurance) {
      await putApis(patientUpdateProfileUrl, data)
        .then((response) => {
          setUserDetails({ ...userDetails, ...response?.data });
          dispatch(setProfileDetails({ ...userDetails, ...response?.data }));
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const handleCheckboxChange = (e) => {
    setSelfPay(e.target.checked);
    form.setFieldsValue({ paymentStatus: e.target.checked });

    if (e.target.checked) {
      setSelectedWeeks(null);
      form.setFieldsValue({
        insurance: null,
      });
    }
  };

  return (
    <Row justify={"center"} className="mt2">
      <Col span={24} className="flex-center">
        <Form
          form={form}
          initialValues={{
            location: bookings?.details?.location,
            insurance: bookings?.details?.insurance,
            days: bookings?.details?.days || 2,
            dateOfBirth: userDetails?.dateOfBirth,
          }}
          size="large"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          validateMessages={{
            required: VALIDATIONS.DEFAULT.BLANK,
          }}
          style={{ width: isMobile ? "100%" : 500 }}
        >
          <p className="bold mb2">
            If you opt for self-pay, the nightly rate for the device is $360.
          </p>
          <Form.Item
            label={FORM_FIELDS.LOCATION}
            name="location"
            size="large"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              name="location"
              size="large"
              placeholder="Please Select"
              options={locations}
              onChange={() => {}}
            />
          </Form.Item>

          {!userDetails?.insurance && <Divider>Select Anyone</Divider>}
          <Form.Item name="paymentStatus" valuePropName="checked">
            <Checkbox onChange={handleCheckboxChange}>
              {FORM_FIELDS.SELF_PAY}($360/night) - Proceed Without Insurance
            </Checkbox>
          </Form.Item>
          {userDetails?.insurance ? (
            <div className={selfPay ? "grey" : ""}>
              Selected Primary Insurance: <b>{userDetails?.insurance}</b>{" "}
              {selfPay ? (
                <EditOutlined
                  className="ml05"
                  style={{ color: "#9f9c9c", fontSize: "16px" }}
                />
              ) : (
                <EditOutlined
                  title="Change"
                  className="ml05"
                  style={{ color: "#f26511", fontSize: "16px" }}
                  onClick={() => navigate(ROUTES.PATIENT.PROFILE.PATH)}
                />
              )}
            </div>
          ) : (
            <>
              <Form.Item
                label={FORM_FIELDS.PRIMARY_INSURANCE}
                name="insurance"
                size="large"
                rules={[
                  {
                    required: !selfPay,
                  },
                ]}
              >
                <Select
                  name="insurance"
                  showSearch
                  size="large"
                  placeholder="Please Select"
                  options={insurances}
                  readOnly={selfPay}
                  disabled={selfPay}
                  onChange={handleInsuranceChange}
                />
              </Form.Item>
            </>
          )}

          <Form.Item
            label="Rent device for?"
            name="days"
            size="large"
            rules={[
              {
                required: true,
              },
            ]}
            className="mt2"
          >
            <Radio.Group className="mt05">
              <Radio value={1}>1 Day</Radio>
              <Radio value={2}>2 Days</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item>
            {centalisedSubmitBtn(loading, BUTTONS.NEXT, null, 10)}
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};
export default Location;
