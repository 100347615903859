import { Card, Col, Row, Steps } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import ChooseSlot from "./ChooseSlot";
import Confirm from "./Confirm";
import UserDetails from "./UserDetails";

const SleepManualBook = () => {
  const isMobile = useSelector((state) => state?.app?.isMobile);
  const reOrderDetails = useSelector(
    (state) => state?.bookings?.reOrderDetails
  );
  const [currentStep, setCurrentStep] = useState(0);

  return (
    <Row>
      <Col span={24}>
        <Card>
          {isMobile ? (
            <Steps
              current={currentStep}
              direction="horizontal"
              labelPlacement="horizontal"
              style={{ flexDirection: "row", textAlign: "center" }}
              items={[
                {
                  title: "",
                },
                {
                  title: "",
                },
                {
                  title: "",
                },
              ]}
            />
          ) : (
            <Steps
              current={currentStep}
              direction="horizontal"
              labelPlacement="vertical"
              items={[
                {
                  title: "Patient Details",
                },
                {
                  title: "Choose Slot",
                },
                {
                  title: "Confirm",
                },
              ]}
            />
          )}
          <div data-testid="sleepManualBook">
            {currentStep === 0 && (
              <UserDetails
                setCurrentStep={setCurrentStep}
                reOrderDetails={reOrderDetails}
              />
            )}
            {currentStep === 1 && (
              <ChooseSlot
                setCurrentStep={setCurrentStep}
                currentStep={currentStep}
              />
            )}
            {currentStep === 2 && (
              <Confirm
                setCurrentStep={setCurrentStep}
                currentStep={currentStep}
              />
            )}
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default SleepManualBook;
