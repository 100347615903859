import { Card, Col, Row } from "antd";
import ONE from "../../../Assets/images/Dashboard/totalWellness.svg";
import TWO from "../../../Assets/images/Dashboard/physical.svg";
import THREE from "../../../Assets/images/Dashboard/cognitive.svg";
import UP from "../../../Assets/images/Dashboard/upArrow.svg";
import DOWN from "../../../Assets/images/Dashboard/downArrow.svg";
import WellnessCard from "../../Common/WellnessCard";

const Risk = ({ riskDetails }) => {
  const defaultDailyData = {
    1: {
      title: "Total",
      strokeColor: "#FF7D01",
      value: 0,
      delta: 0,
      imgSrc: ONE,
    },
    2: {
      title: "Physical",
      strokeColor: "#1CB4BF",
      value: 0,
      delta: 0,
      imgSrc: TWO,
    },
    3: {
      title: "Cognitive",
      strokeColor: "#FFCD00",
      value: 0,
      delta: 0,
      imgSrc: THREE,
    },
  };

  return (
    <Row gutter={[32, 24]} justify="space-between" className="mt2">
      <Col xs={24} sm={12} md={10} lg={6} xl={6}>
        <Row>
          <Col span={24}>
            <Card className="text-center box-more-shadow">
              <div className="bold fs42 orange">
                {riskDetails?.FIScore ? riskDetails?.FIScore : 0}
              </div>
              <div className="bold">FI Score</div>
            </Card>
          </Col>
        </Row>
        <Row className="mt1025">
          <Col span={24}>
            <Card className="text-center box-more-shadow">
              <div className="bold fs42 orange">
                {riskDetails?.riskScore ? riskDetails?.riskScore : 0}
              </div>
              <div className="bold">Risk Score</div>
            </Card>
          </Col>
        </Row>
      </Col>
      {riskDetails?.wellness?.map((item, index) => {
        return (
          <WellnessCard
            key={index}
            apiData={item}
            barImage={defaultDailyData[item?.position]?.imgSrc}
            arrowImage={item?.delta > 0 ? UP : DOWN}
          />
        );
      })}
    </Row>
  );
};

export default Risk;
