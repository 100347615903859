import { Button, Card, Col, Divider, Row } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../Common/Constants";
import { displayDateTime, postMessageToMobileApp } from "../../../Common/Utils";

const List = ({
  deviceDetails,
  deviceImages,
  deviceConnectionDetails,
  isMobileApp,
  showRingModal,
  remoteConfigDevices,
}) => {
  const getDeviceData = (device) => {
    const deviceList = remoteConfigDevices?.filter((i) => i?.value === device);
    return deviceList?.[0];
  };
  const navigate = useNavigate();

  return (
    <div>
      {deviceDetails?.length > 0 && (
        <Card title={"Connected"}>
          <Row gutter={[16, 16]}>
            {deviceDetails?.map((item, index) => {
              return (
                <Col key={index} xs={24} sm={24} md={24} lg={16} xl={12}>
                  <div key={index} className="grey-fill pd05">
                    <Row gutter={[16, 16]}>
                      <Col
                        xs={24}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}
                        className="flex-start-center"
                      >
                        <img
                          alt={item?.device}
                          src={deviceImages?.[item?.device]}
                          width="100%"
                        />
                      </Col>
                      <Col xs={24} sm={14} md={14} lg={14} xl={14}>
                        <div className="bold fs16 blue flex-start-center">
                          {getDeviceData(item?.device)?.label}
                        </div>
                        <div className="fs13 grey mt025">
                          {item?.lastUpdated && (
                            <div>
                              Last Measured On:{" "}
                              <span className="black">
                                {displayDateTime(item?.lastUpdated)}
                              </span>
                            </div>
                          )}
                          <div>
                            MAC:{" "}
                            <span className="black">
                              {item?.macAddress || "NA"}
                            </span>
                          </div>
                        </div>
                        {item?.device === "Saiwell_Ring" && (
                          <Row>
                            <Col xs={24} sm={16} md={12} lg={10} xl={8}>
                              <Button
                                className="mt05"
                                block
                                size="small"
                                onClick={() =>
                                  navigate(ROUTES.PATIENT.RING.PATH)
                                }
                              >
                                View Data
                              </Button>
                            </Col>
                          </Row>
                        )}
                      </Col>

                      {isMobileApp && item?.device === "Saiwell_Ring" && (
                        <Col
                          xs={24}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          style={{ borderLeft: "solid 1px #e0e0e0" }}
                          className="text-center"
                        >
                          <div>
                            <Link
                              key="edit"
                              title="Edit"
                              onClick={() => showRingModal("edit")}
                            >
                              Edit
                            </Link>
                          </div>
                          <Divider />
                          <div>
                            {deviceConnectionDetails?.[
                              item?.device === "Saiwell_Ring"
                                ? "SaiwellRing"
                                : item?.device
                            ] ? (
                              <span className="default-cursor green">
                                Connected
                              </span>
                            ) : (
                              <Link
                                key="activate"
                                title="Connect"
                                onClick={() =>
                                  postMessageToMobileApp(
                                    {
                                      activate: true,
                                      source: "devices",
                                    },
                                    "connectToDevice"
                                  )
                                }
                              >
                                Connect
                              </Link>
                            )}
                          </div>
                        </Col>
                      )}
                    </Row>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Card>
      )}
    </div>
  );
};

export default List;
