import React, { useEffect } from "react";
import * as echarts from "echarts";
import { titleizeWord } from "../../../Common/Utils";

const BarGraph = ({ audiologyDetails }) => {
  useEffect(() => {
    const chartElement = document.getElementById("bar-chart");
    const chartInstance = echarts.init(chartElement);

    const categories = Object.keys(audiologyDetails?.score).map(titleizeWord);
    const values = Object.values(audiologyDetails?.score);
    const colors = ["#FFC100", "#F26511", "#12A205"];

    const options = {
      title: {},
      tooltip: {
        trigger: "item",
      },
      grid: {
        left: "20%",
        right: "10%",
        top: "5%",
        bottom: "10%",
      },
      xAxis: {
        show: false,
      },
      yAxis: {
        type: "category",
        data: categories,
        axisTick: { show: false },
      },
      series: [
        {
          name: "Score",
          type: "bar",
          data: values.map((value, index) => ({
            value,
            itemStyle: {
              color: colors[index],
            },
          })),
          barWidth: "40%",
          label: {
            show: true,
            position: "right",
            color: "#777",
            fontWeight: "bold",
            formatter: "{c}%",
          },
        },
      ],
    };

    chartInstance.setOption(options);

    return () => {
      chartInstance.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      id="bar-chart"
      style={{
        width: "100%",
        height: "200px",
      }}
    ></div>
  );
};

export default BarGraph;
