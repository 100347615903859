import { CalendarOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { Button, Card, Col, Empty, Flex, Row, Splitter } from "antd";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { postApis } from "../../Common/Apis";
import { NOTIFICATIONS, ROUTES, SERVICES } from "../../Common/Constants";
import { patientQuestionnairesUrl } from "../../Common/Endpoints";
import { patientDashboardImages } from "../../Common/Images";
import useNotify from "../../Common/Notify";
import QuestionsForm from "../Questionnaires/QuestionsForm";
import SymptomsForm from "../Questionnaires/SymptomsForm";

const Slit = ({ clinic, details, staticQuestionnaires }) => {
  const [loading, setLoading] = useState(false);
  const [isSymptomsModalOpen, setIsSymptomsModalOpen] = useState(false);
  const [loadingRowId, setLoadingRowId] = useState();
  const [isQuestionnaireModalOpen, setIsQuestionnaireModalOpen] =
    useState(false);
  const { notify } = useNotify();
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setLoading(true);
    const {
      clinic,
      service,
      type,
      subType,
      feet,
      inches,
      lbsWeight,
      ...answers
    } = values;
    setLoadingRowId(subType);
    const data = {
      ...{ clinic, service, type, subType },
      answers,
    };
    await postApis(patientQuestionnairesUrl, data)
      .then((response) => {
        setLoading(false);
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setIsSymptomsModalOpen(false);
        setIsQuestionnaireModalOpen(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = async () => {
    setLoading(false);
  };

  const handleSymptomsModalCancel = () => {
    setIsSymptomsModalOpen(false);
  };

  const handleQuestionnaireModalCancel = () => {
    setIsQuestionnaireModalOpen(false);
  };

  const logSymptomsButton = () => {
    return (
      <Button size="small" block onClick={() => setIsSymptomsModalOpen(true)}>
        Log Symptoms
      </Button>
    );
  };

  const orderVialsButton = () => {
    return (
      <Button
        size="small"
        block
        type="primary"
        className="yellow-btn"
        onClick={() => navigate(ROUTES.PATIENT.DEVICES.PATH)}
      >
        Order Vials
      </Button>
    );
  };

  return (
    <>
      <Card
        id="Slit"
        title={
          <Row gutter={[16, 16]} justify={"space-between"} className="mt05">
            <Col xs={4} sm={3} md={3} lg={3} xl={3}>
              <img src={patientDashboardImages.SLIT} alt="slit" width="100%" />
            </Col>
            <Col
              xs={17}
              sm={19}
              md={19}
              lg={19}
              xl={19}
              className="bold fs18 flex-start-center"
            >
              Allergy Drops
            </Col>
            <Col xs={3} sm={2} md={2} lg={2} xl={2} className="flex-center">
              <Link to={ROUTES.PATIENT.SLIT.PATH.replace(":clinic", clinic)}>
                <img
                  src={patientDashboardImages.YELLOW_VIEWMORE}
                  alt="slit"
                  width="100%"
                />
              </Link>
            </Col>
          </Row>
        }
        className="bdr8 box-more-shadow"
        styles={{ body: { padding: "0" }, color: "#141414" }}
      >
        {details?.statistics ? (
          <Splitter>
            <Splitter.Panel collapsible={false} resizable={false}>
              <Row gutter={[8, 8]} justify={"start"} className="pd1">
                <Col span={12}>
                  <div className="grey fs12">PEF</div>
                  <Flex wrap="wrap" gap="small" className="flex-baseline">
                    <div className="fs22 bold">{details?.statistics?.pef}</div>
                    <div className="grey fs12">L/min</div>
                  </Flex>
                </Col>
                <Col span={12}>{orderVialsButton()}</Col>
              </Row>

              <div className="text-center">
                <img
                  src={patientDashboardImages.RESPIRATORY}
                  alt="respiratory"
                  width="70%"
                />
              </div>

              <Row
                gutter={[16, 8]}
                justify={"end"}
                className="pd05"
                style={{ bottom: 0, position: "absolute", width: "52%" }}
              >
                <Col
                  xs={24}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="grey fs12 text-right"
                >
                  <CalendarOutlined className="mr05" />
                  {details?.statistics?.date}
                </Col>
                <Col
                  xs={24}
                  sm={10}
                  md={10}
                  lg={10}
                  xl={10}
                  className="grey fs12 text-right"
                >
                  <ClockCircleOutlined className="mr05" />
                  {details?.statistics?.time}
                </Col>
              </Row>
            </Splitter.Panel>
            <Splitter.Panel>
              <Splitter layout="vertical">
                <Splitter.Panel collapsible={false} resizable={false}>
                  <div className="pdlr1">
                    <Row gutter={[16, 16]} className="flex-center">
                      <Col xs={8} sm={6} md={6} lg={6} xl={6}>
                        <img
                          src={patientDashboardImages.SLIT_FEV}
                          alt="fev"
                          width="100%"
                        />
                      </Col>
                      <Col xs={16} sm={18} md={18} lg={18} xl={18}>
                        <div className="grey fs12">FEV</div>
                        <Flex wrap="wrap" gap="small" className="flex-baseline">
                          <div className="bold fs22">
                            {details?.statistics?.fev}
                          </div>
                          <div className="grey fs12">%</div>
                        </Flex>
                      </Col>
                    </Row>
                  </div>
                </Splitter.Panel>
                <Splitter.Panel collapsible={false} resizable={false}>
                  <div className="pdlr1">
                    <Row gutter={[16, 16]} className="flex-center">
                      <Col xs={8} sm={6} md={6} lg={6} xl={6}>
                        <img
                          src={patientDashboardImages.SLIT_BEST_PEF}
                          alt="bestPef"
                          width="100%"
                        />
                      </Col>
                      <Col xs={16} sm={18} md={18} lg={18} xl={18}>
                        <div className="grey fs12">Best PEF</div>
                        <Flex wrap="wrap" gap="small" className="flex-baseline">
                          <div className="bold fs22">
                            {details?.statistics?.bestPef}
                          </div>
                          <div className="grey fs12">L/min</div>
                        </Flex>
                      </Col>
                    </Row>
                  </div>
                </Splitter.Panel>
                <Splitter.Panel collapsible={false} resizable={false}>
                  <div className="pdlr1">
                    <Row gutter={[16, 16]} className="flex-center">
                      <Col span={24}>
                        <Button
                          size="small"
                          block
                          type="primary"
                          className="yellow-btn"
                          onClick={() => setIsQuestionnaireModalOpen(true)}
                        >
                          Fill Questionnaires
                        </Button>
                      </Col>
                      <Col span={24}>{logSymptomsButton()}</Col>
                    </Row>
                  </div>
                </Splitter.Panel>
              </Splitter>
            </Splitter.Panel>
          </Splitter>
        ) : (
          <Empty
            imageStyle={{
              height: 60,
            }}
            className="pd1 fs13"
            description={"Order Your PFM Device"}
          >
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={8} md={8} lg={12} xl={8}>
                {orderVialsButton()}
              </Col>
              <Col xs={24} sm={8} md={8} lg={12} xl={8}>
                {logSymptomsButton()}
              </Col>
              <Col xs={24} sm={8} md={8} lg={12} xl={8}>
                <Button
                  block
                  size="small"
                  type="primary"
                  className="yellow-btn"
                  onClick={() => setIsQuestionnaireModalOpen(true)}
                >
                  Fill Questionnaires
                </Button>
              </Col>
            </Row>
          </Empty>
        )}
      </Card>

      {isSymptomsModalOpen && (
        <SymptomsForm
          handleSymptomsModalCancel={handleSymptomsModalCancel}
          isSymptomsModalOpen={isSymptomsModalOpen}
          symptomDetails={details?.symptoms}
          onSymptomsFinish={onFinish}
          onSymptomsFinishFailed={onFinishFailed}
          loading={loading}
          clinic={clinic}
          service={SERVICES.SLIT}
        />
      )}
      {isQuestionnaireModalOpen && (
        <QuestionsForm
          isQuestionnaireModalOpen={isQuestionnaireModalOpen}
          handleQuestionnaireModalCancel={handleQuestionnaireModalCancel}
          staticQuestionnaires={staticQuestionnaires?.[clinic]}
          questionnaires={details?.questionnaires}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          loading={loading}
          service={SERVICES.SLIT}
          loadingRowId={loadingRowId}
          clinic={clinic}
        />
      )}
    </>
  );
};

export default Slit;
