import { ClockCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row } from "antd";
import { BUTTONS, SERVICES, VALIDATIONS } from "../../Common/Constants";
import Loader from "../../Common/Loader";
import Popup from "../../Common/Popup";
import { displayDateTime } from "../../Common/Utils";
const { TextArea } = Input;

const SymptomsForm = ({
  symptomDetails,
  onSymptomsFinish,
  onSymptomsFinishFailed,
  loading,
  isSymptomsModalOpen,
  handleSymptomsModalCancel,
  clinic,
  service,
}) => {
  return (
    <Popup
      title="Symptoms"
      open={isSymptomsModalOpen}
      cancel={handleSymptomsModalCancel}
      width={700}
      footer={null}
    >
      <div>
        <span className="bold mr05">
          <ClockCircleOutlined /> Last Submitted On:
        </span>
        <span>{displayDateTime(symptomDetails?.lastUpdated)}</span>
      </div>
      <div className="mt2 mb2">
        If you are experiencing symptoms from Sublingual Immunotherapy, please
        report it here.
      </div>
      <Form
        name={`${clinic}-${service}-symptoms`}
        initialValues={{
          clinic: clinic,
          service: SERVICES.SLIT,
          type: "symptoms",
          subType: symptomDetails?.subType,
        }}
        size="large"
        onFinish={onSymptomsFinish}
        onFinishFailed={onSymptomsFinishFailed}
        autoComplete="off"
        validateMessages={{
          required: VALIDATIONS.DEFAULT.BLANK,
        }}
      >
        <Form.Item name="clinic" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="service" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="type" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="subType" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TextArea rows={4} placeholder="Symptoms" />
        </Form.Item>
        <Row justify={"center"}>
          <Col xs={12} sm={10} md={10} lg={6} xl={6}>
            <Button
              type="primary"
              htmlType="submit"
              block
              disabled={loading}
              className="mt1"
            >
              <Loader loading={loading} text={BUTTONS.SUBMIT} />
            </Button>
          </Col>
        </Row>
      </Form>
    </Popup>
  );
};

export default SymptomsForm;
