import { Form, Input } from "antd";
import { BUTTONS, VALIDATIONS } from "../../../Common/Constants";
import Popup from "../../../Common/Popup";
import { centalisedSubmitBtn } from "../../../Common/Utils";

const VialsReason = ({
  vials,
  loading,
  onFinish,
  onFinishFailed,
  reasonModalOpen,
  handleReasonCancel,
}) => {
  const [form] = Form.useForm();
  const { TextArea } = Input;

  return (
    <Popup
      title={"Reason To Order"}
      open={reasonModalOpen}
      cancel={handleReasonCancel}
      footer={null}
      width={500}
    >
      <Form
        initialValues={{}}
        form={form}
        layout="vertical"
        size="large"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        validateMessages={{
          required: VALIDATIONS.DEFAULT.BLANK,
        }}
      >
        <p>{vials?.message}</p>

        <Form.Item
          className="mt2"
          name="reason"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TextArea rows={3} placeholder="Reason" />
        </Form.Item>
        <Form.Item>
          {centalisedSubmitBtn(loading, BUTTONS.SUBMIT, null, 8)}
        </Form.Item>
      </Form>
    </Popup>
  );
};

export default VialsReason;
