import { Flex, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { setOtpSent } from "../../../../Reducers/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { BUTTONS, NOTIFICATIONS, ROUTES, VALIDATIONS } from "../../Constants";
import { clinicAdminEmailLoginUrl } from "../../Endpoints";
import { postApis } from "../../Apis";
import useNotify from "../../Notify";
import CustomForm from "../../CustomForm";
import {
  emailInput,
  forgotPasswordLink,
  passwordInput,
} from "../../FormFields";
import { USER_TYPES } from "../../Constants";
import { providerEmailLoginUrl } from "../../Endpoints";

const { Title } = Typography;

const Login = () => {
  const [loading, setLoading] = useState(false);
  const userType = useSelector((state) => state?.app?.userType);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { notify } = useNotify();

  useEffect(() => {
    const adminDetails = JSON.parse(localStorage.getItem("clinicAdminDetails"));
    if (adminDetails) {
      localStorage.clear();
    }
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    await postApis(
      userType === USER_TYPES.CLINIC_ADMIN
        ? clinicAdminEmailLoginUrl
        : providerEmailLoginUrl,
      values
    )
      .then((response) => {
        dispatch(setOtpSent(true));
        window.confirmationResult = response?.data;
        navigate(
          userType === USER_TYPES.CLINIC_ADMIN
            ? ROUTES.CLINIC_ADMIN.LOGIN.PATHS.VERIFY_OTP
            : ROUTES.PROVIDER.LOGIN.PATHS.VERIFY_OTP
        );
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };
  const onFinishFailed = () => {
    setLoading(false);
  };

  return (
    <div>
      <Title>
        {userType === USER_TYPES.CLINIC_ADMIN
          ? ROUTES.CLINIC_ADMIN.LOGIN.LABEL
          : ROUTES.PROVIDER.LOGIN.LABEL}
      </Title>
      <div className="grey">Please enter your login details below</div>
      <div className="grey mt05">
        We will send a verification code to your email
      </div>

      <CustomForm
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        formFields={[
          emailInput,
          passwordInput,
          forgotPasswordLink(
            userType === USER_TYPES.CLINIC_ADMIN
              ? ROUTES.CLINIC_ADMIN.FORGOT_PASSWORD.PATH
              : ROUTES.PROVIDER.FORGOT_PASSWORD.PATH
          ),
        ]}
        initialValues={{}}
        style={{
          width: "100%",
        }}
        loading={loading}
        text={BUTTONS.LOGIN}
        validations={{
          required: VALIDATIONS.DEFAULT.BLANK,
          types: {
            email: VALIDATIONS.DEFAULT.INVALID_EMAIL,
          },
        }}
      />

      <Flex justify={"space-between"} className="mt1">
        <Link
          to={
            userType === USER_TYPES.CLINIC_ADMIN
              ? ROUTES.CLINIC_ADMIN.LOGIN.PATHS.SEND_OTP
              : ROUTES.PROVIDER.LOGIN.PATHS.SEND_OTP
          }
        >
          Phone Login
        </Link>
      </Flex>
    </div>
  );
};
export default Login;
