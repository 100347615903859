import { Col, Row } from "antd";
import { patientDashboardImages } from "../../Common/Images";

const Banner = () => {
  return (
    <div className="blue-card">
      <Row justify={"space-between"}>
        <Col xs={24} sm={24} md={24} lg={18} xl={18} className="pd2">
          <div className="fs24 bold">Health Insights</div>
          <p>
            Your personalized health insights, combining real-time information
            with historical data, providing a holistic perspective to help you
            understand and manage your wellness
          </p>
        </Col>
        <Col xs={0} sm={0} md={0} lg={4} xl={3} style={{ lineHeight: 0 }}>
          <img src={patientDashboardImages.STATS} alt="header" width="100%" />
        </Col>
      </Row>
    </div>
  );
};

export default Banner;
