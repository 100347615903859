import { Button, Card, Col, Modal, Result, Row } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getApis, postApis } from "../../../Common/Apis";
import {
  BUTTONS,
  NOTIFICATIONS,
  ROUTES,
  SERVICES,
} from "../../../Common/Constants";
import {
  patientDeviceOrderUrl,
  patientOrderVialsQuestionnaireUrl,
  patientPaymentChargeUrl,
  patientVialsOrderUrl,
} from "../../../Common/Endpoints";
import Loader from "../../../Common/Loader";
import useNotify from "../../../Common/Notify";
import { getBaseUrl } from "../../../Common/Utils";
import Address from "./Address";
import DeviceForm from "./DeviceForm";
import History from "./History";
import Questionnaire from "./Questionnaire";
import Vials from "./Vials";
import VialsReason from "./VialsReason";

const Orders = ({
  deviceDetails,
  questionnaires,
  deviceImages,
  devices,
  showLoader,
  orderDetails,
}) => {
  const userDetails =
    useSelector((state) => state?.profile?.profileDetails) ||
    JSON.parse(localStorage.getItem("userDetails"));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reasonModalOpen, setReasonModalOpen] = useState(false);
  const [questionnaireModalOpen, setQuestionnaireModalOpen] = useState(false);
  const [vialsSubmitModalOpen, setVialsSubmitModalOpen] = useState(false);
  const [successData, setSuccessData] = useState();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [vials, setVials] = useState();
  const { notify } = useNotify();
  const { confirm } = Modal;
  const enrolledToSlit = userDetails?.dashboard?.filter(
    (item) => item?.service === SERVICES.SLIT
  );

  const getVialsOrder = async () => {
    setLoading(true);
    await getApis(patientVialsOrderUrl)
      .then((response) => {
        setLoading(false);
        if (response?.data?.type) {
          setSuccessData(response?.data);
          setVialsSubmitModalOpen(true);
        } else {
          setVials(response?.data);
          response?.data?.reason
            ? setReasonModalOpen(true)
            : response?.data?.status
            ? setQuestionnaireModalOpen(true)
            : showVialsConfirm(response?.data);
        }
      })
      .catch(() => setLoading(false));
  };

  const showVialsConfirm = (vials) => {
    confirm({
      title: "Order Vials",
      content: vials?.message,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      footer: null,
      closable: true,
    });
  };

  const handleOk = async () => {
    setLoading(true);
    await postApis(patientDeviceOrderUrl, formData)
      .then((response) => {
        setLoading(false);
        setIsModalOpen(false);
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleFormSubmit = (values) => {
    setFormData(values);
    setIsModalOpen(true);
  };

  const handleQuestionnaireCancel = () => {
    setQuestionnaireModalOpen(false);
  };

  const handleReasonCancel = () => {
    setReasonModalOpen(false);
  };

  const onFinish = async (values) => {
    setLoading(true);
    setSuccessData();
    await postApis(patientOrderVialsQuestionnaireUrl, values)
      .then((response) => {
        setLoading(false);
        setSuccessData(response?.data);
        setQuestionnaireModalOpen(false);
        setVialsSubmitModalOpen(true);
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const callPayment = async (data) => {
    const params = {
      clinic: data?.clinic,
      mode: "payment",
      type: data?.type,
      cancelUrl: `${getBaseUrl()}${ROUTES.PATIENT.DEVICES.PATH}`,
      successUrl: `${getBaseUrl()}${ROUTES.PATIENT.DEVICES.PATH}?`,
    };
    setLoading(true);
    await getApis(patientPaymentChargeUrl, params)
      .then((response) => {
        setLoading(false);
        window.location.assign(response?.data?.paymentUrl);
      })
      .catch(() => setLoading(false));
  };
  const onFinishFailed = () => {
    setLoading(false);
  };

  const onReasonFinish = async (values) => {
    setLoading(true);
    await postApis(patientVialsOrderUrl, values)
      .then((response) => {
        setLoading(false);
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const onReasonFinishFailed = () => {
    setLoading(false);
  };

  return (
    !showLoader && (
      <>
        <Row gutter={[16, 16]} className="mt1">
          {orderDetails?.devices &&
            orderDetails?.devices?.length > 0 &&
            orderDetails?.devices?.map((item, index) => {
              return (
                <Col key={index} xs={24} sm={24} md={24} lg={12} xl={12}>
                  <DeviceForm
                    deviceData={item}
                    deviceImages={deviceImages}
                    remoteConfigDevices={devices}
                    key={index}
                    devices={item?.list}
                    mandatory={item?.mandatory}
                    onSubmit={handleFormSubmit}
                    formId={index + 1}
                    notify={notify}
                    userDetails={userDetails}
                  />
                </Col>
              );
            })}
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            {enrolledToSlit?.length !== 0 && (
              <Vials getVialsOrder={getVialsOrder} loading={loading} />
            )}
          </Col>
        </Row>
        {!orderDetails && deviceDetails && (
          <Card className="mt1">
            <Result title="Explore our offerings and enroll in a program to order new monitoring devices." />
          </Card>
        )}

        {!showLoader && <History orderHistory={orderDetails?.orders} />}
        {isModalOpen && (
          <Address
            userDetails={userDetails}
            isModalOpen={isModalOpen}
            handleCancel={handleCancel}
            handleOk={handleOk}
            loading={loading}
          />
        )}
        {questionnaireModalOpen && (
          <Questionnaire
            asthma={orderDetails?.asthma}
            loading={loading}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            questionnaires={questionnaires}
            enrolledPrograms={userDetails?.dashboard}
            questionnaireModalOpen={questionnaireModalOpen}
            handleQuestionnaireCancel={handleQuestionnaireCancel}
          />
        )}
        {reasonModalOpen && (
          <VialsReason
            vials={vials}
            loading={loading}
            onFinish={onReasonFinish}
            onFinishFailed={onReasonFinishFailed}
            reasonModalOpen={reasonModalOpen}
            handleReasonCancel={handleReasonCancel}
          />
        )}
        {vialsSubmitModalOpen && (
          <Modal
            title={"Proceed to Pay"}
            open={vialsSubmitModalOpen}
            closeIcon={false}
            maskClosable={false}
            keyboard={false}
            footer={null}
            width={600}
          >
            <Row>
              <Col span={24}>
                <div
                  dangerouslySetInnerHTML={{ __html: successData?.message }}
                />
              </Col>
            </Row>

            <Row className="mt2 flex-center">
              <Col xs={24} sm={24} md={12} lg={6} xl={4}>
                <Button
                  type="primary"
                  size="large"
                  disabled={loading}
                  onClick={() => callPayment(successData)}
                >
                  <Loader loading={loading} text={BUTTONS.OK} />
                </Button>
              </Col>
            </Row>
          </Modal>
        )}
      </>
    )
  );
};

export default Orders;
