import { useEffect } from "react";
import * as echarts from "echarts";
import { displayOnlyTime, mapGraphTimeWithValue } from "../../../Common/Utils";

const BarChart = ({
  isMobile,
  vitalHeader,
  graphColor,
  vitalsData,
  filterType,
  vitalType,
}) => {
  const getData = () => {
    if (vitalType === "sleep") {
      if (filterType === "day") {
        return [
          ["Time", "Sleep Type", "Duration"],
          ...vitalsData.map((entry) => {
            const { duration, sleepType, startTimeStamp } = entry;
            if (!startTimeStamp || isNaN(new Date(startTimeStamp))) {
              return ["", sleepType, duration];
            }
            return [displayOnlyTime(startTimeStamp), duration, sleepType];
          }),
        ];
      } else {
        return [
          ["Date", ""],
          ...vitalsData.map((entry) => {
            const date = Object.keys(entry)[0];
            let value = entry[date];

            if (value === null) {
              value = 0;
            }
            return mapGraphTimeWithValue(date, value);
          }),
        ];
      }
    } else {
      if (filterType === "day") {
        return [
          ["Time", ""],
          ...vitalsData.map((entry) => {
            const timestamp = Object.keys(entry)[0];
            const value = entry[timestamp];
            if (!timestamp || isNaN(new Date(timestamp))) {
              return ["", value];
            }
            return [displayOnlyTime(timestamp), value];
          }),
        ];
      } else {
        return [
          ["Date", ""],
          ...vitalsData.map((entry) => {
            const date = Object.keys(entry)[0];
            let value = entry[date];

            if (value === null) {
              value = 0;
            }
            return mapGraphTimeWithValue(date, value);
          }),
        ];
      }
    }
  };

  const sleepTypeColorMapping = {
    244: { name: "Awake", color: "#0AAA4A" },
    243: { name: "REM Sleep", color: "#F26511" },
    242: { name: "Light Sleep", color: "#1FA7B2" },
    241: { name: "Deep Sleep", color: "#21575B" },
  };

  useEffect(() => {
    const chartDom = document.getElementById("BarChart");
    let existingChart = echarts.getInstanceByDom(chartDom);
    if (existingChart) {
      existingChart.dispose();
    }

    const myChart =
      process.env.NODE_ENV === "test"
        ? echarts.init(chartDom, null, {
            width: 600,
            height: 400,
          })
        : echarts.init(chartDom);

    const option = {
      title: {
        text: vitalHeader,
        left: "center",
      },
      tooltip: {},
      dataset: {
        source: getData(),
      },
      xAxis: {
        type: "category",
        axisLabel: { rotate: 45 },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          show: vitalType !== "sleep",
          formatter: (value) => {
            if (value >= 1000) {
              return value / 1000 + "k";
            }
            return value;
          },
        },
      },
      grid: {
        top: 50,
        bottom: 50,
        left: isMobile ? "12%" : "5%",
        right: "5%",
      },
      series: {
        type: "bar",
        encode: {
          x: filterType === "day" ? "Time" : "Date",
        },
        itemStyle: {
          color: (params) => {
            return sleepTypeColorMapping[params.data[2]]?.color || graphColor;
          },
        },
        data: getData()
          .slice(1)
          .map((entry) => [entry[0], entry[1], entry[2]]),
      },
    };

    myChart.setOption(option);

    return () => {
      myChart.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vitalType, filterType]);

  return <div id="BarChart" style={{ width: "100%", height: "280px" }}></div>;
};

export default BarChart;
