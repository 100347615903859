import { Button, Card, Col, Row } from "antd";
import Banner from "./Banner";
import Wellness from "./Wellness";
import Weather from "./Weather";
import General from "./General";
import PrePostProcedure from "./PrePostProcedure";
import Slit from "./Slit";
import Biologics from "./Biologics";
import { useSelector } from "react-redux";
import { ROUTES, SERVICES } from "../../Common/Constants";
import Health from "./Health";
import { getApis } from "../../Common/Apis";
import { patientDashboardUrl } from "../../Common/Endpoints";
import { useEffect, useState } from "react";
import { useApiLoader } from "../../Common/ApiLoaderContext";
import {
  containsService,
  getRemoteConfigData,
  isAndroidOrIosApp,
} from "../../Common/Utils";
import Bookings from "./Bookings";
import Audiology from "./Audiology";
import Sleep from "./Sleep";
import { useNavigate } from "react-router-dom";

const PatientDashboard = () => {
  const profileDetails = useSelector((state) => state.profile?.profileDetails);
  const enrolledPrograms = profileDetails?.dashboard;
  const [cardComponents, setCardComponents] = useState([]);
  const { showLoader, setShowLoader } = useApiLoader();
  const isMobileApp = isAndroidOrIosApp();
  const navigate = useNavigate();

  const getData = async (params) => {
    setShowLoader(true);
    try {
      const response = await getApis(patientDashboardUrl, params);
      setShowLoader(false);
      return response?.data;
    } catch {
      setShowLoader(false);
      return null;
    }
  };

  const renderCard = async (item, Component, span) => {
    try {
      const data = await getData(item);
      if (data) {
        return (
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={span ? span : 12}
            xl={span ? span : 12}
            key={`${item?.clinic}-${Component.name}`}
          >
            <Component
              clinic={item?.clinic}
              details={data}
              isMobileApp={isMobileApp}
            />
          </Col>
        );
      }
    } catch (error) {
      throw error;
    }
  };

  const renderDataCard = async (item, Component, span) => {
    try {
      const data = await getData(item);
      return (
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={span ? span : 12}
          xl={span ? span : 12}
          key={`${item?.clinic}-${Component.name}`}
        >
          <Component
            clinic={item?.clinic}
            details={data}
            isMobileApp={isMobileApp}
          />
        </Col>
      );
    } catch (error) {
      throw error;
    }
  };

  const fetchQuestionnaireData = async () => {
    const showQuestionnaire = containsService(enrolledPrograms, [
      SERVICES.SLIT,
      SERVICES.BIOLOGICS,
      SERVICES.TINNITUS,
    ]);

    if (showQuestionnaire) {
      try {
        const result = await getRemoteConfigData("QUESTIONNAIRES");
        return result;
      } catch (error) {
        return null;
      }
    }
  };

  const renderCardWithQuestionnaire = async (item, Component) => {
    try {
      const data = await getData(item);
      const staticQuestionnaires = await fetchQuestionnaireData();
      if (staticQuestionnaires) {
        return (
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            key={`${item?.clinic}-${Component.name}`}
          >
            <Component
              clinic={item?.clinic}
              staticQuestionnaires={staticQuestionnaires}
              details={data}
              isMobileApp={isMobileApp}
            />
          </Col>
        );
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    const fetchAndRenderCards = async () => {
      const components = await Promise.all(
        enrolledPrograms?.map(async (item) => {
          if (item?.service === SERVICES.WELLNESS) {
            return renderDataCard(item, Wellness);
          } else if (item?.service === SERVICES.PRE_POST_PROCEDURE) {
            return renderDataCard(item, PrePostProcedure);
          } else if (item?.service === SERVICES.AUDIOLOGY) {
            return renderCard(item, Audiology);
          } else if (item?.service === SERVICES.GENERAL) {
            return renderDataCard(item, General);
          } else if (item?.service === SERVICES.SLIT) {
            return renderCardWithQuestionnaire(item, Slit);
          } else if (item?.service === SERVICES.BIOLOGICS) {
            return renderCardWithQuestionnaire(item, Biologics);
          } else if (item?.service === SERVICES.HEALTH) {
            return renderCard(item, Health);
          } else if (item?.service === SERVICES.WEATHER) {
            return renderCard(item, Weather);
          } else if (item?.service === SERVICES.SLEEP) {
            return renderCard(item, Sleep);
          } else if (item?.service === SERVICES.BOOKINGS) {
            return renderDataCard(item, Bookings, 24);
          }
        })
      );
      const validComponents = components?.flat().filter(Boolean);
      setCardComponents(validComponents);
    };

    fetchAndRenderCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enrolledPrograms]);

  return (
    <div data-testid="patient-dashboard">
      {!showLoader && (
        <>
          <Banner />
          <Row gutter={[32, 32]} justify="space-between" className="mt2">
            {cardComponents}
          </Row>
          {enrolledPrograms?.filter(
            (item) => ![SERVICES.SLEEP, SERVICES.HEALTH].includes(item?.service)
          )?.length === 0 && (
            <Card className="text-center grey mt2">
              You havent joined any of the service or program, click on the
              Explore button to see the options
              <Row className="flex-center mt2">
                <Col xs={24} sm={12} md={12} lg={8} xl={4}>
                  <Button
                    block
                    type="primary"
                    onClick={() => navigate(ROUTES.PATIENT.PROGRAMS.PATH)}
                  >
                    Explore
                  </Button>
                </Col>
              </Row>
            </Card>
          )}
        </>
      )}
    </div>
  );
};

export default PatientDashboard;
