import { Button, Col, Divider, Form, Input, Row, Select } from "antd";
import { MaskedInput } from "antd-mask-input";
import {
  BUTTONS,
  FORM_FIELDS,
  PLACEHOLDER,
  VALIDATIONS,
} from "../../../Common/Constants";
import Loader from "../../../Common/Loader";
import Popup from "../../../Common/Popup";
import { validatePhoneLength } from "../../../Common/Utils";
const { Option } = Select;

const AddLocation = ({
  form,
  isAddModalOpen,
  handleAddModelCancel,
  loading,
  onFinish,
  onFinishFailed,
  staticDropdownData,
}) => {
  const prefixSelector = (
    <Form.Item name="code" noStyle>
      <Select style={{ width: 70 }} name="code">
        <Option value="+1">+1</Option>
        <Option value="+91">+91</Option>
      </Select>
    </Form.Item>
  );

  return (
    <Popup
      title="Add Location"
      open={isAddModalOpen}
      cancel={handleAddModelCancel}
      footer={null}
      width={700}
      style={{ top: "25px" }}
    >
      <Form
        initialValues={{ code: "+1", role: "Staff" }}
        form={form}
        layout="vertical"
        size="large"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        validateMessages={{
          required: VALIDATIONS.DEFAULT.BLANK,
          types: {
            email: VALIDATIONS.DEFAULT.INVALID_EMAIL,
          },
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={FORM_FIELDS.CLINIC_NAME}
              name="clinicName"
              size="large"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder={FORM_FIELDS.CLINIC_NAME} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={FORM_FIELDS.DEVICES}
              name="totalDevices"
              size="large"
              rules={[
                {
                  required: true,
                },
                {
                  pattern: /^[0-9]+$/,
                  message: VALIDATIONS.PATTERN.NUMERIC,
                },
              ]}
            >
              <Input placeholder={FORM_FIELDS.DEVICES} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item
              label={FORM_FIELDS.ADDRESS}
              name="address"
              size="large"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder={FORM_FIELDS.ADDRESS} />
            </Form.Item>
          </Col>
        </Row>

        <div className="bold mt1">Staff Details</div>
        <Divider style={{ margin: "0.5rem 0 2rem 0" }} />

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={FORM_FIELDS.FIRST_NAME}
              name="firstName"
              size="large"
              rules={[
                {
                  required: true,
                },
                {
                  pattern: /^[A-Za-z\s]+$/,
                  message: VALIDATIONS.PATTERN.STRING,
                },
              ]}
            >
              <Input placeholder={FORM_FIELDS.FIRST_NAME} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={FORM_FIELDS.LAST_NAME}
              name="lastName"
              size="large"
              rules={[
                {
                  required: true,
                },
                {
                  pattern: /^[A-Za-z\s]+$/,
                  message: VALIDATIONS.PATTERN.STRING,
                },
              ]}
            >
              <Input placeholder={FORM_FIELDS.LAST_NAME} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={FORM_FIELDS.PHONE}
              name="phoneNumber"
              size="large"
              rules={[
                {
                  required: true,
                  validator: validatePhoneLength,
                },
              ]}
            >
              <MaskedInput
                addonBefore={prefixSelector}
                mask={"(000)000-0000"}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={FORM_FIELDS.EMAIL}
              name="email"
              size="large"
              rules={[
                {
                  required: true,
                },
                {
                  type: "email",
                },
              ]}
            >
              <Input placeholder={FORM_FIELDS.EMAIL_ADDRESS} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={FORM_FIELDS.GENDER}
              name="gender"
              size="large"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                name="gender"
                placeholder="Please Select"
                options={staticDropdownData?.common?.genders}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={FORM_FIELDS.ROLE}
              name="role"
              size="large"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                disabled={true}
                readOnly={true}
                placeholder={PLACEHOLDER.ROLE}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="flex-center mt1">
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Button
              type="primary"
              className="mt05"
              htmlType="submit"
              block
              disabled={loading}
            >
              <Loader loading={loading} text={BUTTONS.SUBMIT} />
            </Button>
          </Col>
        </Row>
      </Form>
    </Popup>
  );
};

export default AddLocation;
