import { Button, Card, Result } from "antd";
import { ROUTES } from "./Common/Constants";

const WentWrong = () => {
  return (
    <Card>
      <Result
        status="500"
        title="Sorry, something went wrong."
        extra={
          <Button
            type="primary"
            onClick={() => window.location.assign(ROUTES.PATIENT.BOOKINGS.PATH)}
          >
            Try Again
          </Button>
        }
      />
    </Card>
  );
};

export default WentWrong;
