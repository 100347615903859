import { Card, Col, Divider, Empty, Flex, Form, Row, Spin, Tag } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getApis, putApis } from "../../Common/Apis";
import { BUTTONS, NOTIFICATIONS, VALIDATIONS } from "../../Common/Constants";
import MonthlyCalendar from "../../Common/MonthlyCalendar";
import Popup from "../../Common/Popup";
import { centalisedSubmitBtn, disabledBookingsDate } from "../../Common/Utils";
import { setTimeSlots } from "../../../Reducers/bookingsSlice";
import useNotify from "../../Common/Notify";
import moment from "moment";
import {
  clinicAdminWebinarBookingsUrl,
  clinicAdminWebinarSlotsUrl,
} from "../../Common/Endpoints";

const Reschedule = ({ form, modalOpen, cancelModal, selectedRow }) => {
  const bookings = useSelector((state) => state?.bookings);
  const timeSlots = bookings?.timeSlots;
  const [showLoader, setShowLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [amTimes, setAmTimes] = useState([]);
  const [pmTimes, setPmTimes] = useState([]);
  const [newSlot, setNewSlot] = useState();
  const dispatch = useDispatch();
  const { notify } = useNotify();
  const currentDate = moment().add(1, "days");

  useEffect(() => {
    const am = timeSlots?.filter((time) => time.includes("AM"));
    const pm = timeSlots?.filter((time) => time.includes("PM"));

    setAmTimes(am);
    setPmTimes(pm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeSlots]);

  useEffect(() => {
    dispatch(setTimeSlots([]));
    form.setFieldsValue({
      rescheduleBookingDate: currentDate.format("YYYY-MM-DD"),
    });
    getAvailableSlots({ bookingDate: currentDate.format("YYYY-MM-DD") });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow]);

  const onFinish = async (values) => {
    setLoading(true);

    const params = {
      ...values,
      bookingID: selectedRow?.bookingID,
      action: "reschedule",
    };
    await putApis(clinicAdminWebinarBookingsUrl, params)
      .then((response) => {
        setLoading(false);
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };
  const onFinishFailed = () => {
    setLoading(false);
  };

  const getAvailableSlots = async (params) => {
    setShowLoader(true);
    await getApis(clinicAdminWebinarSlotsUrl, params)
      .then((response) => {
        dispatch(setTimeSlots(response?.data));
        setNewSlot();
        form.setFieldsValue({
          slot: null,
        });
        setShowLoader(false);
      })
      .catch(() => {
        dispatch(setTimeSlots([]));
        setShowLoader(false);
      });
  };

  const handleDateSelect = async (date) => {
    form.setFieldsValue({
      rescheduleBookingDate: date.format("YYYY-MM-DD"),
    });

    getAvailableSlots({ bookingDate: date.format("YYYY-MM-DD") });
  };

  const handleTagClick = (value) => {
    setNewSlot(value);
    form.setFieldsValue({
      slot: value,
    });
  };

  const onValuesChange = (changedValues, allValues) => {
    const params = {
      bookingDate:
        allValues?.rescheduleBookingDate || selectedRow?.rescheduleBookingDate,
    };
    getAvailableSlots(params);
  };

  return (
    <Popup
      title={"Reschedule"}
      open={modalOpen}
      cancel={cancelModal}
      footer={null}
      width={850}
      style={{ top: "8%" }}
    >
      <Row justify={"center"}>
        <Col span={24} className="flex-center">
          <Form
            form={form}
            initialValues={{}}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onValuesChange={onValuesChange}
            autoComplete="off"
            layout="vertical"
            validateMessages={{
              required: VALIDATIONS.DEFAULT.BLANK,
            }}
            style={{ width: "100%" }}
          >
            {selectedRow && (
              <div className="grey-fill pd1">
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Flex wrap="wrap" gap="small">
                      <div>Slot: </div>
                      <div className="bold">{selectedRow?.slot}</div>
                    </Flex>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Flex wrap="wrap" gap="small">
                      <div>Scheduled For: </div>
                      <div className="bold">{selectedRow?.bookingDate}</div>
                    </Flex>
                  </Col>
                </Row>
              </div>
            )}

            <div className="bold blue text-center fs18 mt2">Select Again</div>
            <Divider style={{ margin: "0.5rem 0 2rem 0" }} />

            <Row gutter={[16, 16]} className="mt1">
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item
                  label="Booking Date"
                  name="rescheduleBookingDate"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Card>
                    <MonthlyCalendar
                      handleDateSelect={handleDateSelect}
                      disabledDate={disabledBookingsDate}
                    />
                  </Card>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item
                  label="Slot"
                  name="slot"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Card
                    styles={{
                      body: {
                        minHeight: "415px",
                        height: "415px",
                        overflow: "auto",
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "center",
                      },
                    }}
                  >
                    <div className="flex-center">
                      {showLoader ? (
                        <Spin size="large" />
                      ) : timeSlots?.length > 0 ? (
                        <div>
                          <div className="bold blue">Morning</div>
                          <Divider style={{ margin: "0.5rem 0" }} />
                          {amTimes?.map((item, index) => {
                            return (
                              <Tag
                                key={index}
                                className={`mt1 ml05 cursor-pointer ${
                                  newSlot === item ? "selected-tag" : ""
                                }`}
                                onClick={() => handleTagClick(item)}
                              >
                                {item}
                              </Tag>
                            );
                          })}
                          <div className="bold blue mt2">Afternoon</div>
                          <Divider style={{ margin: "0.5rem 0" }} />
                          {pmTimes?.map((item, index) => {
                            return (
                              <Tag
                                key={index}
                                className={`mt1 ml05 cursor-pointer ${
                                  newSlot === item ? "selected-tag" : ""
                                }`}
                                onClick={() => handleTagClick(item)}
                              >
                                {item}
                              </Tag>
                            );
                          })}
                        </div>
                      ) : (
                        <Empty />
                      )}
                    </div>
                  </Card>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              {centalisedSubmitBtn(loading, BUTTONS.SUBMIT, null, 6)}
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Popup>
  );
};

export default Reschedule;
