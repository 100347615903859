import { useEffect } from "react";
import * as echarts from "echarts";
import { displayOnlyHours, getGraphTime } from "../../../Common/Utils";

const BarChart = ({ color, chartDivId, frequency, healthDetails }) => {
  const filteredData = () => {
    const list = healthDetails?.list || [];

    if (list.length === 0) {
      return [["No Data", 0]];
    }

    if (frequency === "day") {
      return [
        ["Time Range", "Value"],
        ...(list?.map((entry) => {
          const { startTime, endTime, value } = entry;

          if (!startTime || !endTime || value === undefined || value === null) {
            return ["Invalid Time Range", 0];
          }

          const formattedTimeRange = `${displayOnlyHours(
            startTime
          )} - ${displayOnlyHours(endTime)}`;
          return [formattedTimeRange, value];
        }) ?? []),
      ];
    } else {
      return [
        ["Date", "Value"],
        ...(list?.map((entry) => {
          const date = Object.keys(entry)[0];
          let value = entry[date];

          if (value === null || value === undefined) {
            value = 0;
          }
          return [getGraphTime(date), value];
        }) ?? []),
      ];
    }
  };

  useEffect(() => {
    const chartDom = document.getElementById(chartDivId);
    if (!chartDom) return;

    let existingChart = echarts.getInstanceByDom(chartDom);
    if (existingChart) {
      existingChart.dispose();
    }

    const myChart = echarts.init(chartDom);

    const option = {
      tooltip: {
        trigger: "axis",
        formatter: (params) => {
          const data = params[0];
          return `${data.name}<br/><b>${data.value[1]}</b>`;
        },
      },
      xAxis: {
        type: "category",
        axisLabel: {
          show: true,
          fontSize: 11,
          rotate: 45,
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          show: true,
          fontSize: 11,
        },
      },
      dataset: {
        source: filteredData(),
      },
      grid: {
        left: 45,
        right: 15,
        top: 10,
        bottom: 60,
      },
      series: [
        {
          type: "bar",
          barWidth: 10,
          itemStyle: {
            color: color,
          },
        },
      ],
    };

    myChart.setOption(option);

    return () => {
      if (myChart) {
        myChart.dispose();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartDivId, frequency, healthDetails]);

  return (
    <div
      className="mt1"
      id={chartDivId}
      style={{ width: "100%", height: "222px" }}
    ></div>
  );
};

export default BarChart;
