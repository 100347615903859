export const patientDashboardImages = {
  STATS: require("../../Assets/images/Dashboard/stats.png"),
  ORANGE_VIEWMORE: require("../../Assets/images/Dashboard/orangeViewMore.svg")
    .default,
  GREEN_VIEWMORE: require("../../Assets/images/Dashboard/greenViewMore.svg")
    .default,
  YELLOW_VIEWMORE: require("../../Assets/images/Dashboard/yellowViewMore.svg")
    .default,
  BLUE_VIEWMORE: require("../../Assets/images/Dashboard/blueViewMore.svg")
    .default,
  WELLNESS: require("../../Assets/images/Dashboard/wellness.png"),
  WEATHER: require("../../Assets/images/Dashboard/weather.png"),
  TINNITUS: require("../../Assets/images/Dashboard/tinnitus.png"),
  SURGERY: require("../../Assets/images/Dashboard/surgery.png"),
  GENERAL: require("../../Assets/images/Dashboard/general.png"),
  TINNITUS_BANNER: require("../../Assets/images/Dashboard/tinnitusBanner.png"),
  GENERAL_BANNER: require("../../Assets/images/Dashboard/generalBanner.png"),
  TEMP: require("../../Assets/images/Dashboard/temp.png"),
  BP: require("../../Assets/images/Dashboard/bp.png"),
  SPO2: require("../../Assets/images/Dashboard/spo2.png"),
  GENERAL_TEMP: require("../../Assets/images/Dashboard/generalTemp.png"),
  GENERAL_BP: require("../../Assets/images/Dashboard/generalBp.png"),
  GENERAL_SPO2: require("../../Assets/images/Dashboard/generalSpo2.png"),
  SLIT: require("../../Assets/images/Dashboard/slit.png"),
  SLIT_FEV: require("../../Assets/images/Dashboard/slitFev.png"),
  SLIT_BEST_PEF: require("../../Assets/images/Dashboard/slitBestPef.png"),
  BIOLOGICS: require("../../Assets/images/Dashboard/biologics.png"),
  HEALTH_BANNER: require("../../Assets/images/Dashboard/healthBanner.png"),
  BIO_FEV: require("../../Assets/images/Dashboard/bioFev.png"),
  BIO_BEST_PEF: require("../../Assets/images/Dashboard/bioBestPef.png"),
  RESPIRATORY: require("../../Assets/images/Dashboard/respiratory.png"),
  SLEEP_BANNER: require("../../Assets/images/Dashboard/sleepBanner.png"),
  WELLNESS_BANNER: require("../../Assets/images/Dashboard/wellnessBanner.png"),
  WEATHER_BANNER: require("../../Assets/images/Dashboard/weatherBanner.png"),
  COGNITIVE_WELLNESS: require("../../Assets/images/Dashboard/cogWell.png"),
  PHYSICAL_WELLNESS: require("../../Assets/images/Dashboard/physWell.png"),
  COGNITIVE_TINNITUS: require("../../Assets/images/Dashboard/cogTin.png"),
  PHYSICAL_TINNITUS: require("../../Assets/images/Dashboard/physTin.png"),
  POLLEN: require("../../Assets/images/Dashboard/pollen.png"),
  AQI: require("../../Assets/images/Dashboard/aqi.png"),
  DEEP_SLEEP: require("../../Assets/images/Dashboard/deepSleep.png"),
  LIGHT_SLEEP: require("../../Assets/images/Dashboard/lightSleep.png"),
  REM_SLEEP: require("../../Assets/images/Dashboard/remSleep.png"),
  SLEEP: require("../../Assets/images/Dashboard/sleep.png"),
  HEALTH: require("../../Assets/images/Dashboard/health.png"),
  STEPS: require("../../Assets/images/Dashboard/steps.png"),
  CALORIES: require("../../Assets/images/Dashboard/calories.png"),
  VIEWMORE: require("../../Assets/images/Dashboard/viewMore.png"),
  BOOKINGS: require("../../Assets/images/Dashboard/bookings.png"),
};

export const patientSupportImages = {
  HEADER: require("../../Assets/images/Support/support.png"),
  BANNER: require("../../Assets/images/Support/banner.png"),
  TECH: require("../../Assets/images/Support/technical.png"),
  CLINIC: require("../../Assets/images/Support/clinic.png"),
  SHOWMORE: require("../../Assets/images/Dashboard/showMore.png"),
  TROUBLESHOOT: require("../../Assets/images/Support/troubleshoot.png"),
};

export const patientDevicesImages = {
  BANNER: require("../../Assets/images/Devices/banner.png"),
};

export const patientHealthImages = {
  BANNER: require("../../Assets/images/Ring/health.png"),
  ACTIVE_ENERGY: require("../../Assets/images/Ring/active.png"),
  BASAL_ENERGY: require("../../Assets/images/Ring/basal.png"),
  STEP: require("../../Assets/images/Ring/step.png"),
  CLIMB: require("../../Assets/images/Ring/climb.png"),
  DISTANCE: require("../../Assets/images/Ring/km.png"),
  AWAKE: require("../../Assets/images/Ring/awake.png"),
  DEEP: require("../../Assets/images/Ring/deep.png"),
  LIGHT: require("../../Assets/images/Ring/light.png"),
  REM: require("../../Assets/images/Ring/rem.png"),
  RESPIRATORY: require("../../Assets/images/Ring/resp.png"),
  HEART_RATE: require("../../Assets/images/Ring/heartRate.png"),
  WORKOUT: require("../../Assets/images/Ring/workout.png"),
  BLOOD_OXYGEN: require("../../Assets/images/Ring/bloodOxygen.png"),
};

export const providerViewPatient = {
  RISK: require("../../Assets/images/Audiology/risk.png"),
  COGNITIVE: require("../../Assets/images/Audiology/cognitive.png"),
  BURNOUT: require("../../Assets/images/Audiology/burnout.png"),
  ANXIETY: require("../../Assets/images/Audiology/anxiety.png"),
  DEPRESSION: require("../../Assets/images/Audiology/depression.png"),
  TINNITUS: require("../../Assets/images/Audiology/tinnitus.png"),
  INFERENCE: require("../../Assets/images/Audiology/inference.png"),
  PROGNOSIS: require("../../Assets/images/Audiology/prognosis.png"),
};
