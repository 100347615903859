import { CalendarOutlined } from "@ant-design/icons";
import { Button, Card, Col, Empty, Row, Splitter } from "antd";
import { patientDashboardImages } from "../../Common/Images";
import { postMessageToMobileApp } from "../../Common/Utils";

const Audiology = ({ details, isMobileApp }) => {
  const renderRecordButton = () => {
    return (
      <Button
        block
        size="small"
        className="yellow-btn"
        onClick={() => postMessageToMobileApp({ record: true }, "voiceRecord")}
      >
        Record Your Speech
      </Button>
    );
  };

  return (
    <Card
      id="Audiology"
      title={
        <Row gutter={[16, 16]} justify={"space-between"} className="mt05">
          <Col xs={4} sm={3} md={3} lg={3} xl={3}>
            <img
              src={patientDashboardImages.TINNITUS}
              alt="wellness"
              width="100%"
            />
          </Col>
          <Col
            xs={17}
            sm={19}
            md={19}
            lg={19}
            xl={19}
            className="bold fs18 flex-start-center"
          >
            Audiology
          </Col>
          <Col xs={3} sm={2} md={2} lg={2} xl={2} className="flex-center"></Col>
        </Row>
      }
      className="bdr8 box-more-shadow"
      styles={{ body: { padding: "0" }, color: "#141414" }}
    >
      {details?.statistics ? (
        <Splitter>
          <Splitter.Panel collapsible={false} resizable={false}>
            <div className="pd-tlr1-b0">
              <div className="grey fs12">Total</div>
              <div className="fs22 bold">{details?.statistics?.total}</div>
            </div>

            <div className="text-center">
              <img
                src={patientDashboardImages.TINNITUS_BANNER}
                alt="wave"
                width="70%"
              />
            </div>
            <Row gutter={[16, 8]} justify={"end"} className="pd05">
              <Col
                xs={16}
                sm={10}
                md={10}
                lg={10}
                xl={10}
                className="grey fs12 text-right"
              >
                <CalendarOutlined className="mr05" />
                {details?.statistics?.date}
              </Col>
            </Row>
          </Splitter.Panel>
          <Splitter.Panel>
            <Splitter layout="vertical">
              <Splitter.Panel collapsible={false} resizable={false}>
                <div className="pdlr1">
                  <Row gutter={[16, 16]} className="flex-center">
                    <Col xs={8} sm={6} md={6} lg={6} xl={6}>
                      <img
                        src={patientDashboardImages.COGNITIVE_TINNITUS}
                        alt="fev"
                        width="100%"
                      />
                    </Col>
                    <Col xs={16} sm={18} md={18} lg={18} xl={18}>
                      <div className="grey fs12">Cognitive</div>
                      <div className="bold fs22">
                        {details?.statistics?.cognitive}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Splitter.Panel>
              <Splitter.Panel collapsible={false} resizable={false}>
                <div className="pdlr1">
                  <Row gutter={[16, 16]} className="flex-center">
                    <Col xs={8} sm={6} md={6} lg={6} xl={6}>
                      <img
                        src={patientDashboardImages.PHYSICAL_TINNITUS}
                        alt="fev"
                        width="100%"
                      />
                    </Col>
                    <Col xs={16} sm={18} md={18} lg={18} xl={18}>
                      <div className="grey fs12">Physical</div>
                      <div className="bold fs22">
                        {details?.statistics?.physical}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Splitter.Panel>

              {isMobileApp && (
                <Splitter.Panel collapsible={false} resizable={false}>
                  <div className="pdlr1">{renderRecordButton()}</div>
                </Splitter.Panel>
              )}
            </Splitter>
          </Splitter.Panel>
        </Splitter>
      ) : (
        <Empty
          imageStyle={{
            height: 60,
          }}
          className="pd1 fs13"
          description={"No data available"}
        >
          {isMobileApp && (
            <Row gutter={[16, 16]} justify="center">
              <Col xs={24} sm={8} md={8} lg={12} xl={12}>
                {renderRecordButton()}
              </Col>
            </Row>
          )}
        </Empty>
      )}
    </Card>
  );
};

export default Audiology;
