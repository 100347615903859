import { Divider, Table, Tag } from "antd";
import { displayOnlyDate, getPageHeader } from "../../../Common/Utils";

const History = ({ orderHistory }) => {
  const columns = [
    {
      title: "Order Type",
      dataIndex: "devices",
      render: (_, record) =>
        record?.devices ? (
          <>
            {record?.devices?.map((item, index) => {
              return <Tag key={index}>{item}</Tag>;
            })}
          </>
        ) : (
          ""
        ),
    },
    {
      title: "Ordered On",
      dataIndex: "created",
      render: (_, record) => displayOnlyDate(record?.created),
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Payment",
      dataIndex: "paymentStatus",
      render: (_, record) => {
        return record?.paymentStatus
          ? getPageHeader(record?.paymentStatus)
          : "NA";
      },
    },
  ];

  return (
    orderHistory?.length > 0 && (
      <>
        <div className="bold mt2 fs20">Order History</div>
        <Divider style={{ margin: "0.5rem 0 0 0" }} />
        <Table
          bordered
          className="mt1"
          rowKey={(record) => record?.created || record?.orderedTime}
          columns={columns}
          dataSource={orderHistory}
          scroll={{ x: "100%" }}
          pagination={false}
        />
      </>
    )
  );
};

export default History;
