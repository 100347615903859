import { Col, Row, Tag } from "antd";
import { FORM_FIELDS } from "../../Common/Constants";

const ClinicDetails = ({ profileDetails, staticDropdownData }) => {
  return (
    <>
      <Row gutter={[16, 32]} justify="center">
        <Col xs={24} sm={24} md={24} lg={10} xl={10} className="text-center">
          <div className="bold mb1">Your Unique Identifier</div>

          <img
            src={`data:image/png;base64,${profileDetails?.qr}`}
            alt="Base64"
            width="130"
            className="box-shadow"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={14} xl={14}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <div className="bold">{FORM_FIELDS.CLINICS}: </div>
              {profileDetails?.clinics ? (
                <div>
                  {profileDetails?.clinics
                    ?.map(
                      (item) =>
                        staticDropdownData?.common?.clinics?.find(
                          (dd) => dd?.value === item
                        )?.label
                    )
                    ?.map((item, index) => {
                      return (
                        <Tag key={index} className="mt05">
                          {item}
                        </Tag>
                      );
                    })}
                </div>
              ) : (
                "Not Available"
              )}
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="mt2">
            <Col span={24}>
              <div className="bold">Providers: </div>
              {profileDetails?.providerName?.length > 0 ? (
                <div>
                  {profileDetails?.providerName?.map((item, index) => {
                    return (
                      <Tag key={index} className="mt05">
                        {item}
                      </Tag>
                    );
                  })}
                </div>
              ) : (
                "Not Available"
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ClinicDetails;
