import React from "react";
import { Button, Collapse } from "antd";
import {
  EditFilled,
  CloseCircleFilled,
  CaretRightOutlined,
} from "@ant-design/icons";

const CollapseSection = ({
  sectionKey,
  title,
  showForm,
  setShowForm,
  children,
  form,
  initialValues,
}) => {
  return (
    <Collapse
      className="mt1"
      collapsible
      defaultActiveKey={[sectionKey]}
      items={[
        {
          key: sectionKey,
          label: (
            <div>
              <span className="left">{title}</span>{" "}
              {!["1", "7"].includes(sectionKey) && (
                <>
                  {showForm ? (
                    <CloseCircleFilled
                      id={`${sectionKey}-close`}
                      className="right icon24"
                      onClick={(e) => {
                        e.stopPropagation();
                        form.setFieldsValue(initialValues);
                        setShowForm(false);
                      }}
                    />
                  ) : (
                    <Button
                      className="right"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowForm(true);
                      }}
                      icon={<EditFilled />}
                    >
                      Edit
                    </Button>
                  )}
                </>
              )}
            </div>
          ),
          children: children,
        },
      ]}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
    />
  );
};

export default CollapseSection;
