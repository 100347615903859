import { CalendarOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { Button, Card, Col, Empty, Flex, Row, Splitter } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../Common/Constants";
import { patientDashboardImages } from "../../Common/Images";

const PrePostProcedure = ({ clinic, details }) => {
  const navigate = useNavigate();

  return (
    <Card
      id="PrePostProcedure"
      title={
        <Row gutter={[16, 16]} justify={"space-between"} className="mt05">
          <Col xs={4} sm={3} md={3} lg={3} xl={3}>
            <img
              src={patientDashboardImages.SURGERY}
              alt="general"
              width="100%"
            />
          </Col>
          <Col
            xs={17}
            sm={19}
            md={19}
            lg={19}
            xl={19}
            className="bold fs18 flex-start-center"
          >
            Pre Post Procedure
          </Col>
          <Col xs={3} sm={2} md={2} lg={2} xl={2} className="flex-center">
            <Link
              to={ROUTES.PATIENT.PRE_POST_PROCEDURE.PATH.replace(
                ":clinic",
                clinic
              )}
            >
              <img
                src={patientDashboardImages.BLUE_VIEWMORE}
                alt="surgery"
                width="100%"
              />
            </Link>
          </Col>
        </Row>
      }
      className="bdr8 box-more-shadow"
      styles={{ body: { padding: "0" }, color: "#141414" }}
    >
      {details ? (
        <Splitter>
          <Splitter.Panel collapsible={false} resizable={false}>
            <Row gutter={[8, 8]} justify={"start"} className="pd1">
              <Col span={10}>
                <div className="grey fs12">Heart Rate</div>
                <Flex wrap="wrap" gap="small" className="flex-baseline">
                  <div className="fs22 bold">{details?.hr}</div>
                  <div className="grey fs12">bpm</div>
                </Flex>
              </Col>
              <Col span={14}>
                <Button
                  size="small"
                  title="View Timelines"
                  block
                  className="blue-btn"
                  onClick={() => navigate(ROUTES.PATIENT.TIMELINES.PATH)}
                >
                  Timelines
                </Button>
              </Col>
            </Row>

            <div className="mb05 mt05 text-center">
              <img
                src={patientDashboardImages.HEALTH_BANNER}
                alt="health"
                width="64%"
              />
            </div>
            <Row gutter={[16, 8]} justify={"end"} className="pd05">
              <Col
                xs={24}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="grey fs12 text-right"
              >
                <CalendarOutlined className="mr05" />
                {details?.date}
              </Col>
              <Col
                xs={24}
                sm={10}
                md={10}
                lg={10}
                xl={10}
                className="grey fs12 text-right"
              >
                <ClockCircleOutlined className="mr05" />
                {details?.time}
              </Col>
            </Row>
          </Splitter.Panel>
          <Splitter.Panel>
            <Splitter layout="vertical">
              <Splitter.Panel collapsible={false} resizable={false}>
                <div className="pdlr1">
                  <Row gutter={[16, 16]} className="flex-center">
                    <Col xs={8} sm={6} md={6} lg={6} xl={6}>
                      <img
                        src={patientDashboardImages.TEMP}
                        alt="temperature"
                        width="100%"
                      />
                    </Col>
                    <Col xs={16} sm={18} md={18} lg={18} xl={18}>
                      <div className="grey fs12">Temperature</div>
                      <Flex wrap="wrap" gap="small" className="flex-baseline">
                        <div className="fs22 bold">{`${details?.temperature}°`}</div>
                        <div className="grey">F</div>
                      </Flex>
                    </Col>
                  </Row>
                </div>
              </Splitter.Panel>
              <Splitter.Panel collapsible={false} resizable={false}>
                <div className="pdlr1">
                  <Row gutter={[16, 16]} className="flex-center">
                    <Col xs={8} sm={6} md={6} lg={6} xl={6}>
                      <img
                        src={patientDashboardImages.SPO2}
                        alt="SPO2"
                        width="100%"
                      />
                    </Col>
                    <Col xs={16} sm={18} md={18} lg={18} xl={18}>
                      <div className="grey fs12">SpO2</div>
                      <Flex wrap="wrap" gap="small" className="flex-baseline">
                        <div className="fs22 bold">{details?.spo2}</div>
                        <div className="grey fs12">%</div>
                      </Flex>
                    </Col>
                  </Row>
                </div>
              </Splitter.Panel>
              <Splitter.Panel collapsible={false} resizable={false}>
                <div className="pdlr1">
                  <Row gutter={[16, 16]} className="flex-center">
                    <Col xs={8} sm={6} md={6} lg={6} xl={6}>
                      <img
                        src={patientDashboardImages.BP}
                        alt="bp"
                        width="100%"
                      />
                    </Col>
                    <Col xs={16} sm={18} md={18} lg={18} xl={18}>
                      <div className="grey fs12">BP</div>
                      <Flex wrap="wrap" gap="small" className="flex-baseline">
                        <div className="fs22 bold">{details?.bp}</div>
                        <div className="grey fs12">mm/Hg</div>
                      </Flex>
                    </Col>
                  </Row>
                </div>
              </Splitter.Panel>
            </Splitter>
          </Splitter.Panel>
        </Splitter>
      ) : (
        <Empty
          imageStyle={{
            height: 60,
          }}
          className="pd1 fs13"
          description={"Order your devices"}
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Button
                block
                type="primary"
                size="small"
                onClick={() => navigate(ROUTES.PATIENT.DEVICES.PATH)}
              >
                Order Now
              </Button>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Button
                block
                size="small"
                type="primary"
                className="blue-btn"
                onClick={() => navigate(ROUTES.PATIENT.TIMELINES.PATH)}
              >
                View Timelines
              </Button>
            </Col>
          </Row>
        </Empty>
      )}
    </Card>
  );
};

export default PrePostProcedure;
