import Popup from "../../Common/Popup";

const CreateQR = ({ profileDetails, isModalOpen, handleCancel }) => {
  return (
    <Popup
      title={"Get your GT +"}
      open={isModalOpen}
      cancel={handleCancel}
      footer={null}
      width={600}
    >
      <div className="text-center">
        Show this QR at the clinic to get your GT plus done
      </div>

      <div className="flex-center mt1">
        <img
          src={`data:image/png;base64,${profileDetails?.qr}`}
          alt="Base64"
          width="160"
          className="box-shadow"
        />
      </div>
    </Popup>
  );
};

export default CreateQR;
