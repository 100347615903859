import { Card, Col, Row } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  patientDashboardImages,
  patientSupportImages,
} from "../../Common/Images";
import { getPageHeader, getRemoteConfigData } from "../../Common/Utils";
import Clinic from "./Clinic";
import Technical from "./Technical";
import Troubleshoot from "./Troubleshoot";

const PatientSupport = () => {
  const [activeCard, setActiveCard] = useState("troubleshoot");
  const [staticContent, setStaticContent] = useState();

  useEffect(() => {
    getRemoteConfigData("SUPPORT").then((result) => {
      setStaticContent(result);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCard = (image, cardName) => {
    return (
      <Link onClick={() => setActiveCard(cardName)}>
        <Card
          className={`${
            activeCard === cardName ? "selected-card" : "hover-text"
          } box-shadow`}
          style={{ border: "0" }}
          styles={{ body: { padding: "1rem" } }}
        >
          <Row gutter={[16, 16]} className="flex-center">
            <Col span={6} style={{ lineHeight: 0 }}>
              <img src={image} alt={cardName} width="100%" />
            </Col>
            <Col span={14} className="bold fs20" style={{ lineHeight: 0 }}>
              {getPageHeader(cardName)}
            </Col>
            <Col span={3} style={{ lineHeight: 0 }}>
              <img
                src={
                  activeCard === cardName
                    ? patientSupportImages.SHOWMORE
                    : patientDashboardImages.ORANGE_VIEWMORE
                }
                alt="more"
                width="100%"
              />
            </Col>
          </Row>
        </Card>
      </Link>
    );
  };

  return (
    <div>
      <div className="blue-card">
        <Row justify={"space-between"}>
          <Col xs={24} sm={24} md={24} lg={16} xl={16} className="pd2">
            <div className="fs24 bold">We're Here to Help</div>
            <p>
              Explore solutions for troubleshooting, technical support, and
              clinical inquiries. Our dedicated team and resources ensure you
              have the help you need, when you need it. <br />
              <b>
                This support page is not actively monitored. For urgent medical
                assistance, please call 911 or your local emergency number
                immediately.
              </b>
            </p>
          </Col>
          <Col xs={0} sm={0} md={0} lg={3} xl={6} style={{ lineHeight: 0 }}>
            <img src={patientSupportImages.BANNER} alt="banner" width="100%" />
          </Col>
        </Row>
      </div>

      <Row gutter={[16, 16]} className="mt2">
        <Col xs={24} sm={12} md={12} lg={12} xl={8}>
          {renderCard(patientSupportImages.TROUBLESHOOT, "troubleshoot")}
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={8}>
          {renderCard(patientSupportImages.TECH, "technical")}
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={8}>
          {renderCard(patientSupportImages.CLINIC, "clinic")}
        </Col>
      </Row>
      {activeCard && (
        <Row gutter={[16, 16]} justify="center" className="mt2">
          <Col span={24}>
            {activeCard === "technical" && (
              <Technical
                setActiveCard={setActiveCard}
                activeCard={activeCard}
                staticContent={staticContent?.technical}
              />
            )}
            {activeCard === "clinic" && (
              <Clinic
                setActiveCard={setActiveCard}
                activeCard={activeCard}
                staticContent={staticContent?.clinic}
              />
            )}
            {activeCard === "troubleshoot" && (
              <Troubleshoot
                activeCard={activeCard}
                setActiveCard={setActiveCard}
                staticContent={staticContent?.troubleshoot}
              />
            )}
          </Col>
        </Row>
      )}
    </div>
  );
};

export default PatientSupport;
