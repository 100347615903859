import { Card, Col, Divider, Empty, Modal, Row, Spin, Tag } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setTimeSlots,
  setWebinarDetails,
} from "../../../Reducers/bookingsSlice";
import { getApis, postApis } from "../../Common/Apis";
import { BUTTONS, NOTIFICATIONS } from "../../Common/Constants";
import {
  clinicAdminWebinarBookingsUrl,
  clinicAdminWebinarSlotsUrl,
} from "../../Common/Endpoints";
import MonthlyCalendar from "../../Common/MonthlyCalendar";
import useNotify from "../../Common/Notify";
import { centalisedSubmitBtn, disabledBookingsDate } from "../../Common/Utils";

const ChooseSlot = () => {
  const bookings = useSelector((state) => state?.bookings);
  const timeSlots = bookings?.timeSlots;
  const webinarDetails = bookings?.webinarDetails;
  const [showLoader, setShowLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [amTimes, setAmTimes] = useState([]);
  const [pmTimes, setPmTimes] = useState([]);
  const [slot, setSlot] = useState();
  const dispatch = useDispatch();
  const { notify } = useNotify();
  const { confirm } = Modal;

  const getAvailableSlots = async (params) => {
    await getApis(clinicAdminWebinarSlotsUrl, params)
      .then((response) => {
        setShowLoader(false);
        dispatch(setWebinarDetails(params));
        dispatch(setTimeSlots(response?.data));
        setSlot();
      })
      .catch(() => {
        dispatch(setTimeSlots([]));
        setShowLoader(false);
      });
  };

  const handleDateSelect = async (date) => {
    setShowLoader(true);
    getAvailableSlots({ bookingDate: date.format("YYYY-MM-DD") });
  };

  const showConfirm = () => {
    confirm({
      title: "Are you sure?",
      content: "This action will book a webinar slot.",
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        submitHandler();
      },
      onCancel() {
        setLoading(false);
      },
    });
  };

  const submitHandler = async () => {
    if (slot) {
      setLoading(true);
      const params = { ...webinarDetails, slot: slot };
      dispatch(setWebinarDetails(params));

      await postApis(clinicAdminWebinarBookingsUrl, params)
        .then((response) => {
          notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch(() => setLoading(false));
    } else {
      notify(NOTIFICATIONS.ERROR.KEY, "Please select a Slot");
    }
  };

  useEffect(() => {
    const am = timeSlots?.filter((time) => time.includes("AM"));
    const pm = timeSlots?.filter((time) => time.includes("PM"));

    setAmTimes(am);
    setPmTimes(pm);
  }, [timeSlots]);

  const handleTagClick = (time) => {
    setSlot(time);
  };

  return (
    <Card title="Book New Slot" className="mt1" data-testid="chooseSlot">
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Card
            className="box-shadow"
            styles={{
              body: {
                padding: "0.5rem 1rem",
              },
            }}
          >
            <MonthlyCalendar
              handleDateSelect={handleDateSelect}
              disabledDate={disabledBookingsDate}
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Card
            className="box-shadow"
            title="Available Slots"
            styles={{
              body: {
                minHeight: "328px",
                height: "328px",
                overflow: "auto",
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              },
            }}
          >
            <div className="flex-center">
              {showLoader ? (
                <Spin size="large" />
              ) : timeSlots?.length > 0 ? (
                <div>
                  <div className="bold blue">Morning</div>
                  <Divider style={{ margin: "0.5rem 0" }} />
                  {amTimes?.map((item, index) => {
                    return (
                      <Tag
                        key={index}
                        className={`mt1 ml05 cursor-pointer ${
                          slot === item ? "selected-tag" : ""
                        }`}
                        onClick={() => handleTagClick(item)}
                      >
                        {item}
                      </Tag>
                    );
                  })}
                  <div className="bold blue mt2">Afternoon</div>
                  <Divider style={{ margin: "0.5rem 0" }} />
                  {pmTimes?.map((item, index) => {
                    return (
                      <Tag
                        key={index}
                        className={`mt1 ml05 cursor-pointer ${
                          slot === item ? "selected-tag" : ""
                        }`}
                        onClick={() => handleTagClick(item)}
                      >
                        {item}
                      </Tag>
                    );
                  })}
                </div>
              ) : (
                <Empty />
              )}
            </div>
          </Card>
        </Col>
      </Row>

      {centalisedSubmitBtn(loading, BUTTONS.SUBMIT, showConfirm, 4)}
    </Card>
  );
};

export default ChooseSlot;
