import { Button, Card, Col, Form, Input, Row } from "antd";
import { useState } from "react";
import { postApis } from "../../Common/Apis";
import { BUTTONS, NOTIFICATIONS, VALIDATIONS } from "../../Common/Constants";
import { clinicAdminSupportUrl } from "../../Common/Endpoints";
import Loader from "../../Common/Loader";
import useNotify from "../../Common/Notify";
const { TextArea } = Input;

const Technical = ({ activeCard, staticContent }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState();
  const { notify } = useNotify();

  const onFinish = async (values) => {
    setLoading(true);
    await postApis(clinicAdminSupportUrl, { ...values, type: activeCard })
      .then((response) => {
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch(() => setLoading(false));
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  return (
    <Row justify={"center"}>
      <Col span={24}>
        <Card>
          <div
            className="mb2"
            dangerouslySetInnerHTML={{ __html: staticContent?.clinicAdmin }}
          />
          <Form
            initialValues={{}}
            form={form}
            layout="vertical"
            size="large"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            validateMessages={{
              required: VALIDATIONS.DEFAULT.BLANK,
            }}
          >
            <Form.Item
              label={"What issues are you encountering?"}
              name="issue"
              size="large"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Issue" />
            </Form.Item>

            <Form.Item
              label={"Kindly explain the problem in detail"}
              name="description"
              size="large"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <TextArea rows={3} placeholder="Description" />
            </Form.Item>

            <Row justify={"center"}>
              <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={loading}
                    size="large"
                    className="mt1"
                  >
                    <Loader loading={loading} text={BUTTONS.SUBMIT} />
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default Technical;
