import { Button, Col, Form, Input, Row } from "antd";
import { setProfileDetails } from "../../../Reducers/profileSlice";
import { putApis } from "../../Common/Apis";
import {
  BUTTONS,
  FORM_FIELDS,
  NOTIFICATIONS,
  VALIDATIONS,
} from "../../Common/Constants";
import { patientUpdateProfileUrl } from "../../Common/Endpoints";
import Loader from "../../Common/Loader";
import { setUserDetails } from "../../Common/Utils";
import Autocomplete from "react-google-autocomplete";

const LocationDetails = ({
  loading,
  userDetails,
  setLoading,
  profileDetails,
  notify,
  form,
  dispatch,
  setShowLocationForm,
  showLocationForm,
  renderProfileDetails,
  initialValues,
}) => {
  const getCityFromPlace = (place) => {
    let city = "";

    place?.address_components?.forEach((component) => {
      if (component.types.includes("locality")) {
        city = component.long_name;
      }
    });

    return city;
  };

  const getStateFromPlace = (place) => {
    let state = "";

    place?.address_components?.forEach((component) => {
      if (component.types.includes("administrative_area_level_1")) {
        state = component.long_name;
      }
    });

    return state;
  };

  const getZipCodeFromPlace = (place) => {
    let zipCode = "";

    place?.address_components?.forEach((component) => {
      if (component.types.includes("postal_code")) {
        zipCode = component.long_name;
      }
    });

    return zipCode;
  };

  const onFinish = async (values) => {
    setLoading(true);

    await putApis(patientUpdateProfileUrl, values)
      .then((response) => {
        setUserDetails({ ...userDetails, ...response?.data });
        dispatch(setProfileDetails({ ...userDetails, ...response?.data }));
        setLoading(false);
        setShowLocationForm(false);
        notify(NOTIFICATIONS.SUCCESS.KEY, "Location details has been updated");
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };
  const onFinishFailed = () => {
    setLoading(false);
  };

  return (
    <div>
      {showLocationForm ? (
        <Form
          initialValues={{
            ...initialValues,
            addressLine1: profileDetails?.addressLine1 || "",
          }}
          form={form}
          layout="vertical"
          size="large"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          validateMessages={{
            required: VALIDATIONS.DEFAULT.BLANK,
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label={FORM_FIELDS.ADDRESS_LINE_1}
                name="addressLine1"
                size="large"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Autocomplete
                  apiKey={process.env.REACT_APP_GOOGLE_LOCATIONS_APY_KEY}
                  onPlaceSelected={(place) => {
                    form.setFieldsValue({
                      addressLine1: place.formatted_address,
                      city: getCityFromPlace(place),
                      state: getStateFromPlace(place),
                      zipcode: getZipCodeFromPlace(place),
                    });
                  }}
                  onBlur={() => {
                    form.setFieldsValue({
                      addressLine1: "",
                      city: "",
                      state: "",
                      zipcode: "",
                    });
                  }}
                  onChange={(value) => {
                    if (!value) {
                      form.setFieldsValue({
                        addressLine1: "",
                        city: "",
                        state: "",
                        zipcode: "",
                      });
                    }
                  }}
                  placeholder={FORM_FIELDS.ADDRESS_LINE_1}
                  className="ant-autocomplete"
                  options={{
                    types: ["address"],
                    componentRestrictions: { country: "us" },
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label={FORM_FIELDS.ADDRESS_LINE_2}
                name="addressLine2"
                size="large"
              >
                <Input placeholder={FORM_FIELDS.ADDRESS_LINE_2} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                label={FORM_FIELDS.CITY}
                name="city"
                size="large"
                rules={[
                  {
                    pattern: /^[A-Za-z\s]+$/,
                    message: VALIDATIONS.PATTERN.STRING,
                  },
                ]}
              >
                <Input placeholder={FORM_FIELDS.CITY} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                label={FORM_FIELDS.STATE}
                name="state"
                size="large"
                rules={[
                  {
                    pattern: /^[A-Za-z\s]+$/,
                    message: VALIDATIONS.PATTERN.STRING,
                  },
                ]}
              >
                <Input placeholder={FORM_FIELDS.STATE} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                label={FORM_FIELDS.ZIPCODE}
                name="zipcode"
                size="large"
                rules={[
                  {
                    pattern: /^[0-9]+$/,
                    message: VALIDATIONS.PATTERN.NUMERIC,
                  },
                ]}
              >
                <Input placeholder={FORM_FIELDS.ZIPCODE} />
              </Form.Item>
            </Col>
          </Row>
          <Row className="flex-center">
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <Form.Item className="mt2">
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  disabled={loading}
                >
                  <Loader loading={loading} text={BUTTONS.UPDATE} />
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : (
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            {renderProfileDetails(
              FORM_FIELDS.ADDRESS_LINE_1,
              profileDetails?.addressLine1 || "Not Available"
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            {renderProfileDetails(
              FORM_FIELDS.ADDRESS_LINE_2,
              profileDetails?.addressLine2 || "Not Available"
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            {renderProfileDetails(
              FORM_FIELDS.CITY,
              profileDetails?.city || "Not Available"
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            {renderProfileDetails(
              FORM_FIELDS.STATE,
              profileDetails?.state || "Not Available"
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            {renderProfileDetails(
              FORM_FIELDS.ZIPCODE,
              profileDetails?.zipcode || "Not Available"
            )}
          </Col>
        </Row>
      )}
    </div>
  );
};

export default LocationDetails;
