import { ArrowRightOutlined, ClockCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Carousel,
  Col,
  Divider,
  Form,
  Input,
  Radio,
  Result,
  Row,
  Slider,
  Space,
} from "antd";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { BUTTONS, VALIDATIONS } from "../../Common/Constants";

import Loader from "../../Common/Loader";
import Popup from "../../Common/Popup";
import { displayDateTime, isAndroidOrIosApp } from "../../Common/Utils";

const TinnitusForm = ({
  staticQuestionnaires,
  loading,
  onFinish,
  onFinishFailed,
  service,
  clinic,
  isQuestionnaireModalOpen,
  handleQuestionnaireModalCancel,
  unSubmittedObject,
  questionnaires,
}) => {
  const isMobile = useSelector((state) => state?.app?.isMobile);
  const [form] = Form.useForm();
  const carouselRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const isMobileApp = isAndroidOrIosApp();
  const questions =
    staticQuestionnaires?.questionnaires?.[unSubmittedObject?.subType]?.data;

  const handleNext = async () => {
    try {
      const currentQuestions = questions[currentSlide]?.questions.map(
        (question) => question.value
      );
      await form.validateFields(currentQuestions);

      if (carouselRef.current) {
        carouselRef.current.next();
      }
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const handlePrev = () => {
    if (currentSlide > 0) {
      carouselRef.current.prev();
      setCurrentSlide((prev) => prev - 1);
    }
  };

  const renderQuestions = () => {
    return questions?.map((questionnaire, mIndex) => {
      return (
        <div key={mIndex}>
          <div className="bold blue mb1">
            <ArrowRightOutlined className="mr05" /> {questionnaire?.label}
          </div>
          {questionnaire?.questions?.map((question, qIndex) => {
            return (
              <div key={qIndex}>
                <div id={`${question?.value}`}>
                  <span className="mr025">{qIndex + 1}.</span> {question?.title}
                </div>
                <Form.Item
                  size="large"
                  name={question?.value}
                  className={
                    unSubmittedObject?.subType === "setOne"
                      ? "flex-start-center center-radio mt1"
                      : "mt1"
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please select an option",
                    },
                  ]}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  style={{
                    marginBottom:
                      unSubmittedObject?.subType === "setOne" ? "15px" : "0px",
                    padding:
                      unSubmittedObject?.subType === "setOne" ? 0 : "0 0.5rem",
                  }}
                >
                  {unSubmittedObject?.subType === "setOne" ? (
                    <Radio.Group className="m0">
                      <Space direction="vertical">
                        {questionnaire?.options?.map((opt, index) => {
                          return (
                            <Radio key={index} value={opt?.value}>
                              {opt?.label}
                            </Radio>
                          );
                        })}
                      </Space>
                    </Radio.Group>
                  ) : (
                    <Slider
                      min={question?.minScale}
                      max={question?.maxScale}
                      step={question?.stepScale}
                      dots
                      marks={
                        isMobile
                          ? {
                              [question?.minScale]: question?.minScale,
                              [question?.maxScale]: question?.maxScale,
                            }
                          : question?.scale
                      }
                      onChange={(value) => {
                        form.setFieldValue(question?.minScale, value || 0);
                      }}
                    />
                  )}
                </Form.Item>
                {unSubmittedObject?.subType === "setTwo" && (
                  <div className="slider-flex">
                    <div className="fs13 grey mb1 text-left">
                      {question?.startLabel}
                    </div>
                    <div className="fs13 grey mb1 text-right">
                      {question?.endLabel}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      );
    });
  };

  return (
    <Popup
      title="Questionnaires"
      open={isQuestionnaireModalOpen}
      cancel={handleQuestionnaireModalCancel}
      width={900}
      footer={null}
      style={{ top: "25px" }}
    >
      {!unSubmittedObject ? (
        questionnaires?.map((item, index) => {
          return (
            <div key={index} className={`${index > 0 ? "mt2" : ""}`}>
              {item?.submitted && (
                <Result
                  icon={false}
                  title={`${
                    staticQuestionnaires?.["questionnaires"]?.[item?.subType]
                      ?.label
                  } has already been submitted`}
                  subTitle={
                    <div>
                      <span className="bold mr05">
                        <ClockCircleOutlined /> Last Submitted On:
                      </span>
                      <span>{displayDateTime(item?.lastUpdated)}</span>
                    </div>
                  }
                />
              )}
              {index !== questionnaires?.length - 1 && <Divider />}
            </div>
          );
        })
      ) : (
        <div>
          <Row justify={"end"} gutter={[16, 16]}>
            <Col span={24} className="bold fs16">
              <div className="blue">
                {service +
                  " - " +
                  staticQuestionnaires?.["questionnaires"]?.[
                    unSubmittedObject?.subType
                  ]?.label}
              </div>
            </Col>
          </Row>
          <Divider style={{ margin: "0.25rem 0 1.5rem 0" }} />

          <div className="bold mb2">
            {
              staticQuestionnaires?.["questionnaires"]?.[
                unSubmittedObject?.subType
              ]?.description
            }
          </div>
          <Form
            name={unSubmittedObject?.subType}
            form={form}
            size="large"
            initialValues={{
              clinic: clinic,
              service: service,
              type: "questionnaires",
              subType: unSubmittedObject?.subType,
              ...(unSubmittedObject?.subType === "setTwo"
                ? staticQuestionnaires?.questionnaires?.[
                    unSubmittedObject?.subType
                  ]?.data?.reduce((acc, questionnaire) => {
                    questionnaire?.questions?.forEach((question) => {
                      acc[question?.value] = 0;
                    });
                    return acc;
                  }, {})
                : {}),
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            validateMessages={{
              required: VALIDATIONS.DEFAULT.BLANK,
            }}
          >
            <Form.Item name="clinic" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="service" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="type" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="subType" hidden>
              <Input />
            </Form.Item>

            {isMobile || isMobileApp ? (
              <Carousel
                ref={carouselRef}
                dots={false}
                afterChange={(current) => setCurrentSlide(current)}
              >
                {renderQuestions()}
              </Carousel>
            ) : (
              <>{renderQuestions()}</>
            )}
            <Row justify={"center"}>
              {isMobile || isMobileApp ? (
                <Col xs={24} sm={24} md={10} lg={6} xl={6}>
                  <Button
                    onClick={handlePrev}
                    disabled={currentSlide === 0}
                    size="middle"
                  >
                    Previous
                  </Button>
                  {currentSlide < questions.length - 1 ? (
                    <Button
                      type="primary"
                      onClick={handleNext}
                      disabled={currentSlide === questions.length - 1}
                      size="middle"
                      className="right"
                    >
                      Next
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      size="middle"
                      htmlType="submit"
                      disabled={loading}
                      className="right"
                    >
                      <Loader loading={loading} text={BUTTONS.SUBMIT} />
                    </Button>
                  )}
                </Col>
              ) : (
                <Col xs={24} sm={24} md={10} lg={6} xl={6}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    disabled={loading}
                    className="mt1"
                  >
                    <Loader loading={loading} text={BUTTONS.SUBMIT} />
                  </Button>
                </Col>
              )}
            </Row>
          </Form>
        </div>
      )}
    </Popup>
  );
};

export default TinnitusForm;
