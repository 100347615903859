import { Button, Form, Select } from "antd";
import { BUTTONS, VALIDATIONS } from "../../../Common/Constants";
import Loader from "../../../Common/Loader";
import Popup from "../../../Common/Popup";

const DispenseModal = ({
  dispenseDevicesModal,
  cancelDispenseDevicesModal,
  deviceList,
  onFinish,
  onFinishFailed,
  loading,
}) => {
  const [form] = Form.useForm();

  return (
    <Popup
      title="Dispense Device"
      open={dispenseDevicesModal}
      cancel={cancelDispenseDevicesModal}
      footer={null}
      width={500}
    >
      <Form
        initialValues={{}}
        form={form}
        layout="vertical"
        size="large"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        validateMessages={{
          required: VALIDATIONS.DEFAULT.BLANK,
        }}
        className="pd1"
      >
        <Form.Item
          label={false}
          name="device"
          size="large"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            name="device"
            placeholder="Please Select"
            options={deviceList?.map((item) => {
              return { label: item, value: item };
            })}
          />
        </Form.Item>
        <Button
          type="primary"
          className="mt2"
          htmlType="submit"
          block
          disabled={loading}
        >
          <Loader loading={loading} text={BUTTONS.SUBMIT} />
        </Button>
      </Form>
    </Popup>
  );
};

export default DispenseModal;
