import { Avatar, Drawer, Dropdown, Flex, Layout, Menu, Space } from "antd";
import { Link } from "react-router-dom";
import { ROUTES, USER_TYPES } from "../../Constants";
import {
  MenuOutlined,
  CloseOutlined,
  UserOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import LOGO from "../../../../Assets/images/logo.png";
import { desktopMenuItems, mobileMenuItems } from "../../MenuData";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getApis } from "../../Apis";
import { providerNotificationUrl } from "../../Endpoints";
import { setNotification } from "../../../../Reducers/authSlice";
import { getOpenKeys, isAndroidOrIosApp } from "../../Utils";
import { patientSupportImages } from "../../Images";
const { Header } = Layout;

const AuthHeader = ({
  handleSideMenuClick,
  isDrawerOpen,
  toggleDrawer,
  fromHomePage,
  userDetails,
  selectedSideMenu,
  pathname,
  isMobile,
  isTablet,
}) => {
  const notification = useSelector((state) => state?.auth?.notification);
  const [defaultPath, setDefaultPath] = useState(ROUTES.DEFAULT.PATH);
  const defaultDoctor = localStorage.getItem("defaultDoctor");
  const isMobileApp = isAndroidOrIosApp();
  const dispatch = useDispatch();
  const userFullName = userDetails
    ? `${userDetails?.firstName} ${userDetails?.lastName}`
    : "Loading...";
  const userType =
    useSelector((state) => state?.app?.userType) ||
    localStorage.getItem("userType");

  const renderUserMenu = () => {
    return (
      <Space>
        <Avatar className="avatar-icon">
          <UserOutlined />
        </Avatar>

        {userFullName}
        {!isMobile && <CaretDownOutlined className="ml025" />}
      </Space>
    );
  };

  const menuProps = {
    items: fromHomePage
      ? mobileMenuItems(notification, fromHomePage)
      : desktopMenuItems(notification),
    onClick: handleSideMenuClick,
  };

  const getNotifications = async () => {
    await getApis(
      providerNotificationUrl,
      defaultDoctor ? { doctor: defaultDoctor } : {}
    )
      .then((response) => {
        dispatch(setNotification(response?.data));
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (userType === USER_TYPES.PROVIDER) {
      if (!notification && fromHomePage) {
        getNotifications();
      }
    }
    if (isMobileApp) {
      setDefaultPath(ROUTES.LANDING.PATH);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    !isMobileApp && (
      <Header className="fixed-header">
        <Flex
          justify="space-between"
          wrap="wrap"
          gap="small"
          className="align-center"
        >
          <Link to={defaultPath} className="flex-center">
            <img src={LOGO} alt="Logo" width="140" />
          </Link>
          {isMobile || isTablet ? (
            <>
              <MenuOutlined
                onClick={toggleDrawer}
                style={{ fontSize: "22px" }}
                className="right"
              />

              <Drawer
                title={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontSize: "14px",
                    }}
                  >
                    {renderUserMenu()}
                    <CloseOutlined
                      onClick={toggleDrawer}
                      className="cursor-pointer"
                    />
                  </div>
                }
                width={"70%"}
                placement="right"
                closeIcon={false}
                onClose={toggleDrawer}
                open={isDrawerOpen}
              >
                <Menu
                  className="mt1 remove-icon"
                  selectedKeys={[selectedSideMenu]}
                  defaultOpenKeys={[getOpenKeys(pathname)]}
                  mode="inline"
                  items={mobileMenuItems(notification)}
                  onClick={handleSideMenuClick}
                />
              </Drawer>
            </>
          ) : (
            <Flex className="flex-center">
              <Space align="center" size="small">
                <Link
                  to={
                    userType === USER_TYPES.PROVIDER
                      ? ROUTES.PROVIDER.SUPPORT.PATH
                      : userType === USER_TYPES.CLINIC_ADMIN
                      ? ROUTES.CLINIC_ADMIN.SUPPORT.PATH
                      : ROUTES.PATIENT.SUPPORT.PATH
                  }
                  title="Support"
                  className="flex-center"
                >
                  <img
                    src={patientSupportImages.HEADER}
                    alt="support"
                    width="30%"
                  />
                  <span className="ml05 black">Support</span>
                </Link>
              </Space>

              <div className="ml1">
                <Dropdown menu={menuProps} className="flex-center">
                  {renderUserMenu()}
                </Dropdown>
              </div>
            </Flex>
          )}
        </Flex>
      </Header>
    )
  );
};

export default AuthHeader;
