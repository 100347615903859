import {
  CalendarOutlined,
  ClockCircleOutlined,
  ColumnHeightOutlined,
  ColumnWidthOutlined,
  ContactsOutlined,
  DollarOutlined,
  EnvironmentOutlined,
  FileDoneOutlined,
  HistoryOutlined,
  InfoCircleOutlined,
  InsuranceOutlined,
  KeyOutlined,
  MailOutlined,
  PhoneOutlined,
  SignatureOutlined,
  SkinOutlined,
  UserOutlined,
  WomanOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { Link } from "react-router-dom";
import { displayOnlyDate, getPageHeader } from "../Utils";

export const bookingsInfo = (details) => {
  return [
    {
      icon: CalendarOutlined,
      label: "Scheduled For",
      value: `${moment(details?.bookingDate).format("MM/DD/YYYY")} (${
        details?.slot
      })`,
    },
    {
      icon: ClockCircleOutlined,
      label: "Number of Days",
      value: details?.days,
    },
    {
      icon: EnvironmentOutlined,
      label: "Location",
      value: details?.location,
    },
    {
      icon: CalendarOutlined,
      label: "Return Date",
      value: moment(details?.returnDate).format("MM/DD/YYYY"),
    },
    {
      icon: DollarOutlined,
      label: "Payment Method",
      value: details?.paymentStatus,
    },
    {
      icon: InfoCircleOutlined,
      label: "Payment Status",
      value: details?.stripePaymentStatus
        ? details?.stripePaymentStatus === "NA"
          ? details?.stripePaymentStatus
          : getPageHeader(details?.stripePaymentStatus)
        : null,
    },
    {
      icon: InsuranceOutlined,
      label: "Insurance",
      value: details?.paymentStatus === "Insurance" ? details?.insurance : null,
    },
  ];
};

export const bookingsInfoForPatient = (details) => {
  return details?.bookingType === "SFOTO-FOTONA-NIGHTLASE"
    ? bookingsInfo(details).filter((item) => item?.label !== "Return Date")
    : [...bookingsInfo(details), ...signedInfo(details)];
};

export const bookingsInfoForClinicAdmin = (details) => {
  return details?.bookingType === "SFOTO-FOTONA-NIGHTLASE"
    ? bookingsInfo(details).filter((item) => item?.label !== "Return Date")
    : [...bookingsInfo(details), ...agreementInfo(details)];
};

const signedInfo = (details) => {
  return [
    {
      icon: DollarOutlined,
      label: "Paid Amount",
      value: details?.paidAmount,
    },
    {
      icon: SignatureOutlined,
      label: "Document Signed",
      value: details?.sign ? "Yes" : "No",
    },
  ];
};

const agreementInfo = (details) => {
  return [
    {
      icon: FileDoneOutlined,
      label: "Agreement",
      value: details?.agreement ? (
        <Link to={details?.agreement} target={"_blank"}>
          View Here
        </Link>
      ) : null,
    },
  ];
};

export const userInfo = (details) => {
  return [
    {
      icon: UserOutlined,
      label: "Full Name",
      value: details?.firstName
        ? `${details?.firstName} ${details?.lastName}`
        : null,
    },
    {
      icon: MailOutlined,
      label: "Email",
      value: details?.email,
    },
    {
      icon: PhoneOutlined,
      label: "Phone",
      value: details?.phoneNumber,
    },
    {
      icon: ContactsOutlined,
      label: "Date Of Birth",
      value: details?.dateOfBirth
        ? displayOnlyDate(details?.dateOfBirth)
        : null,
    },
    {
      icon: ColumnHeightOutlined,
      label: "Height(cm)",
      value: details?.height,
    },
    {
      icon: ColumnWidthOutlined,
      label: "Weight(kg)",
      value: details?.weight,
    },
    {
      icon: HistoryOutlined,
      label: "Age",
      value: details?.age,
    },
    {
      icon: KeyOutlined,
      label: "Address",
      value: details?.address,
    },
    {
      icon: WomanOutlined,
      label: "Gender",
      value: details?.gender,
    },
    {
      icon: SkinOutlined,
      label: "Skin Color",
      value: details?.skinColor,
    },
  ];
};
