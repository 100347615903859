import { Button, Card, Col, Divider, Empty, Row, Spin, Tag } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setBookingDetails,
  setTimeSlots,
} from "../../../../Reducers/bookingsSlice";
import { getApis } from "../../../Common/Apis";
import { BUTTONS, NOTIFICATIONS } from "../../../Common/Constants";
import { clinicAdminSleepSlotsUrl } from "../../../Common/Endpoints";
import MonthlyCalendar from "../../../Common/MonthlyCalendar";
import useNotify from "../../../Common/Notify";
import {
  centalisedSubmitBtn,
  disabledBookingsDate,
  getOnlyWeekDays,
} from "../../../Common/Utils";
import dayjs from "dayjs";

const ChooseSlot = ({ setCurrentStep, currentStep }) => {
  const bookings = useSelector((state) => state?.bookings);
  const timeSlots = bookings?.timeSlots;
  const bookingDetails = bookings?.details;
  const patientDetails = bookings?.patientDetails;
  const [showLoader, setShowLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [amTimes, setAmTimes] = useState([]);
  const [pmTimes, setPmTimes] = useState([]);
  const [slot, setSlot] = useState();
  const dispatch = useDispatch();
  const { notify } = useNotify();

  const getAvailableSlots = async (params) => {
    await getApis(clinicAdminSleepSlotsUrl, params)
      .then((response) => {
        setShowLoader(false);
        dispatch(setBookingDetails(params));
        dispatch(setTimeSlots(response?.data));
        setSlot();
      })
      .catch(() => {
        dispatch(setTimeSlots([]));
        setShowLoader(false);
      });
  };

  const handleDateSelect = async (date) => {
    if (date.format("YYYY-MM-DD") !== bookingDetails?.bookingDate) {
      setShowLoader(true);
      const resultDate = getOnlyWeekDays(
        date.format("YYYY-MM-DD"),
        bookingDetails?.days
      );
      const updatedValues = {
        ...bookingDetails,
        bookingDate: date.format("YYYY-MM-DD"),
        returnDate: resultDate.format("YYYY-MM-DD"),
        dateOfBirth: patientDetails?.dateOfBirth,
        phoneNumber: patientDetails?.phoneNumber,
      };
      getAvailableSlots(updatedValues);
    }
  };

  const submitHandler = async () => {
    if (slot) {
      setLoading(true);
      dispatch(setBookingDetails({ ...bookingDetails, slot: slot }));
      setCurrentStep(2);
    } else {
      notify(NOTIFICATIONS.ERROR.KEY, "Please select a Slot");
    }
  };

  useEffect(() => {
    const am = timeSlots?.filter((time) => time.includes("AM"));
    const pm = timeSlots?.filter((time) => time.includes("PM"));

    setAmTimes(am);
    setPmTimes(pm);
  }, [timeSlots]);

  const handleTagClick = (time) => {
    setSlot(time);
  };

  return (
    <div data-testid="chooseSlot">
      <Row gutter={[16, 16]} className="mt3">
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Card
            className="box-shadow"
            styles={{
              body: {
                padding: "0.5rem 1rem",
              },
            }}
          >
            <MonthlyCalendar
              defaultValue={dayjs().add(0, "day")}
              handleDateSelect={handleDateSelect}
              disabledDate={(current) =>
                disabledBookingsDate(current, false, true)
              }
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Card
            className="box-shadow"
            title="Available Slots"
            styles={{
              body: {
                minHeight: "337px",
                height: "337px",
                overflow: "auto",
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              },
            }}
          >
            <div className="flex-center">
              {showLoader ? (
                <Spin size="large" />
              ) : timeSlots?.length > 0 ? (
                <div>
                  <div className="bold blue">Morning</div>
                  <Divider style={{ margin: "0.5rem 0" }} />
                  {amTimes?.map((item, index) => {
                    return (
                      <Tag
                        key={index}
                        className={`mt1 ml05 cursor-pointer ${
                          slot === item ? "selected-tag" : ""
                        }`}
                        onClick={() => handleTagClick(item)}
                      >
                        {item}
                      </Tag>
                    );
                  })}
                  <div className="bold blue mt2">Afternoon</div>
                  <Divider style={{ margin: "0.5rem 0" }} />
                  {pmTimes?.map((item, index) => {
                    return (
                      <Tag
                        key={index}
                        className={`mt1 ml05 cursor-pointer ${
                          slot === item ? "selected-tag" : ""
                        }`}
                        onClick={() => handleTagClick(item)}
                      >
                        {item}
                      </Tag>
                    );
                  })}
                </div>
              ) : (
                <Empty />
              )}
            </div>
          </Card>
        </Col>
      </Row>
      <Row className="mt1">
        <Col span={24}>
          <p>
            <b>Note:</b> Each time slot is valid for 30 minutes only.
          </p>
        </Col>
      </Row>
      {centalisedSubmitBtn(loading, BUTTONS.NEXT, submitHandler, 4)}
      <div className="flex-center mt1">
        <Button size="small" onClick={() => setCurrentStep(currentStep - 1)}>
          Go Back
        </Button>
      </div>
    </div>
  );
};

export default ChooseSlot;
