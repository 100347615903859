import { InfoCircleFilled } from "@ant-design/icons";
import { Col, Divider, Result, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetBookingDetails } from "../../../../Reducers/bookingsSlice";
import { putApis } from "../../../Common/Apis";
import { BUTTONS, ROUTES, USER_TYPES } from "../../../Common/Constants";
import { patientBookingsUrl } from "../../../Common/Endpoints";
import {
  centalisedSubmitBtn,
  getBookingType,
  getOS,
  logAnalyticsEvent,
  rowDataWithIcon,
} from "../../../Common/Utils";
import { displayConfirmInfo } from "./Info";

const Confirm = ({ sessionId, clinic, userDetails }) => {
  const [showLoader, setShowLoader] = useState(false);
  const [details, setDetails] = useState();
  const bookingSlice = useSelector((state) => state?.bookings?.details);
  const bookingDetails = {
    ...bookingSlice,
    ...JSON.parse(localStorage.getItem("bookingDetails")),
  };

  const dispatch = useDispatch();

  const createBookings = async () => {
    setShowLoader(true);
    await putApis(patientBookingsUrl, {
      ...bookingDetails,
      checkoutSessionID: sessionId,
      bookingType: getBookingType(clinic, "HOME-SLEEP-DEVICE"),
    })
      .then((response) => {
        setShowLoader(false);
        setDetails(response?.data);
        dispatch(resetBookingDetails());
        logAnalyticsEvent("booked", {
          userType: USER_TYPES.PATIENT,
          data: userDetails?.phoneNumber,
          os: getOS(),
        });
      })
      .catch(() => setShowLoader(false));
  };

  useEffect(() => {
    if (!details && bookingDetails) {
      createBookings();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickHandler = () => {
    localStorage.removeItem("bookingDetails");
    window.location.assign(ROUTES.PATIENT.BOOKINGS.PATH);
  };

  const cardBody = () => {
    if (details) {
      return (
        <div>
          {displayConfirmInfo(details)?.map((item, index) => {
            return rowDataWithIcon(index, item?.icon, item?.label, item?.value);
          })}
          {centalisedSubmitBtn(false, BUTTONS.DONE, onClickHandler, 6)}
        </div>
      );
    } else {
      return (
        <Result
          subTitle="No data found"
          extra={centalisedSubmitBtn(false, BUTTONS.DONE, onClickHandler, 6)}
        />
      );
    }
  };

  return (
    <Row justify={"center"} className="mt2">
      <Col xs={24} sm={24} md={24} lg={16} xl={16}>
        <div className="bold fs20 blue">
          <div className="flex-start-center">
            <InfoCircleFilled className="icon24 mr05" /> Status
          </div>
        </div>
        <Divider style={{ margin: "0.5rem 0 2rem 0" }} />

        {showLoader ? (
          <Spin size="large" className="flex-center" />
        ) : (
          cardBody()
        )}
      </Col>
    </Row>
  );
};

export default Confirm;
