import { useEffect } from "react";
import * as echarts from "echarts";
import { displayOnlyTime, getGraphTime } from "../../../Common/Utils";

const AreaChart = ({ color, chartDivId, frequency, healthDetails }) => {
  const filteredData = () => {
    const list = healthDetails?.list || [];

    if (list.length === 0) {
      return [["No Data", 0]];
    }

    if (frequency === "day") {
      return [
        ["Time", "Value"],
        ...(healthDetails?.list?.map((entry) => {
          const timestamp = Object.keys(entry)[0];
          const value = entry[timestamp];

          if (!timestamp || isNaN(new Date(timestamp))) {
            return ["", value];
          }

          const formattedTime = displayOnlyTime(timestamp);
          return [formattedTime, value];
        }) ?? []),
      ];
    } else {
      return [
        ["Date", "Value"],
        ...(healthDetails?.list?.map((entry) => {
          const date = Object.keys(entry)[0];
          let value = entry[date];

          if (value === null || value === undefined) {
            value = 0;
          }

          return [getGraphTime(date), value];
        }) ?? []),
      ];
    }
  };

  useEffect(() => {
    const chartDom = document.getElementById(chartDivId);
    let existingChart = echarts.getInstanceByDom(chartDom);
    if (existingChart) {
      existingChart.dispose();
    }

    const myChart = echarts.init(chartDom);

    const option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
        },
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        axisLabel: {
          show: true,
          fontSize: 11,
          rotate: 45,
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          show: true,
          fontSize: 11,
        },
      },
      dataset: {
        source: filteredData(),
      },
      grid: {
        bottom: 50,
        left: 45,
        right: 15,
        top: 15,
      },
      series: [
        {
          type: "line",
          smooth: true,
          areaStyle: {},
          itemStyle: {
            color: color,
          },
          lineStyle: {
            color: color,
          },
        },
      ],
    };

    myChart.setOption(option);

    return () => {
      myChart.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="mt1"
      id={chartDivId}
      style={{ width: "100%", height: "222px" }}
    ></div>
  );
};

export default AreaChart;
