import { Col, Flex, Row, Segmented } from "antd";
import { patientHealthImages } from "../../Common/Images";
import {
  getFormattedDateRange,
  getFormattedDateRangeFromTimes,
} from "../../Common/Utils";
import SleepBarChart from "./Vitals/SleepBarChart";

const Sleep = ({
  isMobile,
  isTablet,
  frequency,
  healthDetails,
  onFrequencyChange,
}) => {
  const dateRange =
    frequency === "day"
      ? getFormattedDateRangeFromTimes(
          healthDetails?.startTime,
          healthDetails?.endTime
        )
      : getFormattedDateRange(healthDetails, frequency);

  return (
    <>
      <Row gutter={[16, 16]} justify={"space-between"}>
        <Col xs={4} sm={3} md={3} lg={3} xl={4}>
          <img src={patientHealthImages.REM} alt="sleep" width="100%" />
        </Col>

        <Col xs={20} sm={11} md={11} lg={12} xl={11} className="vertical-align">
          <div className="bold blue">Sleep</div>
          {frequency === "day" && (
            <Flex className="flex-baseline">
              <div className="fs28 bold">
                {healthDetails?.duration?.hrs || 0}
              </div>
              <div className="grey fs12 ml025">hrs</div>
              <div className="fs28 bold ml05">
                {healthDetails?.duration?.mins || 0}
              </div>
              <div className="grey fs12 ml025">mins</div>
            </Flex>
          )}
        </Col>
        <Col xs={24} sm={10} md={10} lg={9} xl={9} className="text-right">
          <Segmented
            options={[
              { label: "Day", value: "day" },
              { label: "Week", value: "week" },
              { label: "Month", value: "month" },
            ]}
            size={isMobile || isTablet ? "large" : "middle"}
            value={frequency}
            onChange={(value) => {
              onFrequencyChange(value, "SLEEP_IN_BED");
            }}
          />
          <div className="right fs13 bold grey mt05">{dateRange}</div>
        </Col>
      </Row>
      <SleepBarChart
        chartDivId={"SleepBarChart"}
        healthDetails={healthDetails}
        frequency={frequency}
        color={"#FFC100"}
      />
    </>
  );
};

export default Sleep;
