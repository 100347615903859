import { Card, Col, Form, Row, Tabs } from "antd";
import { useEffect, useState } from "react";
import { getApis, postApis } from "../../Common/Apis";
import {
  providerViewPatientEpisodeUrl,
  providerViewPatientProfileUrl,
  providerViewPatientGraphsUrl,
  providerViewPatientVitalsUrl,
  providerViewPatientAlertsUrl,
  providerViewPatientUnlinkDeviceUrl,
  providerViewPatientTimelinesUrl,
  providerViewPatientFeNoUrl,
  providerViewPatientClaimsUrl,
  providerViewPatientQuestionnairesUrl,
  providerViewPatientEmrDataUrl,
  providerViewPatientReportsUrl,
  providerViewPatientBestPefGraphUrl,
  providerResolveAllAlertsUrl,
  providerViewPatientVialsUrl,
  providerViewPatientWellnessUrl,
  providerViewPatientHealthDataUrl,
  providerViewPatientTinnitusQuestionnairesUrl,
  providerViewPatientTinnitusRiskUrl,
  providerViewPatientAudiologyUrl,
} from "../../Common/Endpoints";
import { useParams } from "react-router-dom";
import { setSelectedEpisode } from "../../../Reducers/profileSlice";
import { useDispatch, useSelector } from "react-redux";
import Graphs from "./Graphs";
import Overview from "./Overview";
import Vitals from "./Vitals";
import WithEpisode from "./WithEpisode";
import Alerts from "./Alerts";
import Devices from "./Devices";
import Claims from "./Claims";
import Timelines from "./Timelines";
import WithoutEpisode from "./WithoutEpisode";
import FeNo from "./FeNo";
import {
  setAlertDetails,
  setBestPefDetails,
  setClaimDetails,
  setDeviceDetails,
  setEmrDetails,
  setEpisodeDetails,
  setFeNoDetails,
  setGraphDetails,
  setProfileDetails,
  setQuestionnaireDetails,
  setReportDetails,
  setTimelineDetails,
  setVialDetails,
  setVitalDetails,
  setWellnessDetails,
  setHealthDetails,
  setRiskDetails,
  setAudiologyDetails,
} from "../../../Reducers/patientSlice";
import Reports from "./Reports";
import WithForm from "./WithForm";
import Questionnaires from "./Questionnaires";
import EmrData from "./EmrData";
import { NOTIFICATIONS, SERVICES } from "../../Common/Constants";
import ResolveAlert from "../Alerts/ResolveAlert";
import useNotify from "../../Common/Notify";
import Dashboard from "./Dashboard";
import Vials from "./Vials";
import Wellness from "./Wellness";
import Health from "./Health";
import { getRemoteConfigData } from "../../Common/Utils";
import Risk from "./Risk";
import Audiology from "./Audiology";

const PatientDetails = () => {
  const { phone, service } = useParams();
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [showLoader, setShowLoader] = useState(false);
  const [activeTab, setActiveTab] = useState(
    service === SERVICES.AUDIOLOGY ? "audiology" : "overview"
  );
  const profile = useSelector((state) => state.profile);
  const patient = useSelector((state) => state.patient);
  const selectedEpisode = profile?.selectedEpisode;
  const [modalOpen, setModalOpen] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [selectedReason, setSelectedReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [loading, setLoading] = useState(false);
  const { notify } = useNotify();
  const defaultDoctor = useSelector((state) => state?.filter?.defaultDoctor);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [staticDropdownData, setStaticDropdownData] = useState();
  const [questionnaires, setQuestionnaires] = useState();
  const [content, setContent] = useState();

  useEffect(() => {
    if (phone) {
      getProfileData();
    }

    getRemoteConfigData("DROPDOWNS").then((result) => {
      setStaticDropdownData(result);
    });

    getRemoteConfigData("CONTENTS").then((result) => {
      setContent(result?.provider?.viewPatient);
    });

    getRemoteConfigData("QUESTIONNAIRES").then((result) => {
      setQuestionnaires(result?.[userDetails?.clinic]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDataCall = async (url, params, action) => {
    setShowLoader(true);
    await getApis(url, params)
      .then((response) => {
        setShowLoader(false);
        dispatch(action(response?.data));
      })
      .catch(() => setShowLoader(false));
  };

  const getEpisodes = async (token) => {
    await getApis(providerViewPatientEpisodeUrl, {
      token: token,
      service: service,
    })
      .then((response) => {
        dispatch(setEpisodeDetails(response?.data));
        dispatch(setSelectedEpisode(response?.data?.currentEpisode));
      })
      .catch(() => {});
  };

  const getProfileData = async () => {
    if (!patient?.profileDetails) {
      setShowLoader(true);
      await getApis(providerViewPatientProfileUrl, {
        phoneNumber: phone.toString(),
      })
        .then((response) => {
          setShowLoader(false);
          dispatch(setProfileDetails(response?.data));
          if (activeTab === "audiology") {
            getAudiologyData(response?.data?.token);
          }
          if (
            ![
              SERVICES.WELLNESS,
              SERVICES.TINNITUS,
              SERVICES.AUDIOLOGY,
            ].includes(service)
          ) {
            getEpisodes(response?.data?.token);
          }
        })
        .catch(() => setShowLoader(false));
    }
  };

  const getAlerts = (episodeNumber) => {
    const episode = episodeNumber || selectedEpisode;
    if (
      patient?.episodeDetails &&
      Object.keys(patient?.episodeDetails).length !== 0
    ) {
      getDataCall(
        providerViewPatientAlertsUrl,
        {
          token: patient?.profileDetails?.token,
          service: service,
          episodeNumber: episode || patient?.episodeDetails?.currentEpisode,
        },
        setAlertDetails
      );
    }
  };

  const getDevices = (reload) => {
    if (reload) {
      getDataCall(
        providerViewPatientUnlinkDeviceUrl,
        {
          token: patient?.profileDetails?.token,
          service: service,
        },
        setDeviceDetails
      );
    }
  };

  const getAudiologyData = (token) => {
    getDataCall(
      providerViewPatientAudiologyUrl,
      {
        token: token || patient?.profileDetails?.token,
      },
      setAudiologyDetails
    );
  };

  const getQuestionnaires = (episodeNumber) => {
    setShowLoader(true);
    if (service === SERVICES.TINNITUS) {
      getDataCall(
        providerViewPatientTinnitusQuestionnairesUrl,
        {
          token: patient?.profileDetails?.token,
        },
        setQuestionnaireDetails
      );
    } else {
      const episode = episodeNumber || selectedEpisode;
      getDataCall(
        providerViewPatientQuestionnairesUrl,
        {
          token: patient?.profileDetails?.token,
          service: service,
          episodeNumber: episode || patient?.episodeDetails?.currentEpisode,
        },
        setQuestionnaireDetails
      );
    }
  };

  const getFeNoData = (reload) => {
    if (reload) {
      getDataCall(
        providerViewPatientFeNoUrl,
        {
          token: patient?.profileDetails?.token,
          service: service,
        },
        setFeNoDetails
      );
    }
  };

  const getReports = (episodeNumber) => {
    const episode = episodeNumber || selectedEpisode;
    if (
      patient?.episodeDetails &&
      Object.keys(patient?.episodeDetails).length !== 0
    ) {
      getDataCall(
        providerViewPatientReportsUrl,
        {
          token: patient?.profileDetails?.token,
          service: service,
          episodeNumber: episode || patient?.episodeDetails?.currentEpisode,
        },
        setReportDetails
      );
    }
  };

  const getGraphs = (episodeNumber) => {
    const episode = episodeNumber || selectedEpisode;
    if (
      patient?.episodeDetails &&
      Object.keys(patient?.episodeDetails).length !== 0
    ) {
      getDataCall(
        providerViewPatientGraphsUrl,
        {
          token: patient?.profileDetails?.token,
          service: service,
          episodeNumber: episode || patient?.episodeDetails?.currentEpisode,
        },
        setGraphDetails
      );
    }
  };

  const getBestPefData = () => {
    if (
      patient?.episodeDetails &&
      Object.keys(patient?.episodeDetails).length !== 0 &&
      [SERVICES.BIOLOGICS, SERVICES.SLIT].includes(service)
    ) {
      getDataCall(
        providerViewPatientBestPefGraphUrl,
        {
          token: patient?.profileDetails?.token,
          service: service,
        },
        setBestPefDetails
      );
    }
  };

  const getVitals = (episodeNumber) => {
    const episode = episodeNumber || selectedEpisode;
    if (
      patient?.episodeDetails &&
      Object.keys(patient?.episodeDetails).length !== 0
    ) {
      getDataCall(
        providerViewPatientVitalsUrl,
        {
          token: patient?.profileDetails?.token,
          service: service,
          episodeNumber: episode || patient?.episodeDetails?.currentEpisode,
        },
        setVitalDetails
      );
    }
  };

  const getEmrData = (reload) => {
    if (reload) {
      getDataCall(
        providerViewPatientEmrDataUrl,
        {
          token: patient?.profileDetails?.token,
          service: service,
        },
        setEmrDetails
      );
    }
  };

  const onEpisodeChange = (value) => {
    if (selectedEpisode !== value) {
      callTabBasedApis(activeTab, value, true);
      dispatch(setSelectedEpisode(value));
    }
  };

  const callTabBasedApis = (tab, episodeNumber) => {
    switch (tab) {
      case "dashboard":
        getGraphs(episodeNumber);
        getBestPefData();
        getVitals(episodeNumber);
        break;
      case "vitals":
        getVitals(episodeNumber);
        break;
      case "graphs":
        getGraphs(episodeNumber);
        getBestPefData();
        break;
      case "alerts":
        getAlerts(episodeNumber);
        break;
      case "devices":
        getDevices();
        break;
      case "audiology":
        getAudiologyData();
        break;
      case "risk":
        getDataCall(
          providerViewPatientTinnitusRiskUrl,
          {
            token: patient?.profileDetails?.token,
            service: service,
          },
          setRiskDetails
        );
        break;
      case "timelines":
        getDataCall(
          providerViewPatientTimelinesUrl,
          {
            token: patient?.profileDetails?.token,
            service: service,
          },
          setTimelineDetails
        );
        break;
      case "wellness":
        getDataCall(
          providerViewPatientWellnessUrl,
          {
            token: patient?.profileDetails?.token,
            service: service,
          },
          setWellnessDetails
        );
        break;
      case "health":
        getDataCall(
          providerViewPatientHealthDataUrl,
          {
            token: patient?.profileDetails?.token,
            service: service,
            timeZone: timezone,
          },
          setHealthDetails
        );
        break;
      case "feNo":
        getFeNoData();
        break;
      case "claims":
        getDataCall(
          providerViewPatientClaimsUrl,
          {
            token: patient?.profileDetails?.token,
            service: service,
          },
          setClaimDetails
        );
        break;
      case "vials":
        getDataCall(
          providerViewPatientVialsUrl,
          {
            token: patient?.profileDetails?.token,
            service: service,
          },
          setVialDetails
        );
        break;
      case "questionnaires":
        getQuestionnaires(episodeNumber);
        break;
      case "emrData":
        getEmrData();
        break;
      case "reports":
        getReports(episodeNumber);
        break;
      default:
        return "";
    }
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
    callTabBasedApis(key);
  };

  const showModal = () => {
    setModalOpen(true);
    form.resetFields();
  };

  const cancelModal = () => {
    setModalOpen(false);
  };

  const tabItems = [
    {
      key: "overview",
      label: "Overview",
      children: (
        <WithoutEpisode
          showLoader={showLoader}
          details={patient?.profileDetails}
        >
          <Overview
            service={service}
            episodeDetails={patient?.episodeDetails}
            profileDetails={patient?.profileDetails}
          />
        </WithoutEpisode>
      ),
    },
    {
      key: "wellness",
      label: "Wellness",
      children: (
        <WithoutEpisode
          showLoader={showLoader}
          details={patient?.wellnessDetails}
        >
          <Wellness
            service={service}
            wellnessDetails={patient?.wellnessDetails}
          />
        </WithoutEpisode>
      ),
    },
    {
      key: "health",
      label: "Health",
      children: (
        <WithoutEpisode
          showLoader={showLoader}
          details={patient?.healthDetails}
        >
          <Health service={service} healthDetails={patient?.healthDetails} />
        </WithoutEpisode>
      ),
    },
    {
      key: "dashboard",
      label: "Dashboard",
      children: (
        <WithEpisode
          selectedEpisode={selectedEpisode}
          episodeDetails={patient?.episodeDetails}
          onEpisodeChange={onEpisodeChange}
          showLoader={showLoader}
          details={patient?.bestPefDetails}
        >
          <Dashboard
            vitalDetails={patient?.vitalDetails}
            graphDetails={patient?.graphDetails}
            bestPefDetails={patient?.bestPefDetails}
          />
        </WithEpisode>
      ),
    },
    {
      key: "vitals",
      label: "Vitals",
      children: (
        <WithEpisode
          selectedEpisode={selectedEpisode}
          episodeDetails={patient?.episodeDetails}
          onEpisodeChange={onEpisodeChange}
          showLoader={showLoader}
          details={patient?.vitalDetails}
        >
          <Vitals vitalDetails={patient?.vitalDetails} />
        </WithEpisode>
      ),
    },
    {
      key: "graphs",
      label: "Graphs",
      children: (
        <WithEpisode
          selectedEpisode={selectedEpisode}
          episodeDetails={patient?.episodeDetails}
          onEpisodeChange={onEpisodeChange}
          showLoader={showLoader}
          details={patient?.graphDetails}
        >
          <Graphs graphDetails={patient?.graphDetails} />
        </WithEpisode>
      ),
    },
    {
      key: "alerts",
      label: "Alerts",
      children: (
        <WithEpisode
          selectedEpisode={selectedEpisode}
          episodeDetails={patient?.episodeDetails}
          onEpisodeChange={onEpisodeChange}
          showLoader={showLoader}
          details={patient?.alertDetails}
          activeTab={activeTab}
          showModal={showModal}
          cancelModal={cancelModal}
        >
          <Alerts
            alertDetails={patient?.alertDetails}
            mrn={patient?.profileDetails?.mrn}
            service={service}
            defaultDoctor={defaultDoctor}
          />
        </WithEpisode>
      ),
    },
    {
      key: "vials",
      label: "Vials",
      children: (
        <WithoutEpisode showLoader={showLoader} details={patient?.vialDetails}>
          <Vials
            vialDetails={patient?.vialDetails}
            notify={notify}
            service={service}
            token={patient?.profileDetails?.token}
            defaultDoctor={defaultDoctor}
            charges={staticDropdownData?.common?.vialCharges}
          />
        </WithoutEpisode>
      ),
    },
    {
      key: "claims",
      label: "Claims",
      children: (
        <WithoutEpisode showLoader={showLoader} details={patient?.claimDetails}>
          <Claims claimDetails={patient?.claimDetails} />
        </WithoutEpisode>
      ),
    },
    {
      key: "audiology",
      label: "Audiology",
      children: (
        <WithoutEpisode
          showLoader={showLoader}
          details={patient?.profileDetails}
        >
          <Audiology
            audiologyDetails={patient?.audiologyDetails}
            profileDetails={patient?.profileDetails}
            content={content}
          />
        </WithoutEpisode>
      ),
    },
    {
      key: "feNo",
      label: "FeNO",
      children: (
        <WithForm showLoader={showLoader}>
          <FeNo
            feNoDetails={patient?.feNoDetails}
            token={patient?.profileDetails?.token}
            getFeNoData={getFeNoData}
          />
        </WithForm>
      ),
    },
    {
      key: "timelines",
      label: "Timelines",
      children: (
        <WithoutEpisode
          showLoader={showLoader}
          details={patient?.timelineDetails}
        >
          <Timelines
            timelineDetails={patient?.timelineDetails}
            clinic={userDetails?.clinic}
          />
        </WithoutEpisode>
      ),
    },
    {
      key: "emrData",
      label: "EMR Data",
      children: (
        <WithForm showLoader={showLoader}>
          <EmrData
            emrDetails={patient?.emrDetails}
            token={patient?.profileDetails?.token}
            getEmrData={getEmrData}
          />
        </WithForm>
      ),
    },
    {
      key: "questionnaires",
      label: "Questionnaires",
      children: (
        <WithEpisode
          selectedEpisode={selectedEpisode}
          episodeDetails={patient?.episodeDetails}
          onEpisodeChange={onEpisodeChange}
          showLoader={showLoader}
          details={patient?.questionnaireDetails}
        >
          <Questionnaires
            questionnaireDetails={patient?.questionnaireDetails}
            service={service}
            questionnaires={questionnaires}
            profileDetails={patient?.profileDetails}
            episodeDetails={patient?.episodeDetails}
          />
        </WithEpisode>
      ),
    },
    {
      key: "reports",
      label: "Reports",
      children: (
        <WithEpisode
          selectedEpisode={selectedEpisode}
          episodeDetails={patient?.episodeDetails}
          onEpisodeChange={onEpisodeChange}
          showLoader={showLoader}
          details={patient?.reportDetails}
        >
          <Reports
            service={service}
            selectedEpisode={selectedEpisode}
            reportDetails={patient?.reportDetails}
            episodeDetails={patient?.episodeDetails}
            token={patient?.profileDetails?.token}
          />
        </WithEpisode>
      ),
    },
    {
      key: "risk",
      label: "Risk",
      children: (
        <WithoutEpisode showLoader={showLoader} details={patient?.riskDetails}>
          <Risk service={service} riskDetails={patient?.riskDetails} />
        </WithoutEpisode>
      ),
    },
    {
      key: "devices",
      label: "Devices",
      children: (
        <WithoutEpisode
          showLoader={showLoader}
          details={patient?.deviceDetails}
        >
          <Devices
            deviceDetails={patient?.deviceDetails}
            token={patient?.profileDetails?.token}
            service={service}
          />
        </WithoutEpisode>
      ),
    },
  ];

  const onFinish = async () => {
    const formData = {
      reason: selectedReason,
      description: otherReason,
      token: patient?.profileDetails?.token,
      episodeNumber: selectedEpisode,
      service: service,
    };
    setLoading(true);
    await postApis(providerResolveAllAlertsUrl, formData)
      .then((response) => {
        setLoading(false);
        setModalOpen(false);
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  const handleTextAreaChange = (event) => {
    setOtherReason(event.target.value);
  };

  const handleDropdownChange = (value) => {
    setSelectedReason(value);
    setOtherReason("");
  };

  const getTabMenus = () => {
    if (service === SERVICES.GENERAL) {
      return tabItems.filter(
        (item) =>
          ![
            "questionnaires",
            "feNo",
            "timelines",
            "emrData",
            "dashboard",
            "vials",
            "wellness",
            "health",
            "risk",
            "audiology",
          ].includes(item.key)
      );
    } else if (service === SERVICES.SLIT) {
      return tabItems.filter(
        (item) =>
          ![
            "timelines",
            "emrData",
            "vitals",
            "graphs",
            "wellness",
            "health",
            "risk",
            "audiology",
          ].includes(item.key)
      );
    } else if ([SERVICES.WELLNESS].includes(service)) {
      return tabItems.filter((item) =>
        ["overview", "wellness", "health"].includes(item.key)
      );
    } else if (service === SERVICES.AUDIOLOGY) {
      return tabItems.filter((item) => ["audiology"].includes(item.key));
    } else if (service === SERVICES.TINNITUS) {
      return tabItems.filter((item) =>
        ["overview", "questionnaires", "risk"].includes(item.key)
      );
    } else if (service === SERVICES.BIOLOGICS) {
      return tabItems.filter(
        (item) =>
          ![
            "vials",
            "timelines",
            "emrData",
            "vitals",
            "graphs",
            "wellness",
            "health",
            "risk",
            "audiology",
          ].includes(item.key)
      );
    } else {
      return tabItems.filter(
        (item) =>
          ![
            "questionnaires",
            "feNo",
            "dashboard",
            "vials",
            "wellness",
            "health",
            "risk",
            "audiology",
          ].includes(item.key)
      );
    }
  };

  return (
    <Row data-testid="patient-view">
      <Col span={24}>
        <ResolveAlert
          form={form}
          handleTextAreaChange={handleTextAreaChange}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          handleDropdownChange={handleDropdownChange}
          cancelModal={cancelModal}
          modalOpen={modalOpen}
          loading={loading}
          selectedReason={selectedReason}
        />
        <Card>
          {tabItems && (
            <Tabs
              type="card"
              defaultActiveKey={activeTab}
              onChange={handleTabChange}
              items={getTabMenus()}
            />
          )}
        </Card>
      </Col>
    </Row>
  );
};
export default PatientDetails;
